import React from "react";
import styled from "styled-components";
import TextComponent from "@common/typography/TextComponent";
import CalendarCheckIcon from "@components/icons/CalendarCheckIcon";
import { useNavigationContext } from "@hooks/useNavigationContext";
import useWizardContext from "./useWizardContext";

interface WizardHeaderProps {
    header: string;
    subHeader?: string;
}

export const WizardHeaderContainer = styled.div`
    .mobile-resume-review {
        background-color: ${({ theme }) => theme.secondary};
        svg {
            margin-top: 2px;
        }
    }
    .container {
        display: flex;
        padding: 16px 0 16px 16px;
        justify-content: space-between;
        align-items: center;
        .resume-review {
            cursor: pointer;
            position: relative;
            padding: 10px 20px 8px 15px;
            background-color: ${({ theme }) => theme.secondary};
            &:before {
                content: "";
                position: absolute;
                left: 0;
                border-left: 9px solid #fff;
                border-bottom: 20px solid transparent;
                border-top: 19px solid transparent;
            }
        }
    }
`;

const ResumeReview = ({ className }: { className: string }) => {
    const { jumpTo } = useNavigationContext();
    const { steps } = useWizardContext();
    const goToReviewPage = () => {
        const reviewIndex = steps.findIndex((step) => step?.name === 'review-page');
        jumpTo(reviewIndex);
    };
    return <div
        role="button"
        tabIndex={0}
        onKeyUp={goToReviewPage}
        onClick={goToReviewPage}
        className={`${className} d-flex gap-2 cursor-pointer`}
    >
        <TextComponent
            fontWeight="600"
            fontSize="sm"
            className="ps-lg-2"
            color="white"
        >
            Resume Review
        </TextComponent>
        <CalendarCheckIcon />
    </div>;
};

const Header = styled.div`
    width: 100%;
    margin: auto;
    @media (min-width: 800px) {
        width: 75%;
    }`;

const WizardHeader = ({
    header,
    subHeader = "MERCHANT PROCESSING APPLICATION",
}: WizardHeaderProps) => {
    const { showBackToReview } = useWizardContext();

    return <WizardHeaderContainer>
        {showBackToReview && <ResumeReview className="mobile-resume-review p-2 justify-content-center d-sm-none" />}
        <Header className="d-flex p-3 border-bottom container">
            <div>
                <TextComponent color="pewter" fontSize="sm">
                    {subHeader}
                </TextComponent>
                <TextComponent fontWeight="800" fontSize="title">
                    {header}
                </TextComponent>
            </div>
            {showBackToReview && <div className="d-sm-block d-none">
                <ResumeReview className="resume-review align-items-center" />
            </div>}
        </Header>
    </WizardHeaderContainer>;
};

export default WizardHeader;
