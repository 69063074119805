import styled from 'styled-components';
import { Button, OutlineButton } from '@common';
import { useNavigationContext } from '@hooks/useNavigationContext';
import useSidebarContext from '@hooks/useSidebarContext';
import useToast from '@hooks/useToast';

import useWizardContext from './useWizardContext';

const FooterContainer = styled.div<{ $sidebar: boolean }>`
    border-top: 1px solid ${(props) => props.theme.borderColor};
    padding: 15px 30px;
    padding-left: 0;
    bottom: 0;
    background: ${(props) => props.theme?.grey};
    text-align: center;
    .btn {
        padding: 6px 16px 6px 16px;
        min-width: 132px;
        margin: 10px;
    }

    @media (min-width: 575px) {
        text-align: right;
        .float-left {
            float: left;
        }
    }
`;

type WizardFooterProps = {
    onComplete?: () => void;
};

const WizardFooter = ({ onComplete = () => { } }: WizardFooterProps) => {
    const {
        loading,
        steps = [],
        getCurrentStep = () => null,
    } = useWizardContext();
    const { showSidebar } = useSidebarContext();
    const { back, currentPage, forward } = useNavigationContext();
    const currentStep = getCurrentStep();

    const {
        buttonSteps,
        enabled = true,
        nextBtnLabel = 'Continue',
        nextButtonDisabled = false,
    } = currentStep?.footer || {};

    const { hideRequiredWarningToast, showRequiredWarningToast } = useToast();

    const isReviewNotValid = currentStep?.name === 'review-page' && nextButtonDisabled;

    const handleHover = () => {
        if (isReviewNotValid) {
            return showRequiredWarningToast()
        }
        return null
    }

    const onClickNext = () => {
        if (isReviewNotValid) {
            showRequiredWarningToast();
            return null;
        }
        forward();
    }

    const renderNextButton = () => <Button disabled={loading} onClick={onClickNext} onMouseOver={handleHover} onMouseOut={hideRequiredWarningToast}  key="continue" variant="secondary" className={`mx-2 ${isReviewNotValid ? 'pe-auto disabled' : ''}`}>
        {nextBtnLabel}
    </Button>;

    const renderPreviousButton = () => (
        <OutlineButton
            disabled={loading}
            onClick={back}
            variant="secondary"
            key="previous"
            className="mx-2"
        >
            Back
        </OutlineButton>
    );

    const renderCompleteButton = () =>
        <Button onClick={onComplete} key="complete" variant="secondary" className="mx-2">
            Log Out
        </Button>;

    const renderDefaultActionButtons = () => {
        if (currentPage === 0 && currentPage < steps?.length) {
            return <>
                {renderNextButton()}
            </>;
        }
        if (currentPage > 0 && currentPage < steps.length - 1) {
            return <>
                {renderPreviousButton()}
                {renderNextButton()}
            </>;
        }
        if (currentPage === steps.length - 1) {
            return <>
                {renderPreviousButton()}
                {renderCompleteButton()}
            </>;
        }
        return null;
    };

    const renderActionButtons = () => buttonSteps?.length ? buttonSteps?.map(step => {
        if (step === 'continue') {
            return renderNextButton();
        }
        if (step === 'back') {
            return renderPreviousButton();
        }
        if (step === 'complete') {
            return renderCompleteButton();
        }
        if (step) {
            return step;
        }
        return null;
    }) : renderDefaultActionButtons();

    return enabled
        ? <FooterContainer $sidebar={showSidebar}><div className="flex">{renderActionButtons()}</div></FooterContainer>
        : null;
};

export default WizardFooter;
