import { Row, Col, Badge } from 'react-bootstrap';
import TextComponent from '@common/typography/TextComponent';
import useWizardContext from '@hooks/wizard/useWizardContext';
import useOpportunityContext from '@hooks/useOpportunityContext';
import AddressComponent from "@components/page-steps/review-page-components/AddressComponent";
import useFormValidationContext from '@hooks/useFormValidationContext';
import { formatPhoneNumber, getAddressValue } from '@utils/FormUtils';
import LabelComponent from './LabelComponent';
import OwnerReviewDetails from './OwnersFormComponent';

export type ContactProps = {
    firstName?: string;
    lastName?: string;
    contactNumber?: string;
    ssn?: string;
    dob?: string;
    email?: string;
    title?: string;
    isOwner?: boolean;
    percentOwnership?: number|string;
    photoId?: string;
    isUnsolicited: boolean;
    addr?: JSX.Element | null;
    addrLabel?: string | null;
    addrPrefix?: string;
};

const ContactComponent = ({
    firstName = undefined,
    lastName = undefined,
    contactNumber = undefined,
    ssn = undefined,
    dob = undefined,
    email = undefined,
    title = undefined,
    isOwner = false,
    percentOwnership = undefined,
    photoId = undefined,
    isUnsolicited = false,
    addr = null,
    addrLabel = null,
    addrPrefix = '',
}: ContactProps) => <>
    <Row>
        <Col md={6} data-cy="people.0.FirstName">
            <LabelComponent label="First Name" value={firstName} formKey='people[0].FirstName' />
        </Col>
        <Col md={6} data-cy="people.0.LastName">
            <LabelComponent label="Last Name" value={lastName} formKey='people[0].LastName' />
        </Col>
    </Row>
    <Row>
        <Col md={6} data-cy="people.0.ContactNumber">
            <LabelComponent
                label="Contact Number"
                value={contactNumber ? formatPhoneNumber(contactNumber) : ''}
                formKey='people[0].ContactNumber'
            />
        </Col>
        <Col md={6} data-cy="people.0.Email">
            <LabelComponent label="Email Address" value={email} formKey='people[0].Email' />
        </Col>
    </Row>
    <Row>
        <Col md={6} data-cy="people.0.DOB">
            <LabelComponent label="Date of Birth" value={dob} formKey='people[0].DateOfBirth' />
        </Col>
        <Col md={6} data-cy="people.0.SSN">
            <LabelComponent label="SSN" value={ssn} formKey='people[0].SSN' ddMaskText />
        </Col>
    </Row>
    <Row>
        <Col md={6} data-cy="people.0.Title">
            <LabelComponent label="Title" value={title} formKey='people[0].Title' />
        </Col>
        {isOwner && <Col md={6} data-cy="people.0.PercentOwnership">
            <LabelComponent label="Ownership %" value={percentOwnership} formKey='people[0].PercentOwnership' />
        </Col>}
        {isUnsolicited && <Col md={6} data-cy="people.0.PhotoID">
            <LabelComponent label="Photo ID" value={photoId} formKey='people[0].PhotoID' />
        </Col>}
        {addrLabel && <Col md={6} data-cy="people.0.Address">
            <AddressComponent label={addrLabel} value={addr} prefixKey={addrPrefix} />
        </Col>}
    </Row>
</>;

const ManagingDetailsReviewSoleProp = () => {
    const { form: { getValues } } = useWizardContext();
    const { additionalProcessingFields } = useOpportunityContext();
    const values = getValues();

    return <>
        <TextComponent fontWeight="700" className='mt-4 '>Company Owner</TextComponent>
        <OwnerReviewDetails
            firstName={values?.people?.[0]?.FirstName}
            lastName={values?.people?.[0]?.LastName}
            contactNumber={values?.people?.[0]?.ContactNumber}
            ssn={values?.people?.[0]?.SSN}
            dob={values?.people?.[0]?.DateOfBirth}
            title={values?.people?.[0]?.Title?.value}
            email={values?.people?.[0]?.Email}
            photoId={values?.people?.[0]?.PhotoID?.name}
            isUnsolicited={additionalProcessingFields?.isUnsolicited ?? false}
            percentOwnership={values?.people?.[0]?.PercentOwnership}
            addrLabel={values?.people?.[0]?.isPrimaryLocSameAsBusiness === 'yes' ? null : 'Home Location'}
            addrPrefix="people[0]."
            addr={getAddressValue(
                values?.people?.[0]?.Addr1,
                values?.people?.[0]?.Addr2,
                values?.people?.[0]?.City,
                values?.people?.[0]?.State?.label,
                values?.people?.[0]?.ZipCode
            )}
            fieldPrefix="people[0]."
        />
    </>;
};

const ManagingDetailsReviewWithoutOwners = () => {
    const { form: { getValues } } = useWizardContext();
    const { additionalProcessingFields } = useOpportunityContext();
    const values = getValues();

    return <>
        <TextComponent fontWeight="700">Company Representative</TextComponent>
        <ContactComponent
            firstName={values.people?.[0]?.FirstName}
            lastName={values.people?.[0]?.LastName}
            contactNumber={values.people?.[0]?.ContactNumber}
            ssn={values.people?.[0]?.SSN}
            dob={values.people?.[0]?.DateOfBirth}
            title={values.people?.[0]?.Title?.label}
            email={values.people?.[0]?.Email}
            isOwner={values.people?.[0]?.IsOwner === 'yes'}
            percentOwnership={values.people?.[0]?.PercentOwnership}
            photoId={values?.people?.[0]?.PhotoID?.name}
            isUnsolicited={additionalProcessingFields?.isUnsolicited ?? false}
            addrLabel={values.people?.[0]?.isPrimaryLocSameAsBusiness === 'yes' ? null : 'Home Location'}
            addrPrefix="people[0]."
            addr={getAddressValue(
                values.people?.[0]?.Addr1,
                values.people?.[0]?.Addr2,
                values.people?.[0]?.City,
                values.people?.[0]?.State?.label,
                values.people?.[0]?.ZipCode
            )}
        />
    </>;
};

const ManagingDetailsReviewWithOwners = () => {
    const { form: { getValues } } = useWizardContext();
    const { additionalProcessingFields } = useOpportunityContext();
    const values = getValues();

    return <>
        <TextComponent fontWeight="700">Company Representative</TextComponent>
        <ContactComponent
            firstName={values.people?.[0]?.FirstName}
            lastName={values.people?.[0]?.LastName}
            contactNumber={values.people?.[0]?.ContactNumber}
            ssn={values.people?.[0]?.SSN}
            dob={values.people?.[0]?.DateOfBirth}
            title={values.people?.[0]?.Title?.label}
            email={values.people?.[0]?.Email}
            isOwner={values.people?.[0]?.IsOwner === 'yes'}
            photoId={values?.people?.[0]?.PhotoID?.name}
            isUnsolicited={additionalProcessingFields?.isUnsolicited ?? false}
            percentOwnership={values.people?.[0]?.PercentOwnership}
            addrLabel={values.people?.[0]?.isPrimaryLocSameAsBusiness === 'yes' ? null : 'Home Location'}
            addrPrefix="people[0]."
            addr={getAddressValue(
                values.people?.[0]?.Addr1,
                values.people?.[0]?.Addr2,
                values.people?.[0]?.City,
                values.people?.[0]?.State?.label,
                values.people?.[0]?.ZipCode
            )}
        />
        {values.people?.length > 1 && values.people?.slice(1).map((person, i) =>
            <div key={`add-owner ${i + 1}`} data-cy={`owner${i + 1}`}>
                <TextComponent fontWeight="700" className='mt-4 '>Owner {i + 1}</TextComponent>
                <OwnerReviewDetails
                    firstName={person.FirstName}
                    lastName={person.LastName}
                    contactNumber={person.ContactNumber}
                    ssn={person.SSN}
                    showSSN
                    dob={person.DateOfBirth}
                    title={person.Title?.value}
                    email={person.Email}
                    photoId={person?.PhotoID?.name}
                    isUnsolicited={additionalProcessingFields?.isUnsolicited ?? false}
                    percentOwnership={person.PercentOwnership}
                    addrLabel={person.isPrimaryLocSameAsBusiness === 'yes' ? null : 'Home Location'}
                    addrPrefix={`people[${i + 1}].`}
                    addr={getAddressValue(
                        person?.Addr1,
                        person?.Addr2,
                        person?.City,
                        person?.State?.label,
                        person?.ZipCode
                    )}
                    fieldPrefix={`people[${i + 1}].`}
                />
                {i + 2 !== values?.people?.length && <hr />}
            </div>
        )}
    </>
};

const ManagingDetailsReview = () => {
    const { errors } = useFormValidationContext();
    const { form } = useWizardContext();
    const { getValues } = form;
    const values = getValues();

    const ownerPercentageError = errors?.find(({ type }) => type === 'owner-100-percent')?.message;

    const entityType = values?.entityType?.value;
    const isNonOwnerEntity = [
        'Publicly Traded Partnership',
        'Publicly Traded LLC',
        'Public Corp',
        'Tax Exempt Organization',
        'Government',
    ].includes(entityType || '');

    return <>
        {/* eslint-disable-next-line no-nested-ternary */}
        {entityType === 'Sole Proprietorship'
            ? <ManagingDetailsReviewSoleProp />
            : isNonOwnerEntity
                ? <ManagingDetailsReviewWithoutOwners />
                : <ManagingDetailsReviewWithOwners />
        }
        {ownerPercentageError && <div>
            <Badge
                bg="danger"
                pill
                className="text-white p-2 mt-2 text-truncate"
                data-cy="errorInformationBadge"
            >
                Total Ownership Percentage Must be less than or equal to 100%.
            </Badge>
        </div>}
    </>;
}

export default ManagingDetailsReview;
