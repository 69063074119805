import React, { type PropsWithChildren, createContext, useContext, useEffect, useMemo, useReducer } from 'react';

export type NextNavigateProps = {
    hasCompleted: boolean,
    hasWarning: boolean,
    redirect?: boolean,
};

type Context = {
    currentPage: number,
    back: () => void,
    forward: () => void,
    jumpTo: (page: number) => Promise<void>,
    pages: {
        hasWarning: boolean,
        isActive: boolean,
        isCompleted: boolean,
        title: string,
        progressIcon?: string|React.ReactNode,
    }[];
};

export const NavigationContext = createContext<Context>({
    currentPage: 0,
    back: () => null,
    forward: () => null,
    jumpTo: () => Promise.resolve(),
    pages: [],
});

type State = { completed: Set<number>, warning: Set<number> };
type Action = { type: 'completed' | 'warning', page: number };

export const NavigationContextProvider = ({
    children = null,
    currentPage = 0,
    initialState = { completed: new Set<number>(), warning: new Set<number>() },
    setCompletedSteps = () => null,
    setCurrentPage = () => null,
    beforeForward,
    beforeJump,
    pages = []
}: PropsWithChildren<{
    currentPage: number,
    initialState: { completed: Set<number>, warning: Set<number> },
    setCompletedSteps: (completedSteps: number) => void,
    setCurrentPage: (currentPage: number) => void,
    beforeForward: () => Promise<NextNavigateProps>|NextNavigateProps,
    beforeJump: () => Promise<NextNavigateProps>|NextNavigateProps,
    pages: { title: string, progressIcon?: string|React.ReactNode, disableJump?: boolean }[],
}>) => {
    const [{ completed, warning}, dispatch] = useReducer((state: State, { type, page }: Action) => {
        switch (type) {
            case 'completed':
                state.warning.delete(page);
                return { completed: new Set(state.completed.add(page)), warning: new Set(state.warning) };
            case 'warning':
                state.completed.delete(page);
                return { completed: new Set(state.completed), warning: new Set(state.warning.add(page)) };
            default:
                return state;
        }
    }, initialState);

    useEffect(() => {
        setCompletedSteps(completed.size);
    }, [completed, setCompletedSteps]);

    const value = useMemo(() => ({
        back: () => currentPage > 0 && setCurrentPage(currentPage - 1),
        currentPage,
        forward: async () => {
            const { hasCompleted, hasWarning, redirect } = await beforeForward();
            if (hasWarning) {
                dispatch({ type: 'warning', page: currentPage });
            }
            if (hasCompleted) {
                dispatch({ type: 'completed', page: currentPage });
            }
            if (redirect && currentPage < (pages.length - 1)) {
                setCurrentPage(currentPage + 1)
            }
        },
        jumpTo: async (page: number) => {
            if (page === currentPage) return;
            if (pages[page].disableJump) return;
            const { hasCompleted, hasWarning } = await beforeJump();
            if (hasWarning) {
                dispatch({ type: 'warning', page: currentPage });
            }
            if (hasCompleted) {
                dispatch({ type: 'completed', page: currentPage });
            }
            setCurrentPage(page);
        },
        pages: pages.map((page, index) => ({
            ...page,
            isActive: index === currentPage,
            isCompleted: completed.has(index),
            hasWarning: warning.has(index),
        })),
    }), [beforeForward, beforeJump, completed, currentPage, setCurrentPage, pages, warning]);

    return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
};

export const useNavigationContext = () => {
    const context = useContext(NavigationContext);
    return context;
};
