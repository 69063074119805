import * as yup from 'yup';
import dayjs from 'dayjs';
import {
    BankFields,
    BusinessFields,
    ProcessingFields,
    EquipmentSetUpFields
} from '@hooks/wizard/types';
import { Option } from '@components/controller-components/FormSelectInput';
import {
    phoneRegExp,
    poBoxRegExp,
    NUM_ONLY,
} from './regex';
import US_STATES from './static-options/state';
import {LegalEntityTypeOptions} from './static-options/entityType';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const brnv = require('bank-routing-number-validator');

const STRING_VALIDATION = yup.string().nullable().trim();

// only works on non-nested fields
const REQUIRED_VALIDATION = (label: string) => STRING_VALIDATION
    .test(label, `${label} is a required field`, (val, ctx) => {
        if (!val && !ctx.options.context?.optional) {
            return ctx.createError({ message: `${label} is a required field` });
        }
        return true;
    })

const OPTIONAL_VALIDATION = STRING_VALIDATION.optional();

const OBJECT_VALIADATION = yup.object().nullable();

const EMAIL_VALIDATION = STRING_VALIDATION.label('Email address').email();

const ADDRESS_VALIDATION = STRING_VALIDATION.test(
    'PO Box Validation',
    'Address should not be a PO Box address',
    (val, ctx) => {
        if (poBoxRegExp.test(val || '')) {
            return ctx.createError({ message: `Address should not be a PO Box address` });
        }
        return true;
    }
);

const ZIP_CODE_VALIDATION = STRING_VALIDATION.test(
    'Zip code', 'Invalid zip code',
    (val, ctx) => {
        if (val) {
            if (!val.match(NUM_ONLY)) return ctx.createError({ message: 'Zip code must be a number' });
            if (val.length !== 5) return ctx.createError({ message: 'Zip code must be exactly 5 digits' });
        }
        return true;
    }
)

function isValidHttpUrl(str : string) {
    let url;

    try {
        url = new URL(str);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

const DATE_VALIDATION = (label: string, {min, max, required} : {min?: Date, max?: Date, required?: boolean} = {}) => yup.string().trim()
    .test('Date Validation', 'Invalid Date', (val, ctx) => {
        if (val) {
            try {
                const date = new Date(val);
                const dateArray = val.split('/');
                if (!(date.getMonth() + 1 === +dateArray[0] && date.getDate() === +dateArray[1] && date.getFullYear() === +dateArray[2])) {
                    return ctx.createError({message: 'Invalid date'});
                }

                if (min && dayjs(val).isBefore(min) && +dateArray[2].length >= 4) {
                    return ctx.createError({message: `${label} must be later than ${dayjs(min)
                            .format('MM/DD/YYYY')}`});
                }

                if (max && dayjs(val).isAfter(max) && +dateArray[2].length >= 4) {
                    return ctx.createError({message: `${label} must be before than ${dayjs(max)
                            .format('MM/DD/YYYY')}`});
                }

            } catch (e) {
                return ctx.createError({message: 'Invalid date'});
            }
        }
        else if (required) {
            return ctx.createError({message: `${label} is a required field`});
        }
        return true;
    });

const NUM_VALIDATION = yup.number().typeError('Required & must be a number').min(0);

const PERCENT_VALIDATION = yup.number().transform((originalValue) => {
    if (!originalValue) {
        return 0;
    }
    return originalValue;
}).max(100).typeError('Required & must be a number');

// FIXME(LY) I can't figure out how to get when and test to use this type, but at least it ensures we are passing values listed here
export type ValidationContext = {
    hasEIServerOrCloud: boolean;
    hasEmergepay: boolean;
    isOpportunity: boolean;
    optional: boolean;
}

/**
 * Business Details Page
 */
export const businessSchema = yup.object<ValidationContext>().shape({
    [BusinessFields.LEGALNAME]: REQUIRED_VALIDATION('Legal Name'),
    [BusinessFields.DBANAME]: REQUIRED_VALIDATION('DBA'),
    [BusinessFields.B_ADD1]: ADDRESS_VALIDATION.label('Address Line 1').test('Address Line 1', 'Address Line 1 is required',
        (val, ctx) => {
            if (!val && !ctx.options.context?.optional) {
                return ctx.createError({ message: 'Address Line 1 is a required field' });
            }
            return true;
        }),
    [BusinessFields.B_ADD2]: OPTIONAL_VALIDATION.label('Address Line 2'),
    [BusinessFields.B_CITY]: REQUIRED_VALIDATION('City'),
    [BusinessFields.B_STATE]: yup.object().label('State').when(['$optional'], {
        is: false,
        then: s => s.shape({
               label: STRING_VALIDATION.oneOf(US_STATES.map(({ label }) => label)).required('State is required'),
               value: STRING_VALIDATION.oneOf(US_STATES.map(({ value }) => value)).required('State is required'),
           }).default(undefined).required('State is a required field'),
        otherwise: s => s.optional(),
    }),
    [BusinessFields.B_ZIPCODE]: ZIP_CODE_VALIDATION.test('Zip code', 'Zip code is required', (val, ctx) => {
        if (!val && !ctx.options.context?.optional) {
            return ctx.createError({ message: 'Zip code is a required field' });
        }
        return true;
    }),
    [BusinessFields.IS_LEGAL_SAME_AS_PYSICAL]: REQUIRED_VALIDATION('Legal address').oneOf(['yes', 'no']),
    [BusinessFields.L_ADD1]: STRING_VALIDATION.when([BusinessFields.IS_LEGAL_SAME_AS_PYSICAL], {
        is: 'no',
        then: () => REQUIRED_VALIDATION('Address Line 1'),
        otherwise: s => s.optional(),
    }),
    [BusinessFields.L_ADD2]: OPTIONAL_VALIDATION.label('Address Line 2'),
    [BusinessFields.L_CITY]: STRING_VALIDATION.when([BusinessFields.IS_LEGAL_SAME_AS_PYSICAL], {
        is: 'no',
        then: () => REQUIRED_VALIDATION('City'),
        otherwise: s => s.optional(),
    }),
    [BusinessFields.L_STATE]: yup.object().label('State').when([BusinessFields.IS_LEGAL_SAME_AS_PYSICAL, '$optional'], {
        is: (shippingAddrSameAsBusiness: string, optional: boolean) => shippingAddrSameAsBusiness === 'no' && !optional,
        then: s => s.shape({
            label: STRING_VALIDATION.oneOf(US_STATES.map(({ label }) => label)).required('State is required'),
            value: STRING_VALIDATION.oneOf(US_STATES.map(({ value }) => value)).required('State is required'),
        }).default(undefined).required('State is a required field'),
        otherwise: s => s.optional(),
    }),
    [BusinessFields.L_ZIPCODE]: STRING_VALIDATION.when([BusinessFields.IS_LEGAL_SAME_AS_PYSICAL], {
        is: 'no',
        then: () => ZIP_CODE_VALIDATION.test('Zip code', 'Zip code is required',
            (val, ctx) => {
                if (!val && !ctx.options.context?.optional) {
                    return ctx.createError({ message: 'Zip code is a required field' });
                }
                return true;
            },
        ),
        otherwise: s => s.optional(),
    }),
    [BusinessFields.B_PHONE]: STRING_VALIDATION.test('Phone', 'Business phone is a required field',
        (val, ctx) => {
            if (!val && !ctx.options.context?.optional) {
                return ctx.createError({ message: 'Business phone is a required field' });
            }
            if (val && !val.match(phoneRegExp)) {
                return ctx.createError({ message: 'Invalid phone number' });
            }
            return true;
        }),
    [BusinessFields.B_EMAIL]: EMAIL_VALIDATION.test('email', 'Email address is a required field',
        (val, ctx) => {
            if (!val && !ctx.options.context?.optional) {
                return ctx.createError({ message: 'Email address is a required field' });
            }
            return true;
        }),
    [BusinessFields.ENTITY_TYPE]: yup.object().label('Entity type').when(['$optional'], {
        is: false,
        then: s => s.shape({
            label: STRING_VALIDATION.oneOf(LegalEntityTypeOptions.map(({ label }) => label)).required('Entity type is required'),
            value: STRING_VALIDATION.oneOf(LegalEntityTypeOptions.map(({ value }) => value)).required('Entity type is required'),
        }).default(undefined).required('Entity type is a required field'),
        otherwise: s => s.optional(),
    }),
    [BusinessFields.TIN]: STRING_VALIDATION.label('TIN/EIN').when([BusinessFields.B_TAX_METHOD], {
        is: 'SSN',
        then: s => s.optional(),
        otherwise: () => REQUIRED_VALIDATION('TIN/EIN').test(
            'TIN', 'Invalid TIN',
            (val, ctx) => {
                if (val && val.length !== 9) {
                    return ctx.createError({ message: 'TIN/EIN must be exactly 9 digits' });
                }
                return true;
            }
        )
    }),
    [BusinessFields.DOE]: DATE_VALIDATION('Date Established/Incorporated', {min: new Date('01/01/1900'), max: dayjs().startOf('d').toDate()}).label('Date Established/Incorporated').test(
        'Date Established/Incorporated', 'Date Established/Incorporated is a required field',
        (val, ctx) => {
            if (!val && !ctx.options.context?.optional) {
                return ctx.createError({ message: 'Date Established/Incorporated is a required field' });
            }
            return true;
        }
    ),
    [BusinessFields.DOT]: STRING_VALIDATION.when(`$isOpportunity`, {
        is: false,
        then: s => s.optional(),
        otherwise: () => DATE_VALIDATION('Target Date', {min: dayjs().startOf('d').toDate()}).test(
            'Target Date', 'Target Date is a required field',
            (val, ctx) => {
                if (!val && !ctx.options.context?.optional) {
                    return ctx.createError({ message: 'Target Date is a required field' });
                }
                return true;
            }),
        }),
    businessUrl: STRING_VALIDATION.when(['internetPercentage'], {
        is: (internet: string) => Number(internet || 0) > 0,
        then: () => REQUIRED_VALIDATION('Business website').url().label('Business website'),
        otherwise: s => s.url().optional()
    }),
    [BusinessFields.NO_OF_LOCATIONS]: NUM_VALIDATION.label('Number of Locations').when(['$isOpportunity'], {
        is: false,
        then: s => s.default(0).required().min(1, 'Min must be greater than 0').max(99, 'Max must be less than 99'),
        otherwise: s => s.optional().nullable(),
    }),
    businessTaxPayMethod: STRING_VALIDATION.when(['$isOpportunity'], {
        is: true,
        then: () => REQUIRED_VALIDATION('Business tax pay method').oneOf(['SSN', 'EIN/TIN']),
        otherwise: s => s.optional(),
    }),
});

/**
 * Application Processing Page
 */
export const applicationProcessingSchema = yup.object<ValidationContext>().shape({
    [ProcessingFields.AVG_TRANSACTION_SIZE]: STRING_VALIDATION.required().label('Avg. Transaction Size'),
    [ProcessingFields.HIGH_TICKET_VOLUME]: STRING_VALIDATION.required().label('High Tickets'),
    [ProcessingFields.IS_ACCEPTING_CC_CARDS]: STRING_VALIDATION.oneOf(['yes', 'no']).default('yes').required(),
    [ProcessingFields.EXPECTED_MONTHLY_CC_VOLUME]: STRING_VALIDATION.label('Monthly Volume').when([ProcessingFields.IS_ACCEPTING_CC_CARDS], {
        is: 'yes',
        then: s => s.required().min(1),
        otherwise: s => s.nullable().optional()
    }),
    [ProcessingFields.SWIPED_PERCENT]: PERCENT_VALIDATION.label('Swiped').required(),
    [ProcessingFields.KEYED_PERCENT]: PERCENT_VALIDATION.label('Keyed').required(),
    [ProcessingFields.MOTO_PERCENT]: PERCENT_VALIDATION.label('Mail Order / Telephone Order').required(),
    [ProcessingFields.INTERNET_PERCENT]: PERCENT_VALIDATION.label('Internet').required(),
    [ProcessingFields.IS_SEASONAL]: STRING_VALIDATION.oneOf(['yes', 'no']).default('no').required(),
    [ProcessingFields.SEASONAL_FROM]: OBJECT_VALIADATION.label('Seasonal From').when([ProcessingFields.IS_SEASONAL], {
        is: 'yes',
        then: s => s.required(),
        otherwise: s => s.optional(),
    }),
    [ProcessingFields.SEASONAL_TO]: OBJECT_VALIADATION.label('Seasonal To').when([ProcessingFields.IS_SEASONAL], {
        is: 'yes',
        then: s => s.required(),
        otherwise: s => s.optional(),
    }),
    [ProcessingFields.SERVICE_FOR_CC_HOLDERS]: STRING_VALIDATION.label('This').when([ProcessingFields.MOTO_PERCENT, ProcessingFields.INTERNET_PERCENT], {
        is: (moto: string, internet: string) => Number(moto || 0) >= 20 || Number(internet || 0) >= 1,
        then: s => s.required(),
    }),
}).test('is-100-Percent', 'Must be 100% in Total', (values) => {
    const total =
        Number(values.motoPercentage) +
        Number(values.internetPercentage) +
        Number(values.keyedPercentage) +
        Number(values.swipedPercentage)
    return total === 100;
});

/**
 * Opportunity Processing Page
 */
export const processingSchema = yup.object<ValidationContext>().shape({
    [ProcessingFields.MARKETING_WEBSITE]: yup.mixed().label('Marketing Website').when([ProcessingFields.IS_CNP_REQUIRED, '$optional'], {
        is: (cnpRequired: string, optional: boolean) => cnpRequired === 'yes' && !optional,
        then: s => s.test(
            'marketing-file-does-not-exist',
            'Marketing Website or Marketing File is Required',
            (_val, ctx) =>
                typeof (ctx.parent[ProcessingFields.MARKETING_FILE]) === 'object' ||
                isValidHttpUrl(ctx.parent[ProcessingFields.MARKETING_WEBSITE])),
        otherwise: s => s.nullable().optional()
    }),
    [ProcessingFields.MARKETING_FILE]: yup.mixed().label('File').when([ProcessingFields.IS_CNP_REQUIRED], {
        is: 'yes',
        then: s => s.test(
            'marketing-website-does-not-exist',
            'Marketing Website or Marketing File is Required',
            (_val, ctx) => {
                if ((!ctx.parent[ProcessingFields.MARKETING_FILE] && !ctx.parent[ProcessingFields.MARKETING_WEBSITE]) && ctx.options.context?.optional) {
                    return true;
                }
                return typeof (ctx.parent[ProcessingFields.MARKETING_FILE]) === 'object' || isValidHttpUrl(ctx.parent[ProcessingFields.MARKETING_WEBSITE])
            }),
        otherwise: s => s.nullable().optional()
    }),
    [ProcessingFields.TXN_COMPLETE_PERIOD]: yup.mixed().label('Transaction Complete Period').when([ProcessingFields.IS_CNP_REQUIRED], {
        is: 'yes',
        then: s => s.test(
            'txn-complete-period',
            'Transaction Completion Period is Required',
            (val, ctx) => {
                if (!val && ctx.options.context?.optional) {
                    return true;
                }
                return typeof val === 'object'
            }),
        otherwise: s => s.optional(),
    }),
    [ProcessingFields.TXN_COMPLETE_PERIOD_OTHER]: yup.mixed().label('Transaction Complete Period').when([ProcessingFields.IS_CNP_REQUIRED, ProcessingFields.TXN_COMPLETE_PERIOD, '$optional'], {
        is: (cnpRequired:string, txnCompleteBeforeShipping: Option, optional: boolean) =>
            cnpRequired === 'yes' && txnCompleteBeforeShipping?.value === 'Other' && !optional,
        then: () => REQUIRED_VALIDATION('Transaction Complete Period'),
        otherwise: s => s.optional(),
    }),
    [ProcessingFields.RECURRING_BILLING]: STRING_VALIDATION.label('Recurring billing').when([ProcessingFields.IS_CNP_REQUIRED, '$optional'], {
        is: (cnpRequired: string, optional: boolean) => cnpRequired === 'yes' && !optional,
        then: s => s.oneOf(['yes', 'no']),
        otherwise: s => s.optional(),
    }),
    [ProcessingFields.BILLING_PERIOD]: yup.mixed().label('Billing Period').when([ProcessingFields.RECURRING_BILLING, ProcessingFields.IS_CNP_REQUIRED], {
        is: (recurring: string, cnp: string) => recurring === 'yes' && cnp === 'yes',
        then: s => s.test(
            'is-billing-period-object',
            'Billing Period is Required',
            (val, ctx) => {
                if (!val && ctx.options.context?.optional) {
                    return true;
                }
                return typeof val === 'object'
            }),
        otherwise: s => s.optional(),
    }),
    [ProcessingFields.DB_COLLECTING_CC_NUMBERS]: STRING_VALIDATION.label('Card number collection').when([ProcessingFields.IS_CNP_REQUIRED, '$optional'], {
        is: (cnpRequired: string, optional: boolean) => cnpRequired === 'yes' && !optional,
        then: s => s.oneOf(['yes', 'no']),
        otherwise: s => s.optional()
    }),
    [ProcessingFields.PCI_COMPLIANT]: yup.mixed().label('PCI Compliant').when([ProcessingFields.DB_COLLECTING_CC_NUMBERS, ProcessingFields.IS_CNP_REQUIRED], {
        is: (dbCC: string, cnp: string) => dbCC === 'yes' && cnp === 'yes',
        then: s => s.test(
            'is-pci-compliant-object',
            'PCI Compliance is required',
            (val, ctx) => {
                if (!val && ctx.options.context?.optional) {
                    return true;
                }
                return typeof val === 'object'
            }),
        otherwise: s => s.optional(),
    }),
    [ProcessingFields.DOES_CUS_SIGN_CONTRACT]: STRING_VALIDATION.label('Customer signs contract').when([ProcessingFields.IS_CNP_REQUIRED, '$optional'], {
        is: (cnpRequired: string, optional: boolean) => cnpRequired === 'yes' && !optional,
        then: s => s.oneOf(['yes', 'no']),
        otherwise: s => s.optional()
    }),
    [ProcessingFields.CONTRACT_FILE]: yup.mixed().label('Contract File').when([ProcessingFields.DOES_CUS_SIGN_CONTRACT, ProcessingFields.IS_CNP_REQUIRED], {
        is: (sign: string, cnp: string) => sign === 'yes' && cnp === 'yes',
        then: s => s.test(
            'is-contract-files-object',
            'Contract File is Required',
            (val, ctx) => {
                if (!val && ctx.options.context?.optional) {
                    return true;
                }
                return typeof val === 'object'
            }),
        otherwise: s => s.optional(),
    }),
    [ProcessingFields.HOW_CUSTOMER_ORDER]: STRING_VALIDATION.label('How customer orders').when([ProcessingFields.IS_CNP_REQUIRED, '$optional'], {
        is: (cnpRequired: string, optional: boolean) => cnpRequired === 'yes' && !optional,
        then: () => REQUIRED_VALIDATION('How customer orders'),
    }),
    [ProcessingFields.VENDOR_PUR_ADDRESS]: STRING_VALIDATION.label('Vendor information').when([ProcessingFields.IS_CNP_REQUIRED, '$optional'], {
        is: (cnpRequired: string, optional: boolean) => cnpRequired === 'yes' && !optional,
        then: () => REQUIRED_VALIDATION('Vendor information'),
    }),
    [ProcessingFields.SERVICE_FOR_CC_HOLDERS]: STRING_VALIDATION.label('Products or services').when([ProcessingFields.IS_CNP_REQUIRED, '$optional'], {
        is: (cnpRequired: string, optional: boolean) => cnpRequired === 'yes' && !optional,
        then: () => REQUIRED_VALIDATION('Products or services'),
    }),
    [ProcessingFields.DAYS_TO_RETURN_CANCEL]: STRING_VALIDATION.label('Days to return or cancel').when('$optional', {
        is: false,
        then: () => REQUIRED_VALIDATION('Days to return or cancel'),
    }),
    [ProcessingFields.IS_GS_DELIVERY_AFTER_TXN_DAY]: STRING_VALIDATION.label('Delivery after transaction completion').test('Delivery after txn', 'Delivery after transaction completion',
        (val, ctx) => {
            if (!val && !ctx.options.context?.optional) {
                return ctx.createError({ message: 'Delivery after transaction completion is a required field' });
            }
            if (val) return ['yes', 'no'].includes(val)
            return true;
    }),
    [ProcessingFields.DELIVERY_IMMEDIATE]: PERCENT_VALIDATION.label('Immediately').when([ProcessingFields.IS_GS_DELIVERY_AFTER_TXN_DAY], {
        is: 'yes',
        then: () => REQUIRED_VALIDATION('Immediately'),
        otherwise: () => yup.number().transform(() => null).nullable().optional(),
    }),
    [ProcessingFields.DELIVERY_1_3_DAYS]: PERCENT_VALIDATION.label('1-3 days').when([ProcessingFields.IS_GS_DELIVERY_AFTER_TXN_DAY], {
        is: 'yes',
        then: () => REQUIRED_VALIDATION('1-3 days'),
        otherwise: () => yup.number().transform(() => null).optional().nullable(),
    }),
    [ProcessingFields.DELIVERY_4_7_DAYS]: PERCENT_VALIDATION.label('4-7 days').when([ProcessingFields.IS_GS_DELIVERY_AFTER_TXN_DAY], {
        is: 'yes',
        then: () => REQUIRED_VALIDATION('4-7 days'),
        otherwise: () => yup.number().transform(() => null).optional().nullable(),
    }),
    [ProcessingFields.DELIVERY_8_14_DAYS]: PERCENT_VALIDATION.label('8-14 days').when([ProcessingFields.IS_GS_DELIVERY_AFTER_TXN_DAY], {
        is: 'yes',
        then: () => REQUIRED_VALIDATION('8-14 days'),
        otherwise: () => yup.number().transform(() => null).optional().nullable(),
    }),
    [ProcessingFields.DELIVERY_15_30_DAYS]: PERCENT_VALIDATION.label('15-30 days').when([ProcessingFields.IS_GS_DELIVERY_AFTER_TXN_DAY], {
        is: 'yes',
        then: () => REQUIRED_VALIDATION('15-30 days'),
        otherwise: () => yup.number().transform(() => null).optional().nullable(),
    }),
    [ProcessingFields.DELIVERY_OVER_30_DAYS]: PERCENT_VALIDATION.label('over 30 days').when([ProcessingFields.IS_GS_DELIVERY_AFTER_TXN_DAY], {
        is: 'yes',
        then: () => REQUIRED_VALIDATION('Over 30 days'),
        otherwise: () => yup.number().transform(() => null).optional().nullable(),
    }),
}).test('is-100-Percent', 'Must be 100% in Total', (values, ctx) => {
        const sales = ctx?.options.context?.optional;
        if (values.isGSDeliveryAfterTxnDay === 'yes') {
            const total =
                Number(values.delivery_immediate_Percentage) +
                Number(values.delivery_1_3_DaysPercentage) +
                Number(values.delivery_4_7_DaysPercentage) +
                Number(values.delivery_8_14_DaysPercentage) +
                Number(values.delivery_15_30_DaysPercentage) +
                Number(values.delivery_Over_30_DaysPercentage);
            if (sales) return total <= 100
            return total === 100;
        }
        return true;
});

export const managingDetailsSchema = yup.object<ValidationContext>().shape({
    people: yup
        .array()
        .max(5)
        .of(yup.object({
            FirstName: STRING_VALIDATION.label('First Name').test('First name', 'First name is a required field', (val, ctx) => {
                const sales = ctx?.options.context?.optional;
                if (!val && !sales) {
                    return ctx.createError({ message: 'First name is a required field' });
                }
                if (val && val.includes(' ')) {
                    return ctx.createError({ message: 'First Name field should not contain a middle name or initial' });
                }
                return true;
            }),
            LastName: STRING_VALIDATION.label('Last Name').test('Last name', 'Last name is a required field',
                function validate(val, ctx) {
                    const sales = ctx?.options.context?.optional
                    if (!val && !sales) {
                        return this.createError({ message: 'Last name is a required field' });
                    }
                    return true;
                }),
            ContactNumber: STRING_VALIDATION.test('Phone', 'Contact phone is a required field',
                function validate(val, ctx) {
                    const sales = ctx?.options.context?.optional;
                    if (!val && !sales) return this.createError({ message: 'Contact phone is a required field' });
                    if (val && !val.match(phoneRegExp)) return this.createError({ message: 'Invalid phone number' });
                    return true;
                }),
            DateOfBirth: STRING_VALIDATION.label('Date of Birth').test('date-dob', 'DOB required', (val, ctx) => {
                   const salesUser = ctx?.options.context?.optional;
                   if (!val && !salesUser) {
                       return ctx.createError({ message: 'DOB is required' });
                   }
                   const isValid = DATE_VALIDATION('Date of Birth', { min: new Date('1900-01-01') }).isValidSync(val);
                   if (val && !isValid) {
                       return ctx.createError({ message: "Invalid Date" });
                   }
                   return true;
               }),
            SSN: STRING_VALIDATION.label('SSN').test('ssn', 'Invalid SSN', (val, ctx) => {
                  const sales = ctx?.options.context?.optional;
                  if (!val && !sales) {
                      return ctx.createError({ message: 'SSN is required' });
                  }
                  if (val && val.length !== 9) {
                      return ctx.createError({ message: 'SSN must be 9 digits' });
                  }
                  return true;
              }),
            Email: EMAIL_VALIDATION.test('email', 'Email address is a required field', (val, ctx) => {
                    const sales = ctx?.options.context?.optional;
                    if (!val && !sales) {
                        return ctx.createError({ message: 'Email address is a required field' });
                    }
                    return true;
                }),
            Title: OBJECT_VALIADATION.label('Title').test('title', 'Title is a required field', (val, ctx) => {
                    const sales = ctx.options.context?.optional;
                    if (!val && !sales) {
                        return ctx.createError({ message: 'Title is a required field' });
                    }
                    return true;
                }),
            PercentOwnership: PERCENT_VALIDATION.label('Ownership').test('ownership', 'Ownership percentage is a required field', (val, ctx) => {
                    const sales = ctx.options.context?.optional;
                    if (ctx.path === 'people[0].PercentOwnership' && ctx.parent.people?.[0].IsOwner?.toLowerCase() !== 'yes')  {
                        // people[0] can be the company representative (non-owner)
                        return true;
                    }
                    if (!val && !sales) {
                        return ctx.createError({ message: 'Ownership percentage is a required field' });
                    }
                    return true;
                }),
            Addr1: ADDRESS_VALIDATION.label('Address Line 1').when(['isPrimaryLocSameAsBusiness'], {
                is: 'yes',
                then: s => s.optional(),
                otherwise: s => s.test('Address Line 1', 'Address Line 1 is required', (val, ctx) => {
                        const sales = ctx.options.context?.optional;
                        if (!val && !sales) {
                            return ctx.createError({ message: 'Address Line 1 is a required field' });
                        }
                        return true;
                    }),
            }),
            Addr2: OPTIONAL_VALIDATION,
            City: STRING_VALIDATION.label('City').when(['isPrimaryLocSameAsBusiness'], {
                is: 'yes',
                then: s => s.optional(),
                otherwise: s => s.test('City', 'City is required', (val, ctx) => {
                        const sales = ctx.options.context?.optional;
                        if (!val && !sales) {
                            return ctx.createError({ message: 'City is a required field' });
                        }
                        return true;
                    }),
            }),
            State: OBJECT_VALIADATION.label('State').when(['isPrimaryLocSameAsBusiness'], {
                is: 'yes',
                then: s => s.optional(),
                otherwise: s => s.test('State', 'State is required', (val, ctx) => {
                        const sales = ctx.options.context?.optional;
                        if (!val && !sales) {
                            return ctx.createError({ message: 'State is a required field' });
                        }
                        return true;
                    }),
            }),
            ZipCode: ZIP_CODE_VALIDATION.when(['isPrimaryLocSameAsBusiness'], {
                is: 'yes',
                then: s => s.optional(),
                otherwise: s => s.test('Zip code', 'Zip code is required', (val, ctx) => {
                        const sales = ctx.options.context?.optional;
                        if (!val && !sales) {
                            return ctx.createError({ message: 'Zip code is a required field' });
                        }
                        return true;
                    }),
            }),
           PhotoID: yup.mixed().label('Photo ID')
               .test('photo id', 'Photo ID', (val, ctx) => {
                   if (!val && !ctx.options.context?.optional && ctx.from?.[1]?.value?.isUnsolicited) {
                       return ctx.createError({ message: 'Photo ID is a required field' });
                   }
                   if (val && typeof val !== 'object') {
                       return ctx.createError({ message: 'Invalid file input' });
                   }
                   return true;
           }),
        }))
        .optional(),
}).test('owner-100-percent', 'ownership must less than 100', (fields) =>
    fields.people && fields.people?.reduce((acc: number, val: { PercentOwnership?: number }) => acc + Number((val?.PercentOwnership || 0)), 0) <= 100);

export const bankingSchema = yup.object<ValidationContext>().shape({
    [BankFields.P_BANK_ROUTING_NUMBER]: STRING_VALIDATION.label('Routing number')
        .test('Routing number', 'Routing number is a required field', (val, ctx) => {
            if (!val && !ctx.options.context?.optional) {
                return ctx.createError({ message: 'Routing number is a required field' });
            }
            if (val) {
                if (val.length !== 9) return ctx.createError({ message: 'Routing number must be exactly 9 digits' });
                if (!brnv.ABARoutingNumberIsValid(val)) return ctx.createError({ message: 'Invalid Routing Number' });
            }
            return true;
        }),
    [BankFields.P_BANK_ACCOUNT_NUMBER]: STRING_VALIDATION.label('Account Number')
        .test('account number', 'Account number is a required field', (val, ctx) => {
            if (!val && !ctx.options.context?.optional) return ctx.createError({ message: 'Account number is a required field' });
            if (val && val.length < 3) return ctx.createError({ message: 'Invalid account number' });
            return true;
        }),
    [BankFields.P_BANK_ACCOUNT_FULL_NAME]: STRING_VALIDATION.label('Account Holder Name').when(['$isOpportunity'], {
        is: false,
        then: () => REQUIRED_VALIDATION('Account Holder Name'),
        otherwise: s => s.optional(),
    }),
    [BankFields.P_ACC_BANK_NAME]: STRING_VALIDATION.label('Bank Name').when(['$isOpportunity'], {
        is: false,
        then: () => REQUIRED_VALIDATION('Bank Name'),
        otherwise: s => s.optional(),
    }),
    [BankFields.P_BANK_ACC_FILE]: yup.mixed().label('Voided Check or Bank Letter Document Upload')
        .test('voided check', 'Voided Check or Bank Letter Document Upload', (val, ctx) => {
            if (!val && !ctx.options.context?.optional) {
                return ctx.createError({ message: 'Voided Check or Bank Letter Document Upload is a required field' });
            }
            if (val && typeof val !== 'object') {
                return ctx.createError({ message: 'Invalid file input' });
            }
            return true;
        }),
    [BankFields.EXTRA_FILES]: yup.array().of(yup.object({}).nullable()).optional(),
    [BankFields.GUARANTEE_STATEMENT1]: yup.mixed().when([BankFields.WAIVE_PERSONAL_GUARANTEE], {
        is: 'yes',
        then: s => s.test('Statement 1', 'Statement 1', (val, ctx) => {
            if (!val && !ctx.options.context?.optional) {
                return ctx.createError({ message: 'Statement 1 is a required field' });
            }
            if (val && typeof val !== 'object') {
                return ctx.createError({ message: 'Invalid file input' });
            }
            return true;
        }),
        otherwise: () => yup.mixed().nullable().optional()
    }),
    [BankFields.GUARANTEE_STATEMENT2]: yup.mixed().when([BankFields.WAIVE_PERSONAL_GUARANTEE], {
        is: 'yes',
        then: s => s.test('Statement 2', 'Statement 2', (val, ctx) => {
            if (!val && !ctx.options.context?.optional) {
                return ctx.createError({ message: 'Statement 2 is a required field' });
            }
            if (val && typeof val !== 'object') {
                return ctx.createError({ message: 'Invalid file input' });
            }
            return true;
        }),
        otherwise: () => yup.mixed().nullable().optional()
    }),
    [BankFields.GUARANTEE_STATEMENT3]: yup.mixed().when([BankFields.WAIVE_PERSONAL_GUARANTEE], {
        is: 'yes',
        then: s => s.test('Statement 3', 'Statement 3', (val, ctx) => {
            if (!val && !ctx.options.context?.optional) {
                return ctx.createError({ message: 'Statement 3 is a required field' });
            }
            if (val && typeof val !== 'object') {
                return ctx.createError({ message: 'Invalid file input' });
            }
            return true;
        }),
        otherwise: () => yup.mixed().nullable().optional()
    }),
    [BankFields.GUARANTEE_B_SHEET]: yup.mixed().when([BankFields.WAIVE_PERSONAL_GUARANTEE], {
        is: 'yes',
        then: s => s.test('Balance Sheet', 'Balance Sheet', (val, ctx) => {
            if (!val && !ctx.options.context?.optional) {
                return ctx.createError({ message: 'Balance sheet is a required field' });
            }
            if (val && typeof val !== 'object') {
                return ctx.createError({ message: 'Invalid file input' });
            }
            return true;
        }),
        otherwise: () => yup.mixed().nullable().optional()
    }),
    [BankFields.GUARANTEE_PL_STATEMENT]: yup.mixed().when([BankFields.WAIVE_PERSONAL_GUARANTEE], {
        is: 'yes',
        then: s => s.test('Profit/Loss Statement', 'Profit/Loss Statement', (val, ctx) => {
            if (!val && !ctx.options.context?.optional) {
                return ctx.createError({ message: 'Profit/Loss statement is a required field' });
            }
            if (val && typeof val !== 'object') {
                return ctx.createError({ message: 'Invalid file input' });
            }
            return true;
        }),
        otherwise: () => yup.mixed().nullable().optional()
    }),
});

/**
 * Equipment Setup Page
 */
export const equipmentSetupSchema = yup.object<ValidationContext>().shape({
    [EquipmentSetUpFields.IS_SHIPPING_SAME_AS_PHYSICAL]: STRING_VALIDATION.oneOf(['yes', 'no']).required(),
    [EquipmentSetUpFields.SHIPPING_ADD1]: STRING_VALIDATION.label('Address Line 1').when([EquipmentSetUpFields.IS_SHIPPING_SAME_AS_PHYSICAL], {
        is: 'no',
        then: () => ADDRESS_VALIDATION.label('Address Line 1')
            .test('Address Line 1', 'Address Line 1 is required', (val, ctx) => {
                if (!val && !ctx.options.context?.optional) {
                    return ctx.createError({ message: 'Address Line 1 is a required field' });
                }
                return true;
            }),
        otherwise: s => s.optional()
    }),
    [EquipmentSetUpFields.SHIPPING_ADD2]: OPTIONAL_VALIDATION.label('Address Line 2'),
    [EquipmentSetUpFields.SHIPPING_CITY]: STRING_VALIDATION.label('City').when([EquipmentSetUpFields.IS_SHIPPING_SAME_AS_PHYSICAL], {
        is: 'no',
        then: () => REQUIRED_VALIDATION('City'),
        otherwise: s => s.optional()
    }),
    [EquipmentSetUpFields.SHIPPING_STATE]: yup.object().label('State').when([EquipmentSetUpFields.IS_SHIPPING_SAME_AS_PHYSICAL, '$optional'], {
        is: (shippingAddrSameAsBusiness: string, optional: boolean) => shippingAddrSameAsBusiness === 'no' && !optional,
        then: s => s.shape({
               label: STRING_VALIDATION.oneOf(US_STATES.map(({ label }) => label)).required('State is required'),
               value: STRING_VALIDATION.oneOf(US_STATES.map(({ value }) => value)).required('State is required'),
        }).default(undefined).required('State is a required field'),
        otherwise: s => s.nullable().optional(),
    }),
    [EquipmentSetUpFields.SHIPPING_ZIPCODE]: ZIP_CODE_VALIDATION.label('Zip code').when([EquipmentSetUpFields.IS_SHIPPING_SAME_AS_PHYSICAL], {
        is: 'no',
        then: () => REQUIRED_VALIDATION('Zip code'),
        otherwise: s => s.optional()
    }),
    [EquipmentSetUpFields.SHIPPING_PHONE]: yup.string().label('Phone').when([EquipmentSetUpFields.IS_SHIPPING_SAME_AS_PHYSICAL], {
       is: 'no',
       then: s => s.test('phone', 'Phone is a required field', (val, ctx) => {
            if (!val && !ctx.options.context?.optional) {
                return ctx.createError({ message: 'Business phone is a required field' });
            }
            if (val && !val.match(phoneRegExp)) {
                return ctx.createError({ message: 'Invalid phone number' });
            }
            return true;
       }),
       otherwise: s => s.nullable().optional(),
    }),
    [EquipmentSetUpFields.INSTALL_CONTACT_FIRST_NAME]: REQUIRED_VALIDATION('First Name'),
    [EquipmentSetUpFields.INSTALL_CONTACT_LAST_NAME]: REQUIRED_VALIDATION('Last Name'),
    [EquipmentSetUpFields.INSTALL_CONTACT_PHONE]: yup.string().label('Phone Number')
        .test('phone', 'Phone Number is a required field', (val, ctx) => {
            if (!val && !ctx.options.context?.optional) {
                return ctx.createError({ message: 'Business phone is a required field' });
            }
            if (val && !val.match(phoneRegExp)) {
                return ctx.createError({ message: 'Invalid phone number' });
            }
            return true;
        }),
    [EquipmentSetUpFields.INSTALL_CONTACT_EMAIL]: EMAIL_VALIDATION
        .test('email', 'Email address is a required field', (val, ctx) => {
            if (!val && !ctx.options.context?.optional) {
                return ctx.createError({ message: 'Email address is a required field' });
            }
            return true;
        }),
    [EquipmentSetUpFields.UTILIZE_TIPS]: STRING_VALIDATION.when(['$optional', '$hasEIServerOrCloud', '$hasEmergepay'], {
        is: (optional: boolean, hasEIServerOrCloud: boolean, hasEmergepay: boolean) =>  (hasEIServerOrCloud || hasEmergepay)  && !optional,
        then: s => s.oneOf(['yes', 'no']).required(),
    }),
    [EquipmentSetUpFields.UTILIZE_CARD_STORAGE]: STRING_VALIDATION.when(['$optional', '$hasEIServerOrCloud'], {
        is: (optional: boolean, hasEIServerOrCloud: boolean) => hasEIServerOrCloud && !optional,
        then: s => s.oneOf(['yes', 'no']).required(),
    }),
    [EquipmentSetUpFields.WINDOWS_TEN_OR_NEWER]: STRING_VALIDATION.when(['$optional', '$hasEIServerOrCloud'], {
        is: (optional: boolean, hasEIServerOrCloud: boolean) => hasEIServerOrCloud && !optional,
        then: s => s.oneOf(['yes', 'no']).required(),
    }),
    [EquipmentSetUpFields.ETHERNET_AVAILABLE]: STRING_VALIDATION.when(['$optional', '$hasEIServerOrCloud'], {
        is: (optional: boolean, hasEIServerOrCloud: boolean) => hasEIServerOrCloud && !optional,
        then: s => s.oneOf(['yes', 'no']).required(),
    }),
    [EquipmentSetUpFields.FIVE_PORT_SWITCH_PURCHASE]: STRING_VALIDATION.label('Would you like to purchase a 5-port switch ($21) from Gravity Payments').when([EquipmentSetUpFields.ETHERNET_AVAILABLE, '$optional'], {
        is: (ethernet: string, optional: boolean) => ethernet === 'no' && !optional,
        then: s => s.oneOf(['yes', 'no']).required(),
    }),
    [EquipmentSetUpFields.FEET_CC_MACHINE_TO_ROUTER]: STRING_VALIDATION.label('Feet from credit card machine to modem/router').when([EquipmentSetUpFields.ETHERNET_AVAILABLE, '$optional'], {
        is: (ethernet: string, optional: boolean) => ethernet === 'yes' && !optional,
        then: s => s.required(),
    }),
    [EquipmentSetUpFields.POS_ACCESS]: STRING_VALIDATION.when(['$optional', '$hasEIServerOrCloud'], {
        is: (optional: boolean, hasEIServerOrCloud: boolean) => hasEIServerOrCloud && !optional,
        then: s => s.oneOf(['yes', 'no']).required(),
    }),
    [EquipmentSetUpFields.HAND_KEY_TRANSACTIONS]: STRING_VALIDATION.when(['$optional', '$hasEIServerOrCloud'], {
        is: (optional: boolean, hasEIServerOrCloud: boolean) => hasEIServerOrCloud && !optional,
        then: s => s.oneOf(['yes', 'no']).required(),
    }),
    [EquipmentSetUpFields.NO_OF_COMPUTERS]: STRING_VALIDATION.label('Number of computers').when([EquipmentSetUpFields.HAND_KEY_TRANSACTIONS, '$optional'], {
        is: (handKey: string, optional: boolean) => handKey === 'yes' && !optional,
        then: s => s.required(),
    }),
    [EquipmentSetUpFields.IS_ACCEPTING_GIFT_CARDS]: STRING_VALIDATION.when(['$optional', '$hasEIServerOrCloud'], {
        is: (optional: boolean, hasEIServerOrCloud: boolean) => hasEIServerOrCloud && !optional,
        then: s => s.oneOf(['yes', 'no']).required(),
    }),
    [EquipmentSetUpFields.IS_INTERESTED_GIFT_CARDS]: STRING_VALIDATION.label('Are you interested in accepting gift cards').when([EquipmentSetUpFields.IS_ACCEPTING_GIFT_CARDS, '$optional'], {
        is: (acceptingGiftCards: string, optional: boolean) => acceptingGiftCards === 'no' && !optional,
        then: s => s.oneOf(['yes', 'no']).required(),
    }),
    [EquipmentSetUpFields.GIFT_CARD_PROVIDER]: STRING_VALIDATION.label('Current Gift Card Provider').when([EquipmentSetUpFields.IS_ACCEPTING_GIFT_CARDS, '$optional'], {
        is: (acceptingGiftCards: string, optional: boolean) => acceptingGiftCards === 'yes' && !optional,
        then: s => s.required(),
    }),
    [EquipmentSetUpFields.BALANCE_SHEET_PROVIDED]: STRING_VALIDATION.label('Balance Sheet Provided to Gravity').when([EquipmentSetUpFields.IS_ACCEPTING_GIFT_CARDS, '$optional'], {
        is: (acceptingGiftCards: string, optional: boolean) => acceptingGiftCards === 'yes' && !optional,
        then: s => s.oneOf(['yes', 'no']).required(),
    }),
    [EquipmentSetUpFields.BLANK_GIFT_CARD_PROVIDED]: STRING_VALIDATION.label('Blank Gift Card Provided to Gravity').when([EquipmentSetUpFields.IS_ACCEPTING_GIFT_CARDS, '$optional'], {
        is: (acceptingGiftCards: string, optional: boolean) => acceptingGiftCards === 'yes' && !optional,
        then: s => s.oneOf(['yes', 'no']).required(),
    }),
    [EquipmentSetUpFields.NO_OF_WORK_STATIONS]: STRING_VALIDATION.label('Number of work stations').when(['$optional', '$hasEIServerOrCloud'], {
        is: (optional: boolean, hasEIServerOrCloud: boolean) => hasEIServerOrCloud && !optional,
        then: s => s.required(),
    }),
    [EquipmentSetUpFields.INSTALL_ADMIN_ACCESS]: STRING_VALIDATION.when(['$optional', '$hasEIServerOrCloud'], {
        is: (optional: boolean, hasEIServerOrCloud: boolean) => hasEIServerOrCloud && !optional,
        then: s => s.oneOf(['yes', 'no']).required(),
    }),
    [EquipmentSetUpFields.REPORTING_TRAINING]: STRING_VALIDATION.when(['$optional', '$hasEIServerOrCloud', '$hasEmergepay'], {
        is: (optional: boolean, hasEIServerOrCloud: boolean, hasEmergepay: boolean) => (hasEIServerOrCloud || hasEmergepay) && !optional,
        then: s => s.oneOf(['yes', 'no']).required(),
    }),
    [EquipmentSetUpFields.NO_FOR_REPORTING_TRAINING]: STRING_VALIDATION.label('Number of people for training').when([EquipmentSetUpFields.REPORTING_TRAINING, '$optional'], {
        is: (reportingTraining: string, optional: boolean) => reportingTraining === 'yes' && !optional,
        then: s => s.required(),
    }),
    [EquipmentSetUpFields.PROCESSING_DATE]: STRING_VALIDATION.label('Anticipated processing date').when(['$optional', '$hasEIServerOrCloud', '$hasEmergepay'], {
        is: (optional: boolean, hasEIServerOrCloud: boolean, hasEmergepay: boolean) => (hasEIServerOrCloud || hasEmergepay) && !optional,
        then: () => DATE_VALIDATION('Anticipated processing date', {required: true})
    })
});

export const signSchema = yup.object({
    keyedInSignature: yup.string().trim().required("Please click Agree & Sign to continue")
        .test('Signature', 'Signature is required', (val, ctx) => {
            if (!val) {
                return ctx.createError({ message: 'Please click Agree & Sign to continue.' });
            }
            const expectedSignature = `${ctx.parent.people[0].FirstName.toLowerCase()} ${ctx.parent.people[0].LastName.toLowerCase()}`;
            const signature = val.toLowerCase().split(' '.repeat(5))[0]; // we add 5 white spaces between signature name & date
            if (signature !== expectedSignature) {
                return ctx.createError({ message: 'Signature must match name on file.' });
            }
            return true;
        }),
    ipAddress: yup.string().required('We encountered an error. Please reload the page to continue.'),
    timestamp: yup.string().required('Please accept the terms to continue.'),
});

export const validatorSchema = yup
    .object()
    .concat(businessSchema)
    .concat(processingSchema)
    .concat(managingDetailsSchema)
    .concat(bankingSchema)
    .concat(equipmentSetupSchema);

export const applicationValidatorSchema = yup
    .object()
    .concat(businessSchema)
    .concat(applicationProcessingSchema)
    .concat(managingDetailsSchema)
    .concat(bankingSchema);

export const validate = async (name: string, value: string | object) =>
    validatorSchema.pick([name as any]).isValid({ [name]: value });
