import { Table } from "react-bootstrap";
import TextComponent from "@common/typography/TextComponent";
import InfoIcon from '@components/icons/InfoIcon';
import OverlayInfoComponent from '@common/inputs/OverlayInfoComponent';
import { format2USAmount, formatRate } from "@utils/FormUtils";
import useOpportunityContext from "@hooks/useOpportunityContext";

const hasValidAmount = (amount?: number) => Boolean(amount && amount !== 0);

const PricingDetails = () => {
    const { complianceFee, lineItems, pci, quote } = useOpportunityContext();
    const {
        Pricing_Structure__c = 'Cost Plus',
        Credit_Qual_Discount_Rate__c,
        Debit_Qual_Discount_Rate__c,
        Amex_Discount_Rate__c,
        VS_MC_DSC_Transaction_Fee__c,
        Amex_Transaction_Fee__c,
        Debit_Transaction_Fee__c,
        EBT_Transaction_Fee__c,
        Statement_Service_Fee__c,
        Annual_Fee__c,
        Batch_Fee__c,
        Monthly_Minimum__c,
        AVS_Fee__c,
        Voice_Auth_Fee__c,
        Chargeback_Fee__c,
        Retrieval_Fee__c,
        Early_Termination_Fee__c,
        Recurring_Fee_Amount__c,
        Gravity_PIN_Debit_Discount_Rate__c,
        Surcharge_Monthly_Program_Fee__c,
        Surcharge_Volume_Based_Program_Fee__c
    } = quote;
    const pricingTableOne = [Credit_Qual_Discount_Rate__c, Debit_Qual_Discount_Rate__c, Amex_Discount_Rate__c, Gravity_PIN_Debit_Discount_Rate__c,
        VS_MC_DSC_Transaction_Fee__c, Amex_Transaction_Fee__c, Debit_Transaction_Fee__c, EBT_Transaction_Fee__c, Amex_Transaction_Fee__c, Statement_Service_Fee__c,
        Annual_Fee__c, Batch_Fee__c, complianceFee, Recurring_Fee_Amount__c, Surcharge_Monthly_Program_Fee__c, Surcharge_Volume_Based_Program_Fee__c];
    const showPricingTableOne = pricingTableOne.some(item => hasValidAmount(item));
    const pricingStructureDetails = new Map([
        ['Cost Plus', 'Pass through fees include all Interchange and Card Association Fees. Card Association Fees include: Dues and Assessments, Visa FANF, Visa Misuse of Auth Fee, Visa ACQ Processing Fee, Visa Zero Floor Limit Fee, Visa Int’l ACQ Fee, Visa ACQ ISA Fee, Visa Trans Integrity Fee, Visa File Transmission Fee, Visa Acquirer Credit Voucher Data Processing Fee, MC ACQ Support Fee, MC Cross Border Fee, MC NABU Fee, MC Proc Integrity Fee, MC Kilobyte Fee, MC CVC2 Fee, MC ICA AVS Fee, MC Digital Enablement Fee, Discover Int’l Proc Fee, Discover Int’l Service Fee, Discover Data Usage Fee, Discover Network Auth Fee, American Express OptBlue® Network Fee, American Express OptBlue® Non- swiped Transaction Fee, American Express OptBlue® Inbound Fee, American Express OptBlue® Technical Specifications Non-Compliance Fee, and any other similar pass-through fees as may be created in the future.'],
        ['Flat Rate', 'Interchange and Card Association Fees are encompassed in the Flat Rate, unless otherwise disclosed. **Association Compliance Fee of 0.01% is billed against total card volume and covers miscellaneous Card Association Fees Gravity Payments does not pass through, as well as the cost of mandatory IRS and PCI compliance reporting. '],
        ['Surcharge', 'Interchange and Card Association Fees are encompassed in the Flat Rate, unless otherwise disclosed. **Association Compliance Fee of 0.01% is billed against total card volume and covers miscellaneous Card Association Fees Gravity Payments does not pass through, as well as the cost of mandatory IRS and PCI compliance reporting. '],
        ['Tiered', 'The qualified rate as stated below will be charged electronically authorized payment card transactions that are in batches closed daily. All payment card transactions may be charged higher than the listed qualified rate (mid- or non-qualified rate). Card Association Fees, excluding Dues and Assessments, are passed through. '],
    ]);

    // get IMS_Fee__c from Line Items, give priority to Items that have a positive or higher IMS_Fee__c
    const imsFee = Math.max(...(lineItems.flatMap(item => item.IMS_Fee__c || []) as number[]), 0);
    return <div>
        <h5>{Pricing_Structure__c === 'Surcharge' ? 'Flat Rate (with surcharging)' : Pricing_Structure__c} Pricing Structure</h5>
        {pricingStructureDetails.has(Pricing_Structure__c) && <TextComponent color='pewter' className='mb-5'>
            {pricingStructureDetails.get(Pricing_Structure__c)}
        </TextComponent>}
        <h5>Pricing</h5>
        {showPricingTableOne && <Table responsive striped>
            <thead>
                <tr>
                    <th className="text-start">Description</th>
                    <th className="text-start">Rate % / Fee $</th>
                    <th className="text-end">Assessed</th>
                </tr>
            </thead>
            <tbody>
                {hasValidAmount(Annual_Fee__c) && <tr>
                    <td aria-label="Description" className="text-start">Regulatory Product Fee</td>
                    <td aria-label="Rate % / Fee $" className="text-start">{format2USAmount(Annual_Fee__c)}</td>
                    <td aria-label="Assessed" className="text-end">Monthly</td>
                </tr>}
                {hasValidAmount(Statement_Service_Fee__c) && <tr>
                    <td aria-label="Description" className="text-start">Statement/Service Fee</td>
                    <td aria-label="Rate % / Fee $" className="text-start">{format2USAmount(Statement_Service_Fee__c)}</td>
                    <td aria-label="Assessed" className="text-end">Monthly</td>
                </tr>}
                {hasValidAmount(Recurring_Fee_Amount__c) && <tr>
                    <td aria-label="Description" className="text-start">EMV Security Fee</td>
                    <td aria-label="Rate % / Fee $" className="text-start">{format2USAmount(Recurring_Fee_Amount__c)}</td>
                    <td aria-label="Assessed" className="text-end">Monthly</td>
                </tr>}
                {hasValidAmount(Credit_Qual_Discount_Rate__c) && <tr>
                    <td aria-label="Description" className="text-start">VS/MC/DSC Discount Rate</td>
                    <td aria-label="Rate % / Fee $" className="text-start">{formatRate(Credit_Qual_Discount_Rate__c)}</td>
                    <td aria-label="Assessed" className="text-end">Gross Monthly Volume</td>
                </tr>}
                {(hasValidAmount(Debit_Qual_Discount_Rate__c) && Debit_Qual_Discount_Rate__c !== Credit_Qual_Discount_Rate__c) && <tr>
                    <td aria-label="Description" className="text-start">VS/MC/DS Signature Debit Discount Rate</td>
                    <td aria-label="Rate % / Fee $" className="text-start">{formatRate(Debit_Qual_Discount_Rate__c)}</td>
                    <td aria-label="Assessed" className="text-end">Gross Monthly Volume</td>
                </tr>}
                {hasValidAmount(Amex_Discount_Rate__c) && <tr>
                    <td aria-label="Description" className="text-start">American Express OptBlue Discount Rate</td>
                    <td aria-label="Rate % / Fee $" className="text-start">{formatRate(Amex_Discount_Rate__c)}</td>
                    <td aria-label="Assessed" className="text-end">Gross Monthly Volume</td>
                </tr>}
                {hasValidAmount(Gravity_PIN_Debit_Discount_Rate__c) && <tr>
                    <td aria-label="Description" className="text-start">Pin Debit Discount Rate</td>
                    <td aria-label="Rate % / Fee $" className="text-start">{formatRate(Gravity_PIN_Debit_Discount_Rate__c)}</td>
                    <td aria-label="Assessed" className="text-end">Gross Monthly Volume</td>
                </tr>}
                {hasValidAmount(complianceFee) && <tr>
                    <td aria-label="Description" className="text-start">Association Compliance Fee</td>
                    <td aria-label="Rate % / Fee $" className="text-start">{formatRate(complianceFee)}</td>
                    <td aria-label="Assessed" className="text-end">Gross Card Volume</td>
                </tr>}
                {hasValidAmount(VS_MC_DSC_Transaction_Fee__c) && <tr>
                    <td aria-label="Description" className="text-start">VS/MC/DSC Transaction Fee</td>
                    <td aria-label="Rate % / Fee $" className="text-start">{format2USAmount(VS_MC_DSC_Transaction_Fee__c)}</td>
                    <td aria-label="Assessed" className="text-end">Per Transaction</td>
                </tr>}
                {hasValidAmount(Amex_Transaction_Fee__c) && <tr>
                    <td aria-label="Description" className="text-start">American Express OptBlue Transaction Fee</td>
                    <td aria-label="Rate % / Fee $" className="text-start">{format2USAmount(Amex_Transaction_Fee__c)}</td>
                    <td aria-label="Assessed" className="text-end">Per Transaction</td>
                </tr>}
                {hasValidAmount(Debit_Transaction_Fee__c) && <tr>
                    <td aria-label="Description" className="text-start">Pin Debit Transaction Fee Plus Network Fees</td>
                    <td aria-label="Rate % / Fee $" className="text-start">{format2USAmount(Debit_Transaction_Fee__c)}</td>
                    <td aria-label="Assessed" className="text-end">Per Transaction</td>
                </tr>}

                {hasValidAmount(EBT_Transaction_Fee__c) && <tr>
                    <td aria-label="Description" className="text-start">EBT Transaction Fee</td>
                    <td aria-label="Rate % / Fee $" className="text-start">{format2USAmount(EBT_Transaction_Fee__c)}</td>
                    <td aria-label="Assessed" className="text-end">Per Transaction</td>
                </tr>}
                {hasValidAmount(Amex_Transaction_Fee__c) && <tr>
                    <td aria-label="Description" className="text-start">Non-Bankcard Transaction Fee</td>
                    <td aria-label="Rate % / Fee $" className="text-start">{format2USAmount(Amex_Transaction_Fee__c)}</td>
                    <td aria-label="Assessed" className="text-end">Per Transaction</td>
                </tr>}
                {hasValidAmount(Batch_Fee__c) && <tr>
                    <td aria-label="Description" className="text-start">Batch Fee</td>
                    <td aria-label="Rate % / Fee $" className="text-start">{format2USAmount(Batch_Fee__c)}</td>
                    <td aria-label="Assessed" className="text-end">Per Batch</td>
                </tr>}

                {hasValidAmount(Surcharge_Monthly_Program_Fee__c)  && <tr>
                    <td aria-label="Description" className="text-start">Surcharge Monthly Program Fee</td>
                    <td aria-label="Rate % / Fee $" className="text-start">{format2USAmount(Surcharge_Monthly_Program_Fee__c)}</td>
                    <td aria-label="Assessed" className="text-end">Monthly</td>
                </tr>}
                {hasValidAmount(Surcharge_Volume_Based_Program_Fee__c) && <tr>
                    <td aria-label="Description" className="text-start">Surcharge Volume Program Fee</td>
                    <td aria-label="Rate % / Fee $" className="text-start">{formatRate(Surcharge_Volume_Based_Program_Fee__c)}</td>
                    <td aria-label="Assessed" className="text-end">Gross Monthly Volume</td>
                </tr>}
            </tbody>
        </Table>}
        <br />
        <TextComponent fontWeight='600' color='charcoal' className='text-left mb-2'>
            The items below will only be charged when applicable:
        </TextComponent>
        <Table responsive striped>
            <thead>
                <tr>
                    <th className="text-start">Description</th>
                    <th className="text-start">Rate % / Fee $</th>
                    <th className="text-end">Assessed</th>
                </tr>
            </thead>
            <tbody>
                {pci.required && <>
                    <tr>
                        <td aria-label="Description" className="text-start">PCI Annual</td>
                        <td aria-label="Rate % / Fee $" className="text-start">{format2USAmount(pci.fees.annual)}</td>
                        <td aria-label="Assessed" className="text-end">Annual</td>
                    </tr>
                    <tr>
                        <td aria-label="Description" className="text-start">PCI Second Location Annual</td>
                        <td aria-label="Rate % / Fee $" className="text-start">{format2USAmount(pci.fees.secondLocation)}</td>
                        <td aria-label="Assessed" className="text-end">Annual</td>
                    </tr>
                </>}

                {hasValidAmount(Monthly_Minimum__c) && <tr>
                    <td aria-label="Description" className="text-start">Monthly Minimum</td>
                    <td aria-label="Rate % / Fee $" className="text-start">{format2USAmount(Monthly_Minimum__c)}</td>
                    <td aria-label="Assessed" className="text-end">Monthly</td>
                </tr>}
                {pci.required && <tr>
                    <td aria-label="Description" className="text-start">PCI Non-Compliance
                        <OverlayInfoComponent
                            popoverText="Assessed per month for each account that does not maintain compliance. "
                            placement="auto"
                            className="mx-2"
                        >
                            <InfoIcon />
                        </OverlayInfoComponent></td>
                    <td aria-label="Rate % / Fee $" className="text-start">{format2USAmount(pci.fees.nonCompliance)}</td>
                    <td aria-label="Assessed" className="text-end">Monthly</td>
                </tr>}
                <tr>
                    <td aria-label="Description" className="text-start">Invalid TIN Fee
                        <OverlayInfoComponent
                            popoverText="Applicable for each month combination of Business Name and EIN  are invalid. Based on responses from the IRS database."
                            placement="auto"
                            className="mx-2"
                        >
                            <InfoIcon />
                        </OverlayInfoComponent>
                    </td>
                    <td aria-label="Rate % / Fee $" className="text-start">{format2USAmount(9.95)}</td>
                    <td aria-label="Assessed" className="text-end">Per Month Until Correct</td>
                </tr>
                {hasValidAmount(AVS_Fee__c) && <tr>
                    <td aria-label="Description" className="text-start">Address Verification Service
                        <OverlayInfoComponent
                            popoverText="Assessed when a merchant submits a transaction for authorization or settlement and includes address verification service (AVS) as part of the transaction. AVS is used to verify that the address provided by the cardholder matches the billing address on file with the card issuer."
                            placement="auto"
                            className="mx-2"
                        >
                            <InfoIcon />
                        </OverlayInfoComponent>
                    </td>
                    <td aria-label="Rate % / Fee $" className="text-start">{format2USAmount(AVS_Fee__c)}</td>
                    <td aria-label="Assessed" className="text-end">Per AVS Request</td>
                </tr>}
                {hasValidAmount(Voice_Auth_Fee__c) && <tr>
                    <td aria-label="Description" className="text-start">Voice Auth Fee
                        <OverlayInfoComponent
                            popoverText="Assessed when a merchant requests authorization for a transaction by calling the card issuer's voice authorization center instead of using an electronic authorization method like a point-of-sale terminal or online gateway."
                            placement="auto"
                            className="mx-2"
                        >
                            <InfoIcon />
                        </OverlayInfoComponent>
                    </td>
                    <td aria-label="Rate % / Fee $" className="text-start">{format2USAmount(Voice_Auth_Fee__c)}</td>
                    <td aria-label="Assessed" className="text-end">Per Voice Auth</td>
                </tr>}
                {hasValidAmount(Chargeback_Fee__c) && <tr>
                    <td aria-label="Description" className="text-start">Chargeback/ACH Reject Fee</td>
                    <td aria-label="Rate % / Fee $" className="text-start">{format2USAmount(Chargeback_Fee__c)}</td>
                    <td aria-label="Assessed" className="text-end">Per Chargeback</td>
                </tr>}
                {hasValidAmount(Retrieval_Fee__c) && <tr>
                    <td aria-label="Description" className="text-start">Retrieval Fee</td>
                    <td aria-label="Rate % / Fee $" className="text-start">{format2USAmount(Retrieval_Fee__c)}</td>
                    <td aria-label="Assessed" className="text-end">Per Retrieval</td>
                </tr>}
                {Pricing_Structure__c === 'Cost Plus' && <tr>
                    <td aria-label="Description" className="text-start d-flex flex-row">
                        Commercial Card Interchange Service
                        <OverlayInfoComponent
                            popoverText="For CCIS, when transactions do not include any tax information we will compute the sales tax based on the applicable rate at your location to allow you to obtain the best interchange. When we compute the sales tax on your behalf, we will retain 50% of the interchange savings. If a transaction is fully or partially exempt, you should enter the tax amount (even if the amount is $0.00) as CCIS applies to your local tax rate to the full amount of transactions when the prompt is bypassed."
                            placement="auto"
                            className="mx-2"
                        >
                            <InfoIcon />
                        </OverlayInfoComponent>
                    </td>
                    <td aria-label="Rate % / Fee $" className="text-start">50.00% of Interchange Savings</td>
                    <td aria-label="Assessed" className="text-end">Gross Qualifying Volume</td>
                </tr>}
                {hasValidAmount(imsFee) && <tr>
                    <td aria-label="Description" className="text-start">Interchange Management Service</td>
                    <td aria-label="Rate % / Fee $" className="text-start">{formatRate(imsFee)} of Interchange Savings</td>
                    <td aria-label="Assessed" className="text-end">Gross Qualifying Volume</td>
                </tr>}
                {hasValidAmount(Early_Termination_Fee__c) && <tr>
                    <td aria-label="Description" className="text-start">Early Termination</td>
                    <td aria-label="Rate % / Fee $" className="text-start">{format2USAmount(Early_Termination_Fee__c)}</td>
                    <td aria-label="Assessed" className="text-end">One Time</td>
                </tr>}
            </tbody>
        </Table>
    </div>
};

export default PricingDetails;
