import TextComponent from "@common/typography/TextComponent";
import { toast } from "react-toastify";

const useToast = () => {
    const showSuccessToast = (message: string) => {
        toast.success(message);
    };
    const showErrorToast = (message: string) => {
        toast.error(message);
    };

    const showRequiredWarningToast = () => {
        toast(
            <div className="warning-toast">
                <TextComponent className="mt-2 mb-3 fw-bold">
                    Missing Required Information
                </TextComponent>
                <TextComponent>
                    All required information must be entered before you can continue.
                    Please review your application and add any missing required information as shown.
                </TextComponent>
            </div>, {
            position: 'bottom-right',
            toastId: 'review-warning-toast',
            style: {
                borderLeft: '10px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#E7A239'
            },
            autoClose: false,
        })
    }

    const hideRequiredWarningToast = () => toast.dismiss('review-warning-toast');

    return {
        hideRequiredWarningToast,
        showSuccessToast,
        showErrorToast,
        showRequiredWarningToast,
    };
};
export default useToast;
