import { formatPhoneNumber } from '@utils/FormUtils';
import { Row, Col } from 'react-bootstrap';
import AddressComponent from './AddressComponent';
import LabelComponent from './LabelComponent';

type OwnerDetailsPage = {
    firstName?: string;
    lastName?: string;
    contactNumber?: string;
    ssn?: string;
    dob?: string;
    email?: string;
    percentOwnership?: number;
    title?: string;
    addr?: JSX.Element | null;
    addrLabel?: string | null;
    addrPrefix?: string;
    showSSN?: boolean;
    fieldPrefix: string;
    showOwnershipPercentage?: boolean;
    photoId?: string;
    isUnsolicited: boolean;
};

const OwnerReviewDetails = ({
    firstName = undefined,
    lastName = undefined,
    contactNumber = undefined,
    ssn = undefined,
    dob = undefined,
    email = undefined,
    title = undefined,
    percentOwnership = undefined,
    addr = null,
    addrLabel = null,
    addrPrefix = '',
    showSSN = true,
    photoId = undefined,
    isUnsolicited = false,
    fieldPrefix,
    showOwnershipPercentage = true,
}: OwnerDetailsPage) => <>
    <Row>
        <Col md={6} data-cy={`${fieldPrefix}FirstName`}>
            <LabelComponent label="First Name" value={firstName} formKey={`${fieldPrefix}FirstName`} />
        </Col>
        <Col md={6} data-cy={`${fieldPrefix}LastName`}>
            <LabelComponent label="Last Name" value={lastName} formKey={`${fieldPrefix}LastName`} />
        </Col>
    </Row>
    <Row>
        <Col md={6} data-cy={`${fieldPrefix}ContactNumber`}>
            <LabelComponent
                label="Contact Number"
                value={contactNumber ? formatPhoneNumber(contactNumber) : ''}
                formKey={`${fieldPrefix}ContactNumber`}
            />
        </Col>
        <Col md={6} data-cy={`${fieldPrefix}Email`}>
            <LabelComponent label="Email Address" value={email} formKey={`${fieldPrefix}Email`} />
        </Col>
    </Row>
    <Row>
        <Col md={6} data-cy={`${fieldPrefix}DOB`}>
            <LabelComponent label="Date of Birth" value={dob} formKey={`${fieldPrefix}DOB`} />
        </Col>
        {showSSN && <Col md={6} data-cy={`${fieldPrefix}SSN`}>
            <LabelComponent label="SSN" value={ssn} formKey={`${fieldPrefix}SSN`} ddMaskText />
        </Col>}
    </Row>
    <Row>
        <Col md={6} data-cy={`${fieldPrefix}Title`}>
            <LabelComponent label="Title" value={title} formKey={`${fieldPrefix}Title`} />
        </Col>
        {showOwnershipPercentage && <Col md={6} data-cy={`${fieldPrefix}PercentOwnership`}>
            <LabelComponent label="Ownership %" value={percentOwnership} formKey={`${fieldPrefix}PercentOwnership`} />
        </Col>}
        {isUnsolicited && <Col md={6} data-cy={`${fieldPrefix}PhotoID`}>
            <LabelComponent label="Photo ID" value={photoId} formKey={`${fieldPrefix}PhotoID`} />
        </Col>}
        {addrLabel && addr && <Col md={6} data-cy={`${fieldPrefix}Address`}>
            <AddressComponent label={addrLabel} value={addr} prefixKey={addrPrefix} />
        </Col>}
    </Row>
</>;

export default OwnerReviewDetails;
