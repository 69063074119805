import styled from 'styled-components';

const SidebarBody = styled.div`
    width: 200px;
    height: 100%;
    background: inherit;
    padding: 10px 0;
    color: inherit;
    overflow: auto;
    top: 0;
`;
const SidebarFooter = styled.div`
    width: 200px;
    background: inherit;
    color: inherit;
    margin-top: auto;
    bottom: 0;
`;

const SidebarContainer = styled.div<{ $show?: boolean; variant?: string; color?: string }>`
    min-width: 200px;
    max-width: 200px;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props?.theme?.[props.variant || 'primary']};
    color: ${(props) => props?.theme?.[props.color || 'white']};
    margin-left: ${(props) => (!props?.$show ? '-200px' : '')};
    transition: all 0.3s;
`;

interface SidebarComponentProps {
    show: boolean;
    variant?: string;
    color?: string;
    body: React.ReactNode;
    footer?: React.ReactNode;
}

const SidebarComponent = ({
    show = true,
    variant = 'primary',
    color = 'white',
    body = null,
    footer = null,
}: SidebarComponentProps) => (
        <SidebarContainer variant={variant} color={color} $show={show}>
            <SidebarBody>{body}</SidebarBody>
            <SidebarFooter>{footer}</SidebarFooter>
        </SidebarContainer>
    );

export default SidebarComponent;
