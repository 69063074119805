import TextComponent from '@common/typography/TextComponent';

const AdditionalTermsOpportunity = () => <TextComponent fontSize="md" className="justify-content-left mb-4">
    <h6>Compliance with Law</h6>

    You agree not to use your merchant account or our services for any transactions that are prohibited by
    law, such as those outlined in the Unlawful Internet Gambling Enforcement Act or as determined by the
    regulations of the Office of Foreign Assets Control (OFAC). Furthermore, you certify that the federal
    taxpayer identification number and the corresponding filing name you have provided are correct and
    complete, under penalty of perjury.<br /><br />

    <h6>Consent to Contact You</h6>

    The Client understands and consents to us, along with our affiliates, third-party subcontractors, and
    agents, reaching out using automated calling technology to the phone number(s) provided in this
    Merchant Processing Application. This may include leaving detailed voicemail messages, if the Client
    is not directly reachable. This consent applies even if the provided number is a mobile or wireless
    number, or if the Client has previously opted out of receiving solicitation calls by being on a Do Not
    Call list or by requesting not to be contacted for marketing purposes. The Client also consents to
    receive commercial electronic mail messages from us, our Affiliates, and our third-party subcontractors
    and agents periodically.<br /><br />

    <h6>Authorization of Financial Transactions and Account Access</h6>

    Merchant authorizes Gravity Payments or Bank, to present Automated Clearing House (ACH) credits, ACH
    debits, wire transfers, or depository transfer checks to and from the account(s) listed under the
    “Banking Details” section of the Merchant Processing Application, and to and from any other account
    that Gravity Payments or Bank is authorized to handle as per the Merchant Processing Agreement. This
    authorization covers transactions related to lease, rental, or purchase agreements for POS terminals
    and accompanying equipment, check guarantee fees, and payments for supplies and materials. This
    authorization remains in effect until all Merchant obligations under the Agreement are fulfilled and
    Gravity Payments receives a written revocation notice from the Merchant.<br /><br />

    <h6>Card Not Present Acceptance</h6>

    In accordance with our terms, card transactions processed via mail, telephone, or internet
    orders—collectively known as “Card Not Present” transactions— should generally not exceed 20% of
    your total card transactions. However, if your Merchant Processing Application specifies alternative
    percentages in the “Processing Details” section and receives approval, you are then authorized to
    process transactions up to those approved percentages.<br /><br />

    <h6>Telecheck (if applicable)</h6>

    This signature page also serves as a signature page to the TeleCheck Solutions Agreement appearing in
    the Third Party Agreements section of the Program Guide, if selected, the undersigned Client being
    “You” and “Your” for the purposes of the TeleCheck Solutions Agreement.<br /><br />

    <h6>Terms for Interchange Management Service (if applicable)</h6>

    You agree that your merchant account will automatically receive the Interchange Management Service (IMS)
    in connection with using the emergepay product. The goal of the IMS is to help you minimize the costs
    associated with certain interchange rates for business, corporate, and commercial card transactions
    (Commercial Transactions).<br/><br/>
    Interchange is the transfer fee charged by Visa and MasterCard to process a card transaction. Visa and
    MasterCard apply different interchange rates for Commercial Transactions based on the level of
    information you provide with each transaction. Providing sales tax amount and a customer code for your
    eligible Commercial Transactions may help you qualify for lower interchange rates than standard rates;
    other qualification factors may also apply. If you do not provide the sales tax amount and/or a customer
    code with your Commercial Transactions, IMS will assign a customer code and/or calculate and transmit
    the sales tax amount for your Commercial Transactions. We do not represents or warrant any specific
    results in connection with use of the IMS.<br/><br/>
    We charge a fee for IMS if you receive the Services through a payment arrangement that includes the pass
    through of all interchange and Payment Network fees. Our fee for IMS is 50% of the savings resulting from
    interchange rate optimization for Commercial Transactions, as applicable. We do not charge a fee for the
    IMS if you receive the Services through a payment arrangement where interchange fees are not passed
    through to you. Please consult your Merchant Processing Application for information on your payment
    arrangement.<br/><br/>
    You may opt out of the IMS at any time by notifying us in writing and your request will be processed in
    accordance with our standard procedures for such changes.  If you elect to opt out of the IMS, and do
    not provide the sales tax amount and/or a customer code with your Commercial Transactions, these
    transactions will be processed pursuant to standard interchange rates, which may result in an increase
    to the overall cost of your acceptance of Visa and MasterCard credit card transactions.<br/><br/>
    IMS does not affect (1) the total amount of any submitted transactions, or (2) your sales tax
    collection or reporting obligations under any applicable tax laws or regulations that apply to you or
    your business.<br/><br/>
    If a transaction is fully or partially tax-exempt, you should include the tax exempt amount in the
    transaction information when prompted (even if that amount is $0.00). If you don’t include the
    tax-exempt amount, IMS will apply your local tax rate to the full amount of the transaction.<br/><br/>
    We may introduce additional features or updates to IMS in the future. Any new features or updates will
    be governed by these terms and conditions, and your continued use of IMS will constitute acceptance of
    the new features and updates and these terms and conditions. Should there be any exceptions or changes
    that would affect your merchant account, we will provide you with advance notice.<br/><br/>
    IMS is currently only available for transactions processed through our emergepay product.<br /><br />

    <h6>Authorizations and Certifications</h6>

    On behalf of myself as an individual, the entity on whose behalf I am signing and its principals
    (collectively, the Client Parties):

    <ol type='A'>
        <li>
            I authorize Processor, Servicers, the applicable Payments Networks, and their Affiliates,
            third-party subcontractors, service providers, and/or agents:
            <ol type='i'>
                <li>
                    to use, disclose, and exchange amongst them and externally with other third-parties, the
                    information in the Merchant Processing Application and information about each of the Client
                    Parties (including by requesting and sharing, personal and business consumer reports, bank
                    references, and other information as necessary from time to time), for marketing and
                    administrative purposes, verification purposes, purposes under the Merchant Processing
                    Application and Agreement, if approved, product improvement, fraud, analytics and any other
                    purposes permitted by law (and to continue to use and share such information following the
                    termination of this Agreement);
                </li>
                <li>
                    to inform me directly about the contents of requested consumer reports (including the name
                    and address of the agency furnishing the report), and
                </li>
                <li>
                    to receive any and all personal and business credit financial information from all
                    references, including banks and consumer reporting agencies, which are hereby released to
                    provide that information; and
                </li>
            </ol>
        </li>
        <li>
            I certify that:
            <ol type='i'>
                <li>
                    The federal taxpayer identification number and corresponding filing name provided herein
                    are correct.
                </li>
                <li>
                    The statements made and agreed to in this Merchant Processing Application and Agreement,
                    to which I have not made any alterations or stricken out any language, are true,
                    complete, and accurate, and may be relied upon as current unless changed or updated per
                    the Notice provisions of Agreement.
                </li>
                <li>
                    I can read and understand the English language.
                </li>
                <li>
                    I have received and read a copy of the:
                    <ol type='a'>
                        <li>Merchant Processing Application</li>
                        <li>Program Guide (version O_WF_W_2611)</li>
                        <li>Confirmation Page</li>
                    </ol>
                </li>
                <li>
                    I have authority to bind the entity on whose behalf I am signing below and have the
                    appropriate consents and authority from each of the Client Parties (whether individuals
                    or other entities) to authorize the use and sharing of data described above.
                </li>
            </ol>
        </li>
    </ol>
</TextComponent>;

export default AdditionalTermsOpportunity;
