import { OverlayTrigger, Popover } from 'react-bootstrap';
import { OverlayTriggerType } from 'react-bootstrap/esm/OverlayTrigger';
import styled from 'styled-components';

interface OverlayInfoComponentProps {
    placement?: 'right' | 'left' | 'top' | 'bottom' | 'auto';
    children: React.ReactNode;
    popoverText: React.ReactNode;
    trigger?: OverlayTriggerType | OverlayTriggerType[];
    variant?: string;
    className?: string;
}

const getPopoverArrowColor = (placement: string, color: string) => `.popover-arrow:after{
        border-${placement}-color: ${color};
    }`;

const PopoverComponent = styled(Popover)<{ variant: string }>`
    ${(props) => getPopoverArrowColor(props?.placement || 'right', props.theme?.[props?.variant])}
`;

const OverlayInfoComponent = ({
    placement = 'auto',
    trigger = 'click',
    variant = 'primary',
    popoverText,
    className = '',
    children,
}: OverlayInfoComponentProps) => (
        <OverlayTrigger
            trigger={trigger}
            key={placement}
            placement={placement}
            rootClose
            overlay={
                <PopoverComponent
                    id={`popover-positioned-${placement}`}
                    variant={variant}
                    placement={placement}
                    className={`p-2 bg-${variant} text-white`}
                >
                    {popoverText}
                </PopoverComponent>
            }
        >
            <span className={className}>{children}</span>
        </OverlayTrigger>
    );

export default OverlayInfoComponent;
