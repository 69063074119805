import { IconProps } from './type';

const Document = ({ fill = '#107AB0', className }: IconProps) => <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M11.4156 3.91563L8.0875 0.587503C7.70937 0.210691 7.20312 3.125e-06 6.67188 3.125e-06L2 0C0.895313 0 0 0.895313 0 2L0.000203125 14C0.000203125 15.1047 0.895516 16 2.0002 16H10C11.1047 16 12 15.1047 12 14V5.32812C12 4.79688 11.7906 4.29063 11.4156 3.91563ZM7 1.065C7.13991 1.11394 7.27081 1.18519 7.37875 1.2931L10.7069 4.62122C10.8156 4.72813 10.8875 4.85938 10.9344 5H7.5C7.225 5 7 4.775 7 4.5V1.065ZM11 14C11 14.5513 10.5512 15 10 15H2C1.44875 15 1 14.5513 1 14V2C1 1.44875 1.44875 1 2 1H6V4.5C6 5.32813 6.67188 6 7.5 6H11V14ZM3 8.5C3 8.775 3.225 9 3.5 9H8.5C8.775 9 9 8.775 9 8.5C9 8.225 8.775 8 8.5 8H3.5C3.225 8 3 8.225 3 8.5ZM8.5 10H3.5C3.225 10 3 10.225 3 10.5C3 10.775 3.225 11 3.5 11H8.5C8.77619 11 9 10.7761 9 10.5C9 10.2239 8.775 10 8.5 10ZM8.5 12H3.5C3.225 12 3 12.225 3 12.5C3 12.775 3.225 13 3.5 13H8.5C8.77619 13 9 12.7761 9 12.5C9 12.2239 8.775 12 8.5 12Z"
        fill={fill}
    />
</svg>;

export default Document;
