import { Badge } from 'react-bootstrap';
import TextComponent from '@common/typography/TextComponent';
import useFormValidationContext from '@hooks/useFormValidationContext';

const capitalizeString = (s?: string) => typeof s === 'string' ? `${s.charAt(0).toUpperCase()}${s.slice(1)}` : s;

interface LabelComponentProps {
    capitalize?: boolean;
    label: string;
    formKey?: string;
    value?: any;
    required?: boolean;
    ddMaskText?: boolean;
}
const LabelComponent = ({
    capitalize = false,
    label,
    value = '',
    formKey = undefined,
    required = true,
    ddMaskText = false,
}: LabelComponentProps) => {
    const { errors } = useFormValidationContext();
    const textComponentProps = ddMaskText ? { 'data-dd-privacy': 'mask' } : {};
    const error = errors.find(({ path }) => path === formKey)?.message;

    const getBody = () => {
        if (error && value) {
            return <Badge
                bg="danger"
                pill
                as="div"
                className="text-white p-2 text-truncate"
                data-cy="errorInformationBadge"
            >
                {error}
            </Badge>
        }
        if (!value) {
            return <Badge
                hidden={!required}
                bg="warning"
                pill
                className="text-orange p-2"
                data-cy="missingInformationBadge"
            >
                Missing Information
            </Badge>
        }

        return <TextComponent fontWeight="400" fontSize="md" {...textComponentProps}>
            {capitalize ? capitalizeString(value): value}
        </TextComponent>
    };

    return <div>
        <TextComponent fontWeight="600" className="mt-4 mb-1">{label}</TextComponent>
        {getBody()}
    </div>
};

export default LabelComponent;
