import { createContext, useContext, useEffect, useState, useMemo } from 'react';
import type { AnySchema, ValidationError } from 'yup';
import useWizardContext from './wizard/useWizardContext';

type Context = {
    errors: ValidationError[];
}

const FormValidationContext = createContext<Context>({
    errors: [],
});

export const FormValidationContextProvider = ({ children = null, schema }: { children?: React.ReactNode, schema: AnySchema }) => {
    const { form: { getValues } } = useWizardContext();
    const [errors, setErrors] = useState<ValidationError[]>([]);

    useEffect(() => {
        const formData = getValues();
        if (formData) {
            try {
                schema.validateSync(formData, { abortEarly: false });
            } catch (e) {
                setErrors(e?.inner);
            }
        }
    }, [getValues, schema]);

    const value = useMemo(() => ({ errors }), [errors]);

    return <FormValidationContext.Provider value={value}>{children}</FormValidationContext.Provider>;
}

const useFormValidationContext = () => {
    const context = useContext(FormValidationContext);
    if (!context) {
        throw new Error(`useFormValidationContext hook can be used only inside Opportunity paths`);
    }
    return context;
};
export default useFormValidationContext;
