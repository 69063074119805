import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormats from 'dayjs/plugin/advancedFormat';
import TextComponent from '@common/typography/TextComponent';
import InformationContainer from '@common/container/InformationContainer';
import { Row, Col, Navbar, Nav, NavItem } from 'react-bootstrap';
import useWizardContext from '@hooks/wizard/useWizardContext';
import useAutoSave from '@hooks/useAutoSave';
import useOpportunityContext from "@hooks/useOpportunityContext";
import { SignFields } from '@hooks/wizard/types';
import { Button } from '@common';
import FormError from '@components/form/FormErrorComponent';
import Products from './equipment-page-components/Products';
import TermsAndConditionsOpportunity from './sign-page-components/TermsAndConditionsOpportunity';
import TermsAndConditionsPFAC from './sign-page-components/TermsAndConditionsPFAC';
import AdditionalTermsOpportunity from './sign-page-components/AdditionalTermsOpportunity';
import AdditionalTermsPFAC from './sign-page-components/AdditionalTermsPFAC';
import PCIComplianceAgreement from './sign-page-components/PCIComplianceAgreement';
import EquipmentRentalContract from './sign-page-components/EquipmentRentalContract';
import SurchargeAddendum from './sign-page-components/SurchargeAddendum';
import PricingDetails from './sign-page-components/PricingDetails';

dayjs.extend(advancedFormats);
dayjs.extend(utc)
dayjs.extend(timezone)

const enum Tabs {
    'TermsAndConditions',
    'AdditionalTerms',
    'PCIComplianceAgreement',
    'EquipmentRentalContract',
    'SurchargeAddendum',
    'Poppy',
};

export type Values = {
    formattedTimestamp: string,
    ipAddress: string,
    keyedInSignature: string,
    signerName: string,
    timestamp: string,
    debitCardBilling?: string;
};

function formatSignatureDate(date: Date) {
    return new Intl.DateTimeFormat([], {
        dateStyle: 'full',
        timeStyle: 'long',
        hourCycle: 'h23'
    }).format(date).replace('at ', '');
}

const SignPage = () => {
    const {
        form: { clearErrors, formState: { errors }, register, setValue, watch, getValues },
        isOpportunity
    } = useWizardContext();
    const { isSigner, lineItems, pci, quote } = useOpportunityContext();
    const { Pricing_Structure__c } = quote || {};
    const signature = watch('keyedInSignature') || '';
    const [accepted, setAccepted] = useState(signature && signature !== '');
    const [tab, setTab] = useState(0);
    const { people, waivePersonalGuarantee } = getValues();
    const hasEquipmentRental = lineItems?.some(item => item.Ownership__c === 'Rental');
    const hasPoppy = lineItems?.some(item => item.Product2Id === '01t4v000007TxGSAA0');

    const handleAccepted = () => {
        clearErrors();
        if (!accepted) {
            setAccepted(!accepted)
            const now = new Date();
            setValue('timestamp', now.toISOString());
            setValue('ipAddress', window.sessionStorage.getItem('ip') || '');
            if (!signature && (people[0].FirstName && people[0].LastName)) {
                const signerName = `${people[0].FirstName} ${people[0].LastName}`
                const formattedSignatureTime = formatSignatureDate(now);
                setValue('signerName', signerName)
                setValue('formattedTimestamp', formattedSignatureTime);
                setValue('keyedInSignature', `${signerName}${' '.repeat(5)}${formattedSignatureTime}`);
            }
        } else {
            setAccepted(!accepted)
            setValue('signerName', '');
            setValue('timestamp', '');
            setValue('ipAddress', '');
            setValue('keyedInSignature', '');
            setValue('formattedTimestamp', '');
        }
    }

    useEffect(()=>{
        const navInfoContainer = document.getElementById("info-container");
        navInfoContainer?.scrollTo({
            top: 0,
            behavior: "smooth",
        })

    },[tab])


    return <div className="p-3">
        <Row>
            <div className="d-flex justify-content-center mt-4">
                <TextComponent
                    fontSize="md"
                    fontWeight="400"
                    color="charcoal"
                    className="text-center mb-5"
                >
                    Your application is ready to be submitted! Please review the pricing information and terms and conditions below. Then sign the application:
                </TextComponent>
            </div>
            {isOpportunity && <PricingDetails />}
            {(isOpportunity && lineItems?.length > 0) && <div className="mt-4">
                <Products lineItems={lineItems} />
            </div>}
            <div className="mt-4">
                <h5>Terms & Conditions</h5>
                {waivePersonalGuarantee === 'no' && <p className='text-muted'>
                    <b>Personal Guarantee</b>: In exchange for Gravity Payments, Wells Fargo Bank, N.A. (the Guaranteed Parties) acceptance of, as applicable, the Agreement, the undersigned (Guarantor): (A) Unconditionally and irrevocably guarantees the full payment and performance of Client’s obligations, (i) as they now exist or as modified under the foregoing agreements, (ii) with or without actual notice of changes, and (iii) during and after the term of the agreements; (B) Waives notice of Merchant&apos;s default; (C) Shall indemnify the Guaranteed Parties for any and all amounts due from Client; (D) Warrants, with knowledge that Guaranteed Parties are acting in full reliance on the same, this Personal Guarantee of payment, and not of collection; (E) Acknowledges that (i) the Guaranteed Parties may proceed in law directly against Guarantor and not Client, (ii) this is a continuing personal guarantee and shall not be discharged or affected for any reason, and (iii) information about the Guarantor as one of the Client Parties may be used and shared as set forth in Section 9 of the Program Guide.
                </p>}
                <InformationContainer className="p-0 overflow-hidden mt-4" height="auto">
                    {isOpportunity && <>
                        <Navbar sticky="top" className='py-0'>
                            <Nav className="mr-2 nav-items">
                                <NavItem
                                    className={`cursor-pointer py-2 px-3 ${tab === Tabs.TermsAndConditions ? 'active' : ''}`}
                                    onClick={() => setTab(Tabs.TermsAndConditions)}
                                >
                                    <TextComponent color="secondary" as="span" fontSize="md" className='nav-text'>
                                        T&Cs
                                    </TextComponent>
                                </NavItem>
                                <NavItem
                                    className={`cursor-pointer py-2 px-3 ${tab === Tabs.AdditionalTerms ? 'active' : ''}`}
                                    onClick={() => setTab(Tabs.AdditionalTerms)}
                                >
                                    <TextComponent color="secondary" as="span" fontSize="md" className='nav-text'>
                                        Additional Terms
                                    </TextComponent>
                                </NavItem>
                                {pci.required && <NavItem
                                    className={`cursor-pointer py-2 px-3 ${tab === Tabs.PCIComplianceAgreement ? 'active' : ''}`}
                                    onClick={() => setTab(Tabs.PCIComplianceAgreement)}
                                >
                                    <TextComponent color="secondary" as="span" fontSize="md" className='nav-text'>
                                        PCI Compliance AGT
                                    </TextComponent>
                                </NavItem>}
                                {hasEquipmentRental && <NavItem
                                    className={`cursor-pointer py-2 px-3 ${tab === Tabs.EquipmentRentalContract ? 'active' : ''}`}
                                    onClick={() => setTab(Tabs.EquipmentRentalContract)}
                                >
                                    <TextComponent color="secondary" as="span" fontSize="md" className='nav-text'>
                                        Equipment Rental CNT
                                    </TextComponent>
                                </NavItem>}
                                {Pricing_Structure__c === 'Surcharge' && <NavItem
                                    className={`cursor-pointer py-2 px-3 ${tab === Tabs.SurchargeAddendum ? 'active' : ''}`}
                                    onClick={() => setTab(Tabs.SurchargeAddendum)}
                                >
                                    <TextComponent color="secondary" as="span" fontSize="md" className='nav-text'>
                                        Surcharge Addendum
                                    </TextComponent>
                                </NavItem>}
                                {hasPoppy && <NavItem
                                    className={`cursor-pointer py-2 px-3 ${tab === Tabs.Poppy ? 'active' : ''}`}
                                    onClick={() => setTab(Tabs.Poppy)}
                                >
                                    <TextComponent color="secondary" as="span" fontSize="md" className='nav-text'>
                                        Poppy
                                    </TextComponent>
                                </NavItem>}
                            </Nav>
                        </Navbar>
                        <hr className="mt-0" />
                    </>}
                    <div className="overflow-auto" style={{height: '260px', paddingLeft: '20px', paddingRight: '20px'}} id='info-container'>
                        {tab === Tabs.TermsAndConditions && (isOpportunity ? <TermsAndConditionsOpportunity /> : <TermsAndConditionsPFAC />)}
                        {tab === Tabs.AdditionalTerms && (isOpportunity ? <AdditionalTermsOpportunity /> : <AdditionalTermsPFAC />)}
                        {tab === Tabs.PCIComplianceAgreement && <PCIComplianceAgreement />}
                        {tab === Tabs.EquipmentRentalContract && <EquipmentRentalContract />}
                        {tab === Tabs.SurchargeAddendum && <SurchargeAddendum />}
                        {tab === Tabs.Poppy && <>
                            <TextComponent fontSize="lg" fontWeight="700" color="charcoal">
                                Poppy Addendum
                            </TextComponent>
                            <TextComponent fontSize="md" className="justify-content-left mb-4">
                                This Addendum is governed by the Poppy Terms & Conditions located at <a href='https://poppy-bridal.com/terms/' rel='noreferrer' target='_blank'>https://poppy-bridal.com/terms/</a> (&quot;Terms&quot;), which may be modified from time to time. Any capitalized terms used in this Addendum and not defined have the meanings given to them in the Terms. All other terms as indicated in the Gravity Payments Merchant Processing Application or corresponding Program Guide will remain unchanged and in full force and effect.
                            </TextComponent>
                        </>}
                    </div>
                </InformationContainer>
            </div>
            {isSigner && <>
                <div className='mt-4'>
                    <TextComponent fontWeight='600' color='pewter' className='mt-4 text-center'>
                        By clicking the Agree & Sign button below, you agree to all terms as they have been presented here. You confirm that all information set forth in this completed Merchant Processing Application is true and correct. You have also reviewed the Program
                        Guide and Confirmation Page, which describe the terms under which we will provide Services to you. The Program Guide, Confirmation Page, and Merchant Processing Application comprise the Agreement. You acknowledge that you are entering a binding agreement, and acknowledge that the Program Guide contains a
                        mandatory arbitration provision which waives your right to a jury trial in a suit to enforce the contract, and a class action waiver that waives your right to participate in a class action or similar proceeding to enforce the contract.
                    </TextComponent>
                </div>
                <Col data-cy='accept' className='mt-4 w-100 d-flex align-items-center flex-column mt-4'>
                    <Button onClick={handleAccepted} variant={accepted ? 'pewter' : 'secondary'} className="px-5">
                        Agree & Sign
                    </Button>
                </Col>
                <InformationContainer className="pb-1 d-flex flex-column justify-content-between mt-4 align-items-center text-center" height="147px">
                    <Col md={6} data-cy='keyedInSignature' className='w-100 d-flex justify-content-between flex-column mt-5'>
                        <TextComponent fontSize='lg'  className="border-bottom text-silver text-center text-bottom rounded-0 w-100">
                            <div className='d-flex justify-content-around flex-wrap '>
                                <div className='pe-3'>{getValues('signerName')}</div>
                                <div>{getValues('formattedTimestamp')}</div>
                            </div>
                        </TextComponent>
                    </Col>
                    <Col md={6} className='d-flex flex-column justify-content-center align-items-center'>
                        {errors?.keyedInSignature?.message && <FormError errorMsg={errors.keyedInSignature.message} />}
                        <input type='hidden' {...register('timestamp')} />
                        {errors?.timestamp?.message && <FormError errorMsg={errors.timestamp.message} />}
                        <input type='hidden' {...register('ipAddress')} />
                        {errors?.ipAddress?.message && <FormError errorMsg={errors.ipAddress.message} />}
                    </Col>
                </InformationContainer></>
            }
        </Row>
    </div>;
};

export default SignPage;
