import TextComponent from '@common/typography/TextComponent';
import useOpportunityContext from '@hooks/useOpportunityContext';
import { Row, Col } from 'react-bootstrap';
import LabelComponent from './LabelComponent';


const AdditionalProcessingFields = () => {
    const {
        additionalProcessingFields: {
            avgMotoPercentage = 0,
            avgSwipedPercentage = 0,
            avgManualPercentage = 0,
            avgInternetPercentage = 0,
            annualSalesVolume = 0,
            goodsAndService = '',
            isCurrentlyAcceptingCards = '',
            currentProcessor = '',
            annualVisaSalesVolume = 0,
            annualDiscoverVolume = 0,
            annualAmexVolume = 0,
            annualAmexOptBlueVolume = 0,
            avgTicket = 0,
            sic = '',
            reasonForLeave = '',
            isSeasonal = false,
        },
    } = useOpportunityContext();

    return <>
        <Row className='mt-2'>
            <TextComponent fontWeight="600" className="mb-2">Card Volume Sales Profile</TextComponent>
            <Col>
                <div data-cy="swiped">Card Swipe % : {`${avgSwipedPercentage || 0}%`}</div>
                <div data-cy="keyed">Manual Key Entry (Card Present) % : {`${avgManualPercentage || 0}%`}</div>
            </Col>
            <Col>
                <div data-cy="moto">Mail Order / Telephone % : {`${avgMotoPercentage || 0}%`}</div>
                <div data-cy="internet">Internet % : {`${avgInternetPercentage || 0}%`}</div>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <LabelComponent
                    label="Gross Yearly Sales Volume"
                    value={`$${annualSalesVolume || "0"}`}
                    required={false}
                />
            </Col>
            <Col md={6}>
                <LabelComponent
                    label="Types of Good or Services"
                    value={goodsAndService}
                    required={false}
                />
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <LabelComponent
                    capitalize
                    label="Do you currently accept credit cards?"
                    value={isCurrentlyAcceptingCards}
                    required={false}
                />
            </Col>
            <Col md={6}>
                <LabelComponent
                    label="Current Credit Card Processor"
                    value={currentProcessor}
                    required={false}
                />
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <LabelComponent
                    label="Reason for Leaving"
                    value={reasonForLeave}
                    required={false}
                />
            </Col>
        </Row>
        <Row className='mt-2'>
            <TextComponent fontWeight="600" className="mb-2">Avg. Yearly Volume</TextComponent>
            <Col>
                <div data-cy="swiped">Avg. MasterCard/Visa : {`$${annualVisaSalesVolume || 0}`}</div>
                <div data-cy="keyed">Avg. Disc Network/PayPal : {`$${annualDiscoverVolume || 0}`}</div>
                <div data-cy="moto">Avg. American Express : {`$${annualAmexVolume || 0}`}</div>
            </Col>
            <Col>
                <div data-cy="internet">Avg. American Express OptBlue ticket : {`$${annualAmexOptBlueVolume || 0}`}</div>
                <div data-cy="internet">Avg. Ticket Size : {`$${avgTicket || 0}`}</div>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <LabelComponent
                    label="Seasonal"
                    value={isSeasonal ? "Yes" : 'No'}
                    required={false}
                />
            </Col>
            <Col md={6}>
                <LabelComponent
                    label="SIC / MCC"
                    value={sic}
                    required={false}
                />
            </Col>
        </Row>
    </>;
};

export default AdditionalProcessingFields;
