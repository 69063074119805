import styled from 'styled-components';
import TextComponent from '@common/typography/TextComponent';
import useOpportunityContext from '@hooks/useOpportunityContext';
import useWizardContext from '@hooks/wizard/useWizardContext';
import { BusinessFields } from '@hooks/wizard/types';

const Bold = styled.span`
  font-weight: 600;
`;

const formatAddress = (add1 = '', add2 = '', city = '', state = '', zipcode = '') => {
    if (!add1 || !city || !state || !zipcode) {
        return null;
    }

    return (
        `${add1}, ${add2 ? `${add2}, ` : ''} ${city}, ${state} ${zipcode}`
    );
};

const EquipmentRentalContract = () => {
    const { form: { getValues } } = useWizardContext();
    const { mid } = useOpportunityContext();
    const values = getValues();

    return <>
        <TextComponent
            fontSize="lg"
            fontWeight="700"
            color="charcoal"
            className="d-flex justify-content-left mb-4"
        >
            Equipment Rental Contract
        </TextComponent>
        <TextComponent
            fontSize="md"
            className="justify-content-left mb-4"
            data-cy="equipment-rental-contract"
        >
            <Bold>Merchant DBA: </Bold> {values?.dbaName}
            <br />
            <Bold>Merchant ID: </Bold> {mid}
            <br />
            <Bold>Merchant Address:</Bold> {formatAddress(values?.[BusinessFields.B_ADD1], values?.[BusinessFields.B_ADD2], values?.[BusinessFields.B_CITY], values?.[BusinessFields.B_STATE]?.value, values?.[BusinessFields.B_ZIPCODE])}
            <br />
            <br />
            This Equipment Rental Contract is between <Bold>Gravity Payments</Bold> and the merchant listed above and states the agreement of the parties as follows:
            <br />
            <br />
            <Bold>RENTAL EQUIPMENT SUBJECT TO THIS CONTRACT.</Bold> Gravity Payments shall rent the equipment listed above with the Ownership of Rental.
            <br />
            <br />
            <Bold>PAYMENT TERMS.</Bold> The rental payments shall be due whether or not the Merchant has received notice of a payment. The payment terms of the rental are defined below.
            <br />
            <br />
            <Bold>PAYMENT METHOD.</Bold> By signing this agreement, the Merchant hereby authorizes Gravity Payments, in accordance with this agreement, to initiate
            debit/credit entries to Merchant’s deposit account, as indicated in the “Merchant Credit Card Processing Agreement”. This authority is to remain in
            full force and effect until (a) Gravity
            Payments has received the rental equipment from the Merchant in such a manner as to afford Gravity Payments reasonable opportunity to act on it
            and (b) all obligations of the Merchant to Gravity Payments that have arisen under this Agreement have been paid in full.
            <br />
            <br />
            <Bold>NON-SUFFICIENT FUNDS.</Bold> The Merchant shall be charged $25.00 for each ACH that is returned to Gravity Payments for lack of sufficient funds.
            <br />
            <br />
            <Bold>RISK OF LOSS OR DAMAGE.</Bold> The Merchant assumes all risks of loss or damage to the equipment from any cause and agrees to return it
            to Gravity Payments in the condition received from Gravity Payments, with the exception of normal wear and tear. The merchant agrees
            that they are responsible for shipping rental devices with adequate packing materials to ensure the safety of the devices during transit.
            <br />
            <br />
            <Bold>INSURANCE.</Bold> The Merchant shall insure the equipment for its full replacement value as listed above with the Ownership of Rental.
            <br />
            <br />
            <Bold>RENTAL TERM.</Bold> This Rental shall begin upon receipt of the signed and completed form, and shall terminate on return in fully working
            condition to Gravity Payments’ facility. If the equipment has to be repaired to return it to a fully working state the equipment will continue to
            be rented until it is repaired. The Rental period will end only when the equipment has been returned to a fully working state. At any time
            Gravity Payments and/or the Merchant has the right to terminate the rental with 7 days notice.
            <br />
            <br />
            <Bold>CARE AND OPERATION OF EQUIPMENT.</Bold> The equipment may only be used and operated in a careful and proper manner. Its use must comply with
            all laws, ordinances, and regulations relating to the possession, use, or maintenance of the equipment, including registration and/or licensing
            requirements, if any.
            <br />
            <br />
            <Bold>MAINTENANCE AND REPAIR.</Bold> The Merchant shall maintain the equipment in good repair and operating condition, allowing for reasonable wear and tear.
            <br />
            <br />
            <Bold>LIFETIME EQUIPMENT REPLACEMENT.</Bold> All equipment provided to the Merchant by Gravity Payments is covered under a lifetime replacement
            plan. The merchant simply contacts Gravity Payments, who will confirm that the equipment needs replacing, and will handle the shipping and
            installation of replacement equipment.
            <br />
            <br />
            <Bold>RETURN OF EQUIPMENT.</Bold> At the end of the Rental period, the Merchant shall be obligated to return the equipment to Gravity Payments at
            the Merchant’s expense. Any freight, insurance or customs charges related to the rented equipment incurred during or at the end of the
            rental will be billed to the Merchants account. After the return of the equipment Gravity will inspect the device and assess the state of the
            device. If damage that is outside the manufacturer’s warranty has occurred, the merchant may be responsible for fees incurred to repair or
            replace the terminal up to full replacement value.
            <br />
            <br />
            <Bold>OPTION TO RENEW.</Bold> If the Merchant is not in default upon the expiration of this rental, the Merchant shall have first option to rent the equipment on
            such terms as the parties may agree at that time.
            <br />
            <br />
            <Bold>OWNERSHIP AND STATUS OF EQUIPMENT.</Bold> The equipment will be deemed to be personal property, regardless of the manner in which it may be
            attached to any other property. Gravity Payments shall be deemed to have retained title to the equipment at all times, unless Gravity Payments
            transfers the title by sale. The Merchant shall immediately advise Gravity Payments regarding any notice of any claim, levy, lien, or legal process
            issued against the equipment.
            <br />
            <br />
            <Bold>INDEMNITY OF COMPANY FOR LOSS.</Bold> If the equipment is lost, Gravity Payments shall have the option of requiring the Merchant to replace the
            equipment, which equipment shall become the property of Gravity Payments and subject to this contract.
            <br />
            <br />
            <Bold>DEFAULT.</Bold> The occurrence of any of the following shall constitute default under this Hire:
            <br />
            <ul style={{listStyleType: 'lower-alpha'}}>
                <li>The failure to make a required payment under this Rental when due.</li>
                <li>The violation of any other provision or requirement that is not corrected within 4 days(s) after written notice of the violation is given.</li>
                <li>The insolvency or bankruptcy of the Merchant.</li>
                <li>The subjection of any of Merchant’s property to any levy, seizure, assignment, application or sale for or by any creditor or government agency.</li>
            </ul>
            <Bold>RIGHTS ON DEFAULT.</Bold> If the Merchant is in default under this Rental, without notice to or demand on the Merchant Gravity Payments may take
            possession of the equipment as provided by law, deduct the costs of recovery (including attorney fees and legal costs), repair, and related costs,
            and hold the Merchant responsible for any deficiency. Gravity Payments shall be obligated to re-rent the equipment, or otherwise mitigate the
            damages from the default, only as required by law.
            <br />
            <br />
            <Bold>NOTICE.</Bold> All notices required or permitted under this Rental shall be deemed delivered when delivered in person or by mail, postage prepaid,
            addressed to the appropriate party at the address shown for that party at the beginning of this Rental.
            <br />
            <br />
            <Bold>ASSIGNMENT.</Bold> The Merchant shall not assign or sublet any interest in this Rental or the equipment or permit the equipment to be used by anyone
            other than the Merchant or Merchant’s employees, without Gravity Payments’ prior written consent.
            <br />
            <br />
            <Bold>ENTIRE AGREEMENT AND MODIFICATION.</Bold> This contract constitutes the entire agreement between the parties. No modification or amendment
            of this contract shall be effective unless in writing and signed by both parties. This contract replaces any and all prior agreements between the parties.
            <br />
            <br />
            <Bold>GOVERNING LAW.</Bold> This contract shall be construed in accordance with the laws of the State of Washington.
            <br />
            <br />
            <Bold>SEVERABILITY.</Bold> If any portion of this contract shall be held to be invalid or unenforceable for any reason, the remaining provisions shall continue to
            be valid and enforceable. If a court finds that any provision of this contract is invalid or unenforceable, but that by limiting such provision, it would
            become valid and enforceable, then such provision shall be deemed to be written, construed, and enforced as so limited.
            <br />
            <br />
            <Bold>WAIVER.</Bold> The failure of either party to enforce any provision of this contract shall not be construed as a waiver or limitation of that party’s right to
            subsequently enforce and compel strict compliance with every provision of this contract.
            <br />
            <br />
            <Bold>ACH SCHEDULE.</Bold> The merchant agrees to all applicable monthly debits in the amounts listed above, with the Ownership of Rental, plus all applicable local tax.
        </TextComponent>
    </>;
}

export default EquipmentRentalContract;
