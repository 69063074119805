import TextComponent from '@common/typography/TextComponent';

const PCIComplianceAgreement = () => <>
    <TextComponent
        fontSize="lg"
        fontWeight="700"
        color="charcoal"
        className="d-flex justify-content-left mb-4"
    >
        PCI Compliance Agreement
    </TextComponent>
    <TextComponent fontSize="md" className="justify-content-left mb-4">
        Once your application has been approved for credit card processing, Gravity Payments will notify
        SecureTrust, a partner of Gravity Payments, within 30 days. SecureTrust will provide credentials to
        you for their PCI Portal. Within the portal, you’ll be able to access an annual questionnaire and can
        perform vulnerability scans (if applicable). This portal is provided to assist you in complying with
        the PCI Council compliance guidelines.<br /><br />

        SecureTrust will also provide breach protection coverage. This will cover costs associated with
        forensic audit fees, card replacement costs and fines assessed due to a breach. Breach protection terms
        are as follows:
        <ol>
            <li>$100,000 coverage per MID</li>
            <li>$0 deductible</li>
            <li>$500,000 maximum per year for merchants with multiple MIDs enrolled in breach protection.</li>
        </ol>

        The fees for the services and coverage outlined above include the PCI Annual and PCI Second Location
        Annual fees, as listed on the Pricing Details Table. The PCI Annual fee is assessed for the first
        location and the PCI Second Location Annual fee is assessed for each additional location.<br /><br />

        You will have 90 days from the date your merchant account is opened to complete all necessary steps to
        achieve a compliant status. This includes completing the questionnaire and scan (if applicable). If you
        have not completed the required steps to achieve a compliant status, we may assess a monthly PCI
        Non-Compliance fee for each account until a compliant status is achieved. If applicable, the PCI
        Non-Compliance fee will be assessed at the rate listed on the Pricing Details table.<br /><br />

        Entering this agreement for PCI compliance services does not make you PCI compliant. The completion
        and ongoing renewal of the annual self-assessment questionnaire, completing and obtaining evidence of
        a passing vulnerability scan with PCI Security Standards Council Approved Scanning Vendor (if
        applicable), and obtaining an attestation of compliance in its entirety are all necessary steps towards
        PCI compliance. Only a certified PCI approved vendor, such as SecureTrust, can advise of your PCI
        compliance status. I understand that upon cancellation of my processing account with Gravity Payments,
        my access to SecureTrust’s PCI Portal and related services, along with the breach protection outlined
        above, will be terminated.
    </TextComponent>
</>;

export default PCIComplianceAgreement;
