import { Row, Col } from 'react-bootstrap';
import useWizardContext from '@hooks/wizard/useWizardContext';
import { EquipmentSetUpFields } from '@hooks/wizard/types';
import { formatPhoneNumber, getAddressValue } from '@utils/FormUtils';
import { TextComponent } from '@common/typography/TextComponent';
import useOpportunityContext from '@hooks/useOpportunityContext';
import LabelComponent from './LabelComponent';
import AddressComponent from './AddressComponent';

const EquipmentFieldsReview = () => {
    const { form: { getValues } } = useWizardContext();
    const { lineItems } = useOpportunityContext();
    const values = getValues();
    const isShippingPhysical = values?.[EquipmentSetUpFields.IS_SHIPPING_SAME_AS_PHYSICAL] === 'yes';
    const isEthernetAvailable = values?.[EquipmentSetUpFields.ETHERNET_AVAILABLE] === 'yes';
    const isHandKeyTransactions = values?.[EquipmentSetUpFields.HAND_KEY_TRANSACTIONS] === 'yes';
    const isAcceptingGiftCards = values?.[EquipmentSetUpFields.IS_ACCEPTING_GIFT_CARDS] === 'yes';
    const isTrainingRequired = values?.[EquipmentSetUpFields.REPORTING_TRAINING] === 'yes';

    const hasEmergePay = lineItems?.some(item => item?.Product_Name__c?.toLowerCase().includes('emergepay'));
    const hasEIServerOrCloud = lineItems?.some(item => item?.Product_Name__c?.toLowerCase().includes('eiserver') || item?.Product_Name__c?.toLowerCase().includes('cloud services'));

    return <>
        <Row className='mt-2'>
            <Col data-cy={EquipmentSetUpFields.IS_SHIPPING_SAME_AS_PHYSICAL}>
                <LabelComponent
                    capitalize
                    label='Send to DBA Physical Address'
                    formKey={EquipmentSetUpFields.IS_SHIPPING_SAME_AS_PHYSICAL}
                    value={values?.[EquipmentSetUpFields.IS_SHIPPING_SAME_AS_PHYSICAL]}
                />
            </Col>
        </Row>
        {!isShippingPhysical ? <Row>
            <Col data-cy="shipping-address">
                <AddressComponent
                    label='Shipping Address'
                    prefixKey='shipping'
                    value={getAddressValue(
                        values?.[EquipmentSetUpFields.SHIPPING_ADD1],
                        values?.[EquipmentSetUpFields.SHIPPING_ADD2],
                        values?.[EquipmentSetUpFields.SHIPPING_CITY],
                        values?.[EquipmentSetUpFields.SHIPPING_STATE]?.label,
                        values?.[EquipmentSetUpFields.SHIPPING_ZIPCODE]
                    )}
                />
                <div data-cy={EquipmentSetUpFields.SHIPPING_PHONE}>
                    <LabelComponent
                        capitalize
                        label='Shipping Phone Number'
                        formKey={EquipmentSetUpFields.SHIPPING_PHONE}
                        value={values?.[EquipmentSetUpFields.SHIPPING_PHONE]
                            ? formatPhoneNumber(values?.[EquipmentSetUpFields.SHIPPING_PHONE] || "")
                            : values?.[EquipmentSetUpFields.SHIPPING_PHONE]}
                    />
                </div>
            </Col>
        </Row> : null}
        <TextComponent fontWeight="700" fontSize='xl' className='mt-4 mb-2'>Install Request Notes</TextComponent>
        <TextComponent fontWeight="700" fontSize='sm'>Install / Training Point of Contact</TextComponent>
        <Row className='mt-2'>
            <Col data-cy={EquipmentSetUpFields.INSTALL_CONTACT_FIRST_NAME}>
                <LabelComponent
                    label='First Name'
                    formKey={EquipmentSetUpFields.INSTALL_CONTACT_FIRST_NAME}
                    value={values?.[EquipmentSetUpFields.INSTALL_CONTACT_FIRST_NAME]}
                />
            </Col>
            <Col data-cy={EquipmentSetUpFields.INSTALL_CONTACT_LAST_NAME}>
                <LabelComponent
                    label='Last Name'
                    formKey={EquipmentSetUpFields.INSTALL_CONTACT_LAST_NAME}
                    value={values?.[EquipmentSetUpFields.INSTALL_CONTACT_LAST_NAME]}
                />
            </Col>
        </Row>
        <Row className='mt-2'>
            <Col data-cy={EquipmentSetUpFields.INSTALL_CONTACT_PHONE}>
                <LabelComponent
                    label='Phone Number'
                    formKey={EquipmentSetUpFields.INSTALL_CONTACT_PHONE}
                    value={values?.[EquipmentSetUpFields.INSTALL_CONTACT_PHONE]
                        ? formatPhoneNumber(values?.[EquipmentSetUpFields.INSTALL_CONTACT_PHONE] || "")
                        : values?.[EquipmentSetUpFields.INSTALL_CONTACT_PHONE]}
                />
            </Col>
            <Col data-cy={EquipmentSetUpFields.INSTALL_CONTACT_EMAIL}>
                <LabelComponent
                    label='Email Address'
                    formKey={EquipmentSetUpFields.INSTALL_CONTACT_EMAIL}
                    value={values?.[EquipmentSetUpFields.INSTALL_CONTACT_EMAIL]}
                />
            </Col>
        </Row>
        {(hasEmergePay || hasEIServerOrCloud) && <Row className='mt-2'>
            <Col data-cy={EquipmentSetUpFields.UTILIZE_TIPS}>
                <LabelComponent
                    capitalize
                    label='Will you be utilizing tips?'
                    required
                    formKey={EquipmentSetUpFields.UTILIZE_TIPS}
                    value={values?.[EquipmentSetUpFields.UTILIZE_TIPS]}
                />
            </Col>
            {hasEIServerOrCloud && <Col data-cy={EquipmentSetUpFields.UTILIZE_CARD_STORAGE}>
                <LabelComponent
                    capitalize
                    required
                    label='Do you plan to utilize card storage?'
                    formKey={EquipmentSetUpFields.UTILIZE_CARD_STORAGE}
                    value={values?.[EquipmentSetUpFields.UTILIZE_CARD_STORAGE]}
                />
            </Col>}
        </Row>}
        {hasEIServerOrCloud && <>
            <Row className='mt-2'>
                <Col data-cy={EquipmentSetUpFields.WINDOWS_TEN_OR_NEWER}>
                    <LabelComponent
                        capitalize
                        required
                        label='Can you confirm that your computer is currently running Windows 10 or newer?'
                        formKey={EquipmentSetUpFields.WINDOWS_TEN_OR_NEWER}
                        value={values?.[EquipmentSetUpFields.WINDOWS_TEN_OR_NEWER]}
                    />
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col data-cy={EquipmentSetUpFields.ETHERNET_AVAILABLE}>
                    <LabelComponent
                        capitalize
                        required
                        label='Is there a wired internet connection (ethernet) available?'
                        formKey={EquipmentSetUpFields.ETHERNET_AVAILABLE}
                        value={values?.[EquipmentSetUpFields.ETHERNET_AVAILABLE]}
                    />
                </Col>
                <Col>
                    {isEthernetAvailable ? <div data-cy={EquipmentSetUpFields.FEET_CC_MACHINE_TO_ROUTER}>
                        <LabelComponent
                            label='Feet from credit card machine to modem/router'
                            formKey={EquipmentSetUpFields.FEET_CC_MACHINE_TO_ROUTER}
                            value={values?.[EquipmentSetUpFields.FEET_CC_MACHINE_TO_ROUTER]}
                        />
                    </div> : <div data-cy={EquipmentSetUpFields.FIVE_PORT_SWITCH_PURCHASE}>
                        <LabelComponent
                            capitalize
                            label='Would you like to purchase a 5-port switch ($21) from Gravity Payments?'
                            formKey={EquipmentSetUpFields.FIVE_PORT_SWITCH_PURCHASE}
                            value={values?.[EquipmentSetUpFields.FIVE_PORT_SWITCH_PURCHASE]}
                        />
                    </div>}
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col data-cy={EquipmentSetUpFields.POS_ACCESS}>
                    <LabelComponent
                        capitalize
                        required
                        label='Do you remote into a server to access the Point of Sale?'
                        formKey={EquipmentSetUpFields.POS_ACCESS}
                        value={values?.[EquipmentSetUpFields.POS_ACCESS]}
                    />
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col data-cy={EquipmentSetUpFields.HAND_KEY_TRANSACTIONS}>
                    <LabelComponent
                        capitalize
                        required
                        label='Would you like the ability to hand-key transactions on any other computers without a physical terminal?'
                        formKey={EquipmentSetUpFields.HAND_KEY_TRANSACTIONS}
                        value={values?.[EquipmentSetUpFields.HAND_KEY_TRANSACTIONS]}
                    />
                </Col>
                <Col>
                    {isHandKeyTransactions ? <div data-cy={EquipmentSetUpFields.NO_OF_COMPUTERS}>
                        <LabelComponent
                            label='How many computers?'
                            formKey={EquipmentSetUpFields.NO_OF_COMPUTERS}
                            value={values?.[EquipmentSetUpFields.NO_OF_COMPUTERS]}
                        />
                    </div> : null}
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col data-cy={EquipmentSetUpFields.IS_ACCEPTING_GIFT_CARDS}>
                    <LabelComponent
                        capitalize
                        required
                        label='Do you currently accept gift cards?'
                        formKey={EquipmentSetUpFields.IS_ACCEPTING_GIFT_CARDS}
                        value={values?.[EquipmentSetUpFields.IS_ACCEPTING_GIFT_CARDS]}
                    />
                </Col>
                <Col>
                    {isAcceptingGiftCards ? <div data-cy={EquipmentSetUpFields.GIFT_CARD_PROVIDER}>
                        <LabelComponent
                            label='Current Gift Card Provider'
                            formKey={EquipmentSetUpFields.GIFT_CARD_PROVIDER}
                            value={values?.[EquipmentSetUpFields.GIFT_CARD_PROVIDER]}
                        />
                    </div> : <div data-cy={EquipmentSetUpFields.IS_INTERESTED_GIFT_CARDS}>
                        <LabelComponent
                            capitalize
                            label='Are you interested in accepting gift cards?'
                            formKey={EquipmentSetUpFields.IS_INTERESTED_GIFT_CARDS}
                            value={values?.[EquipmentSetUpFields.IS_INTERESTED_GIFT_CARDS]}
                        />
                    </div>}
                </Col>
            </Row>
            {isAcceptingGiftCards ? <Row className='mt-2'>
                <Col data-cy={EquipmentSetUpFields.BALANCE_SHEET_PROVIDED}>
                    <LabelComponent
                        capitalize
                        label='Balance Sheet Provided to Gravity?'
                        formKey={EquipmentSetUpFields.BALANCE_SHEET_PROVIDED}
                        value={values?.[EquipmentSetUpFields.BALANCE_SHEET_PROVIDED]}
                    />
                </Col>
                <Col data-cy={EquipmentSetUpFields.BLANK_GIFT_CARD_PROVIDED}>
                    <LabelComponent
                        capitalize
                        label='Blank Gift Card Provided to Gravity?'
                        formKey={EquipmentSetUpFields.BLANK_GIFT_CARD_PROVIDED}
                        value={values?.[EquipmentSetUpFields.BLANK_GIFT_CARD_PROVIDED]}
                    />
                </Col>
            </Row> : null}
            <Row className='mt-2'>
                <Col data-cy={EquipmentSetUpFields.NO_OF_WORK_STATIONS}>
                    <LabelComponent
                        label='How many workstations (terminal and hand-key) will process cards with Gravity Payments?'
                        required
                        formKey={EquipmentSetUpFields.NO_OF_WORK_STATIONS}
                        value={values?.[EquipmentSetUpFields.NO_OF_WORK_STATIONS]}
                    />
                </Col>
            </Row>
        </>}
        {(hasEmergePay || hasEIServerOrCloud) && <>
            <Row className='mt-2'>
                {hasEIServerOrCloud && <Col data-cy={EquipmentSetUpFields.INSTALL_ADMIN_ACCESS}>
                    <LabelComponent
                        capitalize
                        required
                        label='During the install, will somebody have admin access (usernames/passwords) to the workstations?'
                        formKey={EquipmentSetUpFields.INSTALL_ADMIN_ACCESS}
                        value={values?.[EquipmentSetUpFields.INSTALL_ADMIN_ACCESS]}
                    />
                </Col>}
                <Col data-cy={EquipmentSetUpFields.REPORTING_TRAINING}>
                    <LabelComponent
                        capitalize
                        required
                        label='Is there more than one person (or a different person) who will need training on reporting?'
                        formKey={EquipmentSetUpFields.REPORTING_TRAINING}
                        value={values?.[EquipmentSetUpFields.REPORTING_TRAINING]}
                    />
                </Col>
            </Row>
            <Row className='mt-2'>
                {isTrainingRequired && (hasEIServerOrCloud || hasEmergePay) ?
                    <Col data-cy={EquipmentSetUpFields.NO_FOR_REPORTING_TRAINING}>
                        <LabelComponent
                            label='How many people will need training?'
                            formKey={EquipmentSetUpFields.NO_FOR_REPORTING_TRAINING}
                            value={values?.[EquipmentSetUpFields.NO_FOR_REPORTING_TRAINING]}
                        />
                    </Col>
                    : null}
                <Col data-cy={EquipmentSetUpFields.PROCESSING_DATE}>
                    <LabelComponent
                        required
                        label='When do you anticipate processing your first transaction with Gravity Payments?'
                        formKey={EquipmentSetUpFields.PROCESSING_DATE}
                        value={values?.[EquipmentSetUpFields.PROCESSING_DATE]}
                    />
                </Col>
            </Row>
        </>}
    </>;
};
export default EquipmentFieldsReview;
