import { IconProps } from "./type";

const CalendarCheckIcon = ({ fill = "#107AB0", className }: IconProps) => (
    <svg
        width="12"
        height="16"
        viewBox="0 0 12 16"
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.53125 2.125C4.46875 2.375 4.28125 2.5 4.0625 2.5H3.5C3.21875 2.5 3 2.75 3 3V4H6H9V3C9 2.75 8.75 2.5 8.5 2.5H7.9375C7.6875 2.5 7.5 2.375 7.4375 2.125C7.28125 1.5 6.6875 1 6 1C5.28125 1 4.6875 1.5 4.53125 2.125ZM6 0C7 0 7.90625 0.625 8.28125 1.5H8.5C9.125 1.5 9.6875 1.9375 9.90625 2.5H10C11.0938 2.5 12 3.40625 12 4.5V14C12 15.125 11.0938 16 10 16H2C0.875 16 0 15.125 0 14V4.5C0 3.40625 0.875 2.5 2 2.5H2.0625C2.28125 1.9375 2.84375 1.5 3.5 1.5H3.6875C4.09375 0.625 4.96875 0 6 0ZM10 3.5V4C10 4.5625 9.53125 5 9 5H6H3C2.4375 5 2 4.5625 2 4V3.5C1.4375 3.5 1 3.96875 1 4.5V14C1 14.5625 1.4375 15 2 15H10C10.5312 15 11 14.5625 11 14V4.5C11 3.96875 10.5312 3.5 10 3.5ZM6.5 2.5C6.5 2.78125 6.25 3 6 3C5.71875 3 5.5 2.78125 5.5 2.5C5.5 2.25 5.71875 2 6 2C6.25 2 6.5 2.25 6.5 2.5ZM9.34375 7.875L5.34375 11.875C5.15625 12.0625 4.8125 12.0625 4.625 11.875L2.625 9.875C2.4375 9.6875 2.4375 9.34375 2.625 9.15625C2.8125 8.96875 3.15625 8.96875 3.34375 9.15625L5 10.8125L8.625 7.15625C8.8125 6.96875 9.15625 6.96875 9.34375 7.15625C9.53125 7.34375 9.53125 7.6875 9.34375 7.875Z"
            fill="white"
        />
    </svg>
);

export default CalendarCheckIcon;
