import { Row, Col, Form } from 'react-bootstrap';
import { OutlineButton } from '@common';
import TextComponent from '@common/typography/TextComponent';
import EditIcon from '@components/icons/EditIcon';
import useFormValidationContext, { FormValidationContextProvider } from '@hooks/useFormValidationContext';
import { useNavigationContext } from '@hooks/useNavigationContext';
import { BankFields, BusinessFields, ProcessingFields } from '@hooks/wizard/types';
import useWizardContext from '@hooks/wizard/useWizardContext';
import { format2USAmount, formatPhoneNumber, getAddressValue, getFileDetails } from '@utils/FormUtils';
import { applicationValidatorSchema, validatorSchema } from '@utils/validators';
import AddressComponent from './review-page-components/AddressComponent';
import ApplicationProcessPercent from './review-page-components/ApplicationProcessPercent';
import LabelComponent from './review-page-components/LabelComponent';
import ManagingDetailsReview from './review-page-components/ManagingDetailsReviewPage';
import ProcessingPercentageComponent from './review-page-components/PercentageComponent';
import BankDetailsComponent from './review-page-components/BankDetailsComponent';
import AdditionalProcessingFields from './review-page-components/AdditionalProcessingFields';
import EquipmentFieldsReview from './review-page-components/EquipmentsReviewFields';

const ReviewPageTitle = ({ title = undefined, goToIndex }: { title?: string, goToIndex: number }) => {
    const { jumpTo } = useNavigationContext();
    const { setShowBackToReview } = useWizardContext();

    const handleClickEdit = async () => {
        await jumpTo(goToIndex);
        setShowBackToReview(true);
    };

    return <Row className="mt-4 " data-cy={title?.replaceAll(/\s/g, '')}>
        <Col>
            <TextComponent fontWeight="700" fontSize="lg">{title}</TextComponent>
        </Col>
        <Col className="text-end">
            <OutlineButton variant="secondary" onClick={handleClickEdit}>
                <EditIcon className="me-2 " />
                Edit Section
            </OutlineButton>
        </Col>
    </Row>
};

const BusinessDetails = () => {
    const { errors } = useFormValidationContext();
    const { form: { getValues } } = useWizardContext();
    const values = getValues();

    return <>
        <ReviewPageTitle title="Business Details" goToIndex={0} />
        <Row>
            <Col md={6} data-cy="legalName">
                <LabelComponent
                    label="Legal Name"
                    value={values?.[BusinessFields.LEGALNAME]}
                    formKey={BusinessFields.LEGALNAME}
                />
            </Col>
            <Col md={6} data-cy="DBA">
                <LabelComponent
                    label="Doing Business As Name"
                    value={values?.[BusinessFields.DBANAME]}
                    formKey={BusinessFields.DBANAME}
                />
            </Col>
        </Row>
        <Row>
            <Col md={6} data-cy="businessAddress">
                <AddressComponent
                    label="Physical Address"
                    prefixKey="business"
                    value={getAddressValue(
                        values?.[BusinessFields.B_ADD1],
                        values?.[BusinessFields.B_ADD2],
                        values?.[BusinessFields.B_CITY],
                        values?.[BusinessFields.B_STATE]?.label,
                        values?.[BusinessFields.B_ZIPCODE]
                    )}
                />
            </Col>
            {values?.[BusinessFields.IS_LEGAL_SAME_AS_PYSICAL] === 'no' && <Col md={6} data-cy="legalAddress">
                <AddressComponent
                    label="Legal Address"
                    prefixKey="legal"
                    value={getAddressValue(
                        values?.[BusinessFields.L_ADD1],
                        values?.[BusinessFields.L_ADD2],
                        values?.[BusinessFields.L_CITY],
                        values?.[BusinessFields.L_STATE]?.label,
                        values?.[BusinessFields.L_ZIPCODE]
                    )}
                />
            </Col>}
        </Row>
        <Row>
            <Col md={6} data-cy="businessEmail">
                <LabelComponent
                    label="Business Email"
                    value={values?.[BusinessFields.B_EMAIL]}
                    formKey={BusinessFields.B_EMAIL}
                />
            </Col>
            <Col md={6} data-cy="businessPhone">
                <LabelComponent
                    label="Business Phone Number"
                    value={values?.[BusinessFields.B_PHONE]
                        ? formatPhoneNumber(values?.[BusinessFields.B_PHONE])
                        : values?.[BusinessFields.B_PHONE]}
                    formKey={BusinessFields.B_PHONE}
                />
            </Col>
        </Row>
        <Row>
            <Col md={6} data-cy="businessTaxPayMethod">
                <LabelComponent
                    label="Business taxes paid through SSN/TIN or a Federal Tax ID?"
                    value={values?.businessTaxPayMethod}
                    formKey='businessTaxPayMethod'
                />
            </Col>
        </Row>
        <Row>
            {values?.businessTaxPayMethod !== 'SSN' && <Col md={6} data-cy="TIN">
                <LabelComponent
                    label="TIN/EIN"
                    value={values?.tin}
                    ddMaskText
                    formKey='tin'
                />
            </Col>}
            <Col md={6} data-cy="entityType">
                <LabelComponent
                    label="Legal Entity/Ownership Type"
                    value={values?.entityType?.label}
                    formKey='entityType'
                />
            </Col>
        </Row>
        <Row>
            <Col md={6} data-cy="dateOfIncorporation">
                <LabelComponent
                    label="Date Established/Incorporated"
                    value={values?.[BusinessFields.DOE]}
                    formKey={BusinessFields.DOE}
                />
            </Col>
            {((values?.locationCount && values.locationCount > 0) || errors.some(({ path }) => path === 'locationCount')) && <Col md={6} data-cy='numOfLocations'>
                <LabelComponent
                    label="Number of Locations"
                    value={values?.locationCount}
                    formKey='locationCount'
                />
            </Col>}
        </Row>
        <Row>
            {(values.targetDate || errors.some(({ path }) => path === 'targetDate')) && <Col md={6} data-cy="targetDate">
                <LabelComponent
                    label="Target Date to start processing with Gravity"
                    value={values?.targetDate}
                    formKey='targetDate'
                />
            </Col>}
            {(values?.[BusinessFields.B_WEBSITE] || Number(values?.internetPercentage || 0) !== 0) && <Col md={6} data-cy="websiteURL">
                <LabelComponent
                    label="Business Website URL"
                    value={values?.[BusinessFields.B_WEBSITE]}
                    required={Number(values?.internetPercentage || 0) > 0}
                    formKey={BusinessFields.B_WEBSITE}
                />
            </Col>}
        </Row>
    </>;
};

const ExtraFiles = () => {
    const { form: { getValues } } = useWizardContext();
    const values = getValues();
    const files = values?.extraFiles?.filter(file => !!getFileDetails(file));

    return files?.length && files?.length > 0 ? <>
        <Form.Label className="mt-4 fw-bold">Extra Files</Form.Label>
        {files?.map((file, i: number) => <Row key={`Extra File ${i + 1}`}>
            <Col data-cy={`extraFile${i + 1}`}>
                <LabelComponent
                    label={`Extra File ${i + 1}`}
                    value={getFileDetails(file)}
                    formKey={`${BankFields.EXTRA_FILES}.[${i}]`}
                />
            </Col>
        </Row>)}
    </> : null;
}

const OpportunityReviewPage = () => {
    const { form: { getValues }, id } = useWizardContext();

    const values = getValues();

    return <FormValidationContextProvider schema={validatorSchema}>
        <div className='p-3'>
            <BusinessDetails />

            <hr />
            <ReviewPageTitle title='Processing Details' goToIndex={1} />
            <Row>
                <Col md={6} data-cy="daysToReturnCancel">
                    <LabelComponent
                        label="How many days from purchase does a customer have to return and/or cancel their order?"
                        value={values?.[ProcessingFields.DAYS_TO_RETURN_CANCEL]}
                        formKey={ProcessingFields.DAYS_TO_RETURN_CANCEL}
                    />
                </Col>
                <Col md={6} data-cy="deliveryDays">
                    <LabelComponent
                        capitalize
                        label="Are any of your products/service provided to customers one or more days after the credit card transaction is completed?"
                        value={values?.isGSDeliveryAfterTxnDay}
                        formKey={ProcessingFields.IS_GS_DELIVERY_AFTER_TXN_DAY}
                    />
                </Col>
            </Row>
            {values?.[ProcessingFields.IS_GS_DELIVERY_AFTER_TXN_DAY] === 'yes' &&
                <ProcessingPercentageComponent />}
            {values[ProcessingFields.IS_CNP_REQUIRED] === "yes" && <>
                <Row>
                    <Col md={6} data-cy="marketingURL">
                        <LabelComponent
                            label="Marketing Website Address (or link to marketing collateral)"
                            value={values?.[ProcessingFields.MARKETING_WEBSITE]}
                            required={false}
                            formKey={ProcessingFields.MARKETING_WEBSITE}
                        />
                    </Col>
                    <Col md={6} data-cy="authCapturePeriod">
                        <LabelComponent
                            label="How long after credit card authorization is the transaction completed?"
                            value={values?.[ProcessingFields.TXN_COMPLETE_PERIOD]?.label}
                            formKey={ProcessingFields.TXN_COMPLETE_PERIOD}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6} data-cy="monthlyBilling">
                        <LabelComponent
                            capitalize
                            label="Do you perform recurring monthly billing?"
                            value={values?.[ProcessingFields.RECURRING_BILLING]}
                            formKey={ProcessingFields.RECURRING_BILLING}
                        />
                    </Col>
                    {values?.[ProcessingFields.RECURRING_BILLING] === 'yes' && <Col md={6} data-cy="monthlyBillingPeriod">
                        <LabelComponent
                            label="How often does billing occur?"
                            value={values?.[ProcessingFields.BILLING_PERIOD]?.label}
                            formKey={ProcessingFields.BILLING_PERIOD}
                        />
                    </Col>}
                </Row>
                <Row>
                    <Col md={6} data-cy="creditCardCollection">
                        <LabelComponent
                            capitalize
                            label="Does your database collect entire credit card numbers?"
                            value={values?.[ProcessingFields.DB_COLLECTING_CC_NUMBERS]}
                            formKey={ProcessingFields.DB_COLLECTING_CC_NUMBERS}
                        />
                    </Col>
                    <Col md={6} data-cy="signContract">
                        <LabelComponent
                            capitalize
                            label="Does the customer sign a contract specifying terms and conditions?"
                            value={values?.[ProcessingFields.DOES_CUS_SIGN_CONTRACT]}
                            formKey={ProcessingFields.DOES_CUS_SIGN_CONTRACT}
                        />
                    </Col>
                    {values?.[ProcessingFields.DB_COLLECTING_CC_NUMBERS] === 'yes' && <Col md={6} data-cy="pciCompliance">
                        <LabelComponent
                            label="Are you PCI Compliant?"
                            value={values?.[ProcessingFields.PCI_COMPLIANT]?.label}
                            formKey={ProcessingFields.PCI_COMPLIANT}
                        />
                    </Col>}
                    {values?.[ProcessingFields.DOES_CUS_SIGN_CONTRACT] === 'yes' && <Col md={6} data-cy="contractFile">
                        <LabelComponent
                            label="Contract File"
                            value={getFileDetails(values?.[ProcessingFields.CONTRACT_FILE])}
                            formKey={ProcessingFields.CONTRACT_FILE}
                        />
                    </Col>}
                </Row>
                <Row>
                    <Col md={6} data-cy="orderProcess">
                        <LabelComponent
                            label="How does the customer order the product/service?"
                            value={values?.[ProcessingFields.HOW_CUSTOMER_ORDER]}
                            formKey={ProcessingFields.HOW_CUSTOMER_ORDER}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6} data-cy="vendors">
                        <LabelComponent
                            label="List name(s) and address(es) of vendor from which the product is purchased?"
                            value={values?.[ProcessingFields.VENDOR_PUR_ADDRESS]}
                            formKey={ProcessingFields.VENDOR_PUR_ADDRESS}
                        />
                    </Col>
                    <Col md={6} data-cy="productsAndServices">
                        <LabelComponent
                            label="What product(s) or service(s) does your organization provide the cardholder? (Please be specific)"
                            value={values?.[ProcessingFields.SERVICE_FOR_CC_HOLDERS]}
                            formKey={ProcessingFields.SERVICE_FOR_CC_HOLDERS}
                        />
                    </Col>
                </Row>
            </>}
            <AdditionalProcessingFields />

            <hr />
            <ReviewPageTitle title="Managing Details" goToIndex={2} />
            <ManagingDetailsReview />

            <hr />
            <ReviewPageTitle title="Bank Details" goToIndex={3} />
            <BankDetailsComponent />
            <Row>
                <Col md={6} data-cy="primaryAccountDDAType">
                    <LabelComponent
                        label="Account Type"
                        value={values?.primaryAccountDDAType?.label}
                        ddMaskText
                        formKey="primaryAccountDDAType"
                    />
                </Col>
            </Row>
            <Row>
                {values?.[BankFields.P_BANK_ACC_FILE]?.name !== 'plaid_verification.pdf' && <Col data-cy="bankLetterFile">
                    <LabelComponent
                        label="Voided Check or Bank Letter"
                        value={getFileDetails(values?.[BankFields.P_BANK_ACC_FILE])}
                        formKey={BankFields.P_BANK_ACC_FILE}
                    />
                </Col>}
            </Row>
            {values?.[BankFields.USE_ALTERNATIVE_ACCOUNT] === 'yes' && <>
                    <Row>
                        <Col md={6} data-cy={BankFields.ALT_BANK_ROUTING_NUMBER}>
                            <LabelComponent
                                label="Alternative Routing Number"
                                value={values?.[BankFields.ALT_BANK_ROUTING_NUMBER]}
                                ddMaskText
                                formKey={BankFields.ALT_BANK_ROUTING_NUMBER}
                            />
                        </Col>
                        <Col md={6} data-cy={BankFields.ALT_BANK_ACCOUNT_NUMBER}>
                            <LabelComponent
                                label="Alternative Account Number"
                                value={localStorage.getItem(`${id}:altBankValidatedByPlaid`) === 'true' ? values?.[BankFields.MASKED_ALT_BANK_ACCOUNT_NUMBER] : values?.[BankFields.ALT_BANK_ACCOUNT_NUMBER]}
                                ddMaskText
                                formKey={BankFields.ALT_BANK_ACCOUNT_NUMBER}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {values?.[BankFields.ALT_BANK_ACC_FILE]?.name !== 'alt_bank_plaid_verification.pdf' && <Col data-cy={BankFields.ALT_BANK_ACC_FILE}>
                            <LabelComponent
                                label="Alternative Voided Check or Bank Letter"
                                value={getFileDetails(values?.[BankFields.ALT_BANK_ACC_FILE])}
                                formKey={BankFields.ALT_BANK_ACC_FILE}
                            />
                        </Col>}
                    </Row>
                </>}
                <Row>
                    <Col md={6} data-cy={BankFields.WAIVE_PERSONAL_GUARANTEE}>
                        <LabelComponent
                            capitalize
                            label="Request to waive required Personal Guarantee?"
                            value={values?.[BankFields.WAIVE_PERSONAL_GUARANTEE]}
                            ddMaskText
                            formKey={BankFields.WAIVE_PERSONAL_GUARANTEE}
                        />
                    </Col>
                </Row>
                {values?.[BankFields.WAIVE_PERSONAL_GUARANTEE] === 'yes' && <>
                    <Row>
                        <Col md={6} data-cy={BankFields.GUARANTEE_STATEMENT1}>
                            <LabelComponent
                                label="Bank Statement 1"
                                value={getFileDetails(values?.[BankFields.GUARANTEE_STATEMENT1])}
                                formKey={BankFields.GUARANTEE_STATEMENT1}
                            />
                        </Col>
                        <Col md={6} data-cy={BankFields.GUARANTEE_STATEMENT2}>
                            <LabelComponent
                                label="Bank Statement 2"
                                value={getFileDetails(values?.[BankFields.GUARANTEE_STATEMENT2])}
                                formKey={BankFields.GUARANTEE_STATEMENT2}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} data-cy={BankFields.GUARANTEE_STATEMENT3}>
                            <LabelComponent
                                label="Bank Statement 3"
                                value={getFileDetails(values?.[BankFields.GUARANTEE_STATEMENT3])}
                                formKey={BankFields.GUARANTEE_STATEMENT3}
                            />
                        </Col>
                        <Col md={6} data-cy={BankFields.GUARANTEE_B_SHEET}>
                            <LabelComponent
                                label="Current Balance Sheet"
                                value={getFileDetails(values?.[BankFields.GUARANTEE_B_SHEET])}
                                formKey={BankFields.GUARANTEE_B_SHEET}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} data-cy={BankFields.GUARANTEE_PL_STATEMENT}>
                            <LabelComponent
                                label="Year to Date Profit/Loss Statement"
                                value={getFileDetails(values?.[BankFields.GUARANTEE_PL_STATEMENT])}
                                formKey={BankFields.GUARANTEE_PL_STATEMENT}
                            />
                        </Col>
                    </Row>
                </>}
            <ExtraFiles />
            <hr />
            <ReviewPageTitle title="Equipment & Setup" goToIndex={4} />
            <EquipmentFieldsReview />
        </div>
    </FormValidationContextProvider>;
}

const ApplicationReviewPage = () => {
    const { form: { getValues } } = useWizardContext();
    const values = getValues();

    const motoPercent = Number(values?.[ProcessingFields.MOTO_PERCENT] || 0);
    const internetPercent = Number(values?.[ProcessingFields.INTERNET_PERCENT] || 0);
    const showAdditionalFields = motoPercent >= 20 || internetPercent >= 1;

    return <FormValidationContextProvider schema={applicationValidatorSchema}>
        <div className='p-3'>
            <BusinessDetails />

            <hr />
            <ReviewPageTitle title='Processing Details' goToIndex={1} />
            <Row>
                <Col md={6}>
                    <LabelComponent
                        label="Avg. Transaction Size"
                        value={format2USAmount(values?.avgTransactionSize)}
                        formKey='avgTransactionSize'
                    />
                </Col>
                <Col md={6}>
                    <LabelComponent
                        label="Highest Ticket Value"
                        value={format2USAmount(values?.high_ticket_volume)}
                        formKey='high_ticket_volume'
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <LabelComponent
                        capitalize
                        label="Currently accepting credit cards"
                        value={values?.[ProcessingFields.IS_ACCEPTING_CC_CARDS]}
                        formKey={ProcessingFields.IS_ACCEPTING_CC_CARDS}
                    />
                </Col>
                {values?.[ProcessingFields.IS_ACCEPTING_CC_CARDS] === 'yes' && <Col>
                    <LabelComponent
                        label="Expected Monthly Credit Card Volume on this Account"
                        value={format2USAmount(values?.[ProcessingFields.EXPECTED_MONTHLY_CC_VOLUME] || 0)}
                        formKey={ProcessingFields.EXPECTED_MONTHLY_CC_VOLUME}
                    />
                </Col>}
            </Row>
            <Row>
                <Col md={12}>
                    <LabelComponent
                        label="Is Business Seasonal?"
                        value={values?.[ProcessingFields.IS_SEASONAL]}
                        formKey={ProcessingFields.IS_SEASONAL}
                    />
                </Col>
                {values?.[ProcessingFields.IS_SEASONAL] === 'yes' && <>
                    <Col>
                        <LabelComponent
                            label="Seasonal From"
                            value={values?.[ProcessingFields.SEASONAL_FROM]?.label}
                            formKey={ProcessingFields.SEASONAL_FROM}
                        />
                    </Col>
                    <Col>
                        <LabelComponent
                            label="Seasonal To"
                            value={values?.[ProcessingFields.SEASONAL_TO]?.label}
                            formKey={ProcessingFields.SEASONAL_TO}
                        />
                    </Col>
                </>}
            </Row>
            {showAdditionalFields && <Row>
                <Col>
                    <LabelComponent
                        label="What product(s) or service(s) does your organization provide the cardholder?"
                        value={values?.[ProcessingFields.SERVICE_FOR_CC_HOLDERS]}
                        formKey={ProcessingFields.SERVICE_FOR_CC_HOLDERS}
                    />
                </Col>
            </Row>}
            <ApplicationProcessPercent />

            <hr />
            <ReviewPageTitle title="Managing Details" goToIndex={2} />
            <ManagingDetailsReview />

            <hr />
            <ReviewPageTitle title="Bank Details" goToIndex={3} />
            <BankDetailsComponent />
            <Row>
                <Col md={6} data-cy="holderName">
                    <LabelComponent
                        label="Account Holder Name"
                        value={values?.[BankFields.P_BANK_ACCOUNT_FULL_NAME]}
                        ddMaskText
                        formKey={BankFields.P_BANK_ACCOUNT_FULL_NAME}
                    />
                </Col>
                <Col md={6} data-cy="bankName">
                    <LabelComponent
                        label="Bank Name"
                        value={values?.[BankFields.P_ACC_BANK_NAME]}
                        ddMaskText
                        formKey={BankFields.P_ACC_BANK_NAME}
                    />
                </Col>
            </Row>
            <Row>
                {values?.[BankFields.P_BANK_ACC_FILE]?.name !== 'plaid_verification.pdf' && <Col data-cy="bankLetterFile">
                    <LabelComponent
                        label="Voided Check or Bank Letter"
                        value={getFileDetails(values?.[BankFields.P_BANK_ACC_FILE])}
                        formKey={BankFields.P_BANK_ACC_FILE}
                    />
                </Col>}
            </Row>
            {values?.processingStatement1?.name && <Row>
                <Col data-cy="statement1">
                    <LabelComponent
                        label="Processing Statement #1"
                        value={getFileDetails(values?.processingStatement1)}
                        formKey='processingStatement1'
                    />
                </Col>
            </Row>}
            {values?.processingStatement2?.name && <Row>
                <Col data-cy="statement2">
                    <LabelComponent
                        label="Processing Statement #2"
                        value={getFileDetails(values?.processingStatement2)}
                        formKey='processingStatement2'
                    />
                </Col>
            </Row>}
            {values?.processingStatement3?.name && <Row>
                <Col data-cy="statement3">
                    <LabelComponent
                        label="Processing Statement #3"
                        value={getFileDetails(values?.processingStatement3)}
                        formKey='processingStatement3'
                    />
                </Col>
            </Row>}
            <ExtraFiles />
        </div>
    </FormValidationContextProvider>;
}

const ReviewPage = () => {
    const { isApplication } = useWizardContext();
    return isApplication ? <ApplicationReviewPage /> : <OpportunityReviewPage />;
}

export default ReviewPage;
