import TextComponent from "@common/typography/TextComponent";
import FormRadioGroup from "@components/controller-components/FormRadioGroup";
import useOpportunityContext from "@hooks/useOpportunityContext";
import useWizardContext from "@hooks/wizard/useWizardContext";
import { formatRate, format2USAmount } from "@utils/FormUtils";
import { Row, Table } from "react-bootstrap";
import styled from "styled-components";

const DebitCardBillingOptions = [
    {
      label: "Debit Card on Month-End Billing",
      value: "Debit Card on Month-End Billing",
    },
    {
      label: "Debit Card on Daily Billing",
      value: "Debit Card on Daily Billing",
    },
  ]

export type SurchargeAddendumFields = {
    debitCardBilling: typeof DebitCardBillingOptions[number]['value'];
}

const SurchargeStyledContainer = styled.div`
  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }

  ol > li {
    display: table;
    counter-increment: item;
    margin-top: 10px;
  }

  ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
  }

  li ol > li:before {
    content: counters(item, ".") " ";
    display: table-cell;
    min-width: 30px;
  }
`;


const SurchargeAddendum = () => {
  const { form } = useWizardContext();
  const { control } = form;

  const { quote } = useOpportunityContext();
  const {
    Credit_Qual_Discount_Rate__c,
    Debit_Qual_Discount_Rate__c,
    VS_MC_DSC_Transaction_Fee__c,
    Gravity_PIN_Debit_Discount_Rate__c,
    Debit_Transaction_Fee__c,
    Surcharge_Volume_Based_Program_Fee__c,
    Surcharge_Monthly_Program_Fee__c,
    Surcharge_Rate_for_Customer__c
  } = quote;

  return (
    <SurchargeStyledContainer>
      <Row id="surcharge-tc">
        <TextComponent fontSize="lg" fontWeight="700" color="charcoal">
          Surcharge Addendum
        </TextComponent>
        <ol>
          <li>
            What This Addendum Does
            <ol>
              <li>
                This Addendum adds Merchant Surcharge Program to your merchant
                processing agreement. Bank, however, is not a party to the
                Addendum, and the Merchant Surcharge Program (Services) are
                provided to you only by Processor and not by Bank. In the
                Addendum, the words we, our, and us refer only to Processor and
                not to Bank.
              </li>
              <li>
                The terms of your merchant processing agreement and the terms of
                the Addendum both apply to Merchant Surcharge Program and all
                transactions processed in connection with the Services, but if
                anything in the Addendum directly conflicts with your merchant
                processing agreement, the Addendum will control. If your
                merchant processing agreement includes terms and conditions for
                any of the Services, the terms in the Addendum replace them
                entirely while this Addendum is in effect.
              </li>
              <li>
                The Addendum becomes effective as the of the first day of the
                first full month following the date that appears in the
                signature block and continues in effect until (a) your merchant
                processing agreement terminates, in which case, the Addendum
                will automatically terminate, or (b) you or we choose to
                terminate the Addendum by giving the other at least 30 days’
                advance written notice.
              </li>
            </ol>
          </li>
          <li>
            Fees
            <ol>
              <li>
                The Fees listed in the table below will replace your current
                Discount Rate and Transaction Fees for the listed Fees:
                <Table responsive bordered>
                  <thead>
                    <tr>
                      <th className="text-start">{" "}</th>
                      <th className="text-start">Discount Rate</th>
                      <th className="text-start">Transaction Fee</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td aria-label="" className="text-start">
                        Credit Card
                      </td>
                      <td aria-label="Discount Rate" className="text-center">
                        {formatRate(Credit_Qual_Discount_Rate__c)}
                      </td>
                      <td aria-label="Transaction Fee" className="text-center">
                        n/a
                      </td>
                    </tr>
                    <tr>
                      <td aria-label="" className="text-start">
                        Signature Debit Card
                      </td>
                      <td aria-label="Discount Rate" className="text-center">
                        {formatRate(Debit_Qual_Discount_Rate__c)}
                      </td>
                      <td aria-label="Transaction Fee" className="text-start">
                        {format2USAmount(VS_MC_DSC_Transaction_Fee__c)}
                      </td>
                    </tr>
                    <tr>
                      <td aria-label="" className="text-start">
                        Pin-Debit Card
                      </td>
                      <td aria-label="Discount Rate" className="text-center">
                        {formatRate(Gravity_PIN_Debit_Discount_Rate__c)}
                      </td>
                      <td aria-label="Transaction Fee" className="text-start">
                        {format2USAmount(Debit_Transaction_Fee__c)}
                      </td>
                    </tr>
                    <tr>
                      <td aria-label="" className="text-start">
                        Surcharge Program Fee
                      </td>
                      <td aria-label="Discount Rate" className="text-center">
                        {formatRate(Surcharge_Volume_Based_Program_Fee__c)}
                      </td>
                      <td aria-label="Transaction Fee" className="text-start">
                        {format2USAmount(Surcharge_Monthly_Program_Fee__c)} monthly fee
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <TextComponent fontSize="md" fontWeight="700">
                  The Surcharge Rate that you assess on your customers is {formatRate(Surcharge_Rate_for_Customer__c)}.
                </TextComponent>
              </li>
              <li>
                Except as modified, replaced, or added in this Addendum, all
                applicable Fees will remain unchanged
              </li>
              <li>
                Merchant Elects:
                <br />
                <FormRadioGroup
                  name="debitCardBilling"
                  defaultValue="Debit Card on Month-End Billing"
                  control={control}
                  options={DebitCardBillingOptions}
                />
              </li>
            </ol>
          </li>
          <li>
            Definitions
            <ol>
              <li>
                Payments Organization means any payments association or payments
                network whose cards or other payment forms you accept under your
                merchant processing agreement. If “Card Organization” is a
                defined term in your merchant processing agreement, then Payment
                Organization replaces it for purposes of Merchant Surcharge
                Program and all transactions processed in connection with
                Merchant Surcharge Program.
              </li>
              <li>
                Rules means the rules, requirements, and standards of each
                Payments Organization, including the PCI DSS. If “Card
                Organization Rules” is a defined term in your merchant
                processing agreement, then Rules replaces it for purposes of
                Merchant Surcharge Program and all transactions processed in
                connection with Merchant surcharge Program.
              </li>
            </ol>
          </li>
          <li>
            Surcharging
            <ol>
              <li>
                A Surcharge is an additional fee that you add to relevant
                transactions as permitted by the Card Organization Rules and
                applicable laws (together, Applicable Laws). By choosing to
                assess a Surcharge and participate in this Merchant Surcharge
                Program, you agree that you are solely responsible for: (1)
                complying with all Applicable Laws and the Your Payments
                Acceptance Guide. (2) properly and clearly disclosing the
                existence and the amount of any Surcharge to Cardholders in
                accordance with Applicable Laws, and (3) ensuring any Surcharge
                you add to a transaction does not exceed the limit provided in
                the rules.
              </li>
              <li>
                You also agree that: (1) you are assessing a Surcharge on
                Cardholders for certain Credit Card transactions in an amount
                equal to the Surcharge Rate reflected herein; (2) you will pay
                us the Discount Rates for Credit Card, and Signature Debit, PIN
                Debit, and PINless Debit transactions on gross sales for all of
                the transactions you submit (without reduction for refunds,
                returns, or chargebacks); (3) you will pay us the Transaction
                Fee (the fixed charge per transaction reflected above for
                Signature Debit, PIN Debit and PINless Debit transactions) for
                each sale and refund that you submit; (4) you will not assess a
                Surcharge for the portion of the transaction that is tip on
                paper, and you will be responsible to pay us the Discount Rate
                for the gross amount of all tips on paper; (5) you will be
                responsible to refund Cardholders any Surcharge you assess in
                the amount billed on such transaction; (6) you will not assess a
                Surcharge for card not present transactions on cardholders whose
                billing ZIP code corresponds to states or US territories where
                Surcharging is prohibited by Applicable Law (as of April 2023,
                in Connecticut, Maine, Massachusetts, and Oklahoma. Note also
                that merchants located in Colorado may not surcharge more than
                2% as per State law)), and you will be responsible to pay us the
                Discount Rate for such transactions, and (7) we may change or
                cancel this Merchant Surcharge Program upon notice to you.
              </li>
            </ol>
          </li>
          <li>
            Disclaimers
            <ol>
              <li>
                Use of Merchant Surcharge Program does not (a) guarantee
                compliance with any laws, Rules, or applicable standards
                (including the PCI DSS), or (b) affect your obligation to comply
                with laws, Rules, and applicable standards (including the PCI
                DSS).
              </li>
            </ol>
          </li>
          <li>Addendum Approval
            <TextComponent className="my-2">You acknowledge that</TextComponent>
            <ul>
                <li>you have read and understand the Addendum;</li>
                <li>you are choosing to enroll in the Merchant Surcharge Program;</li>
                <li>
                    you agree to comply with this Addendum and any Applicable Laws;
                </li>
                <li>
                    you understand that the terms of the Addendum (including the fees)
                    may be changed from time to time as described in your merchant
                    processing agreement, and
                </li>
                <li>
                    you understand that if you sign the Addendum using an electronic
                    signature process, the resulting signature has the same legal effect
                    as if you had signed it by hand.
                </li>
            </ul>
            </li>
        </ol>
      </Row>
    </SurchargeStyledContainer>
  );
};

export default SurchargeAddendum;
