import { Badge } from 'react-bootstrap';
import useFormValidationContext from '@hooks/useFormValidationContext';
import LabelComponent from './LabelComponent';

type AddressProps = {
    label: string;
    prefixKey: string;
    value: any;
};

const AddressComponent = ({ value, label, prefixKey }: AddressProps) => {
    const { errors } = useFormValidationContext();
    const fields = ['Addr1', 'Addr2', 'City', 'State', 'ZipCode'].map((field) => `${prefixKey}${field}`);

    const errorFields = errors.filter(error =>
        fields.some(field => field === error?.path) &&
        error.value !== '' &&
        error.type !== 'required'
    );

    return <>
        <LabelComponent
            label={label}
            value={errorFields.length === 0 || (!value && errorFields?.length !== 0) ? value : ' '}
        />
        {errorFields.map(error => <Badge
            bg="danger"
            pill
            as="div"
            className="text-white p-2 mt-1 text-truncate"
            data-cy="errorInformationBadge"
        >
            {error.message}
        </Badge>)}
    </>;
};

export default AddressComponent;
