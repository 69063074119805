import TextComponent from "@common/typography/TextComponent";
import { format2USAmount, getProductTransactionFeeClassName } from '@utils/FormUtils';
import { Table } from "react-bootstrap";
import { getOpportunityLineItemsHelperText } from '@utils/helperFunctions';

export type LineItem = {
    Product2Id: string;
    Product_Name__c: string;
    Quantity: number;
    Ownership__c: string;
    UnitPrice: number;
    Monthly_Rental_Cost__c: number;
    Monthly_Fee__c: number;
    Transaction_Fee__c: number;
    TotalPrice: number;
}
const Products = ({ lineItems = [] }: { lineItems: LineItem[] }) => <div>
    <h5>Products</h5>
    <TextComponent color='pewter' className='my-3'>
        Client authorizes FDMS and Bank and their affiliates to debit Client&apos;s designated bank account via Automated Clearing House (ACH) for costs associated with equipment hardware, software and shipping.
    </TextComponent>
    <Table responsive striped >
        <thead>
            <tr>
                <th className="text-start">Product</th>
                <th className="text-end">Quantity</th>
                <th className="text-end">Ownership</th>
                <th className="text-end">Sales Price</th>
                <th className="text-end">Monthly Rental Price Each</th>
                <th className="text-end">Monthly Fee Each</th>
                <th className="text-end">Transaction Fees</th>
                <th className="text-end">Total*</th>
            </tr>
        </thead>
        <tbody>
            {lineItems.map(item => <tr key={item.Product_Name__c}>
                <td aria-label="Product" className="text-start">{item.Product_Name__c}</td>
                <td aria-label="Quantity" className="text-end">{item.Quantity}</td>
                <td aria-label="Ownership" className="text-end">{item.Ownership__c}</td>
                <td aria-label="Sales Price" className="text-end">{item?.Ownership__c === 'Purchase' && item.UnitPrice ? format2USAmount(item.UnitPrice) : '-'}</td>
                <td aria-label="Monthly Rental Price Each" className="text-end">{item?.Ownership__c === 'Rental' && item.Monthly_Rental_Cost__c ? format2USAmount(item.Monthly_Rental_Cost__c) : '-'}</td>
                <td aria-label="Monthly Fee Each" className="text-end">{format2USAmount(item.Monthly_Fee__c) ?? '-'}</td>
                <td aria-label="Transaction Fees" className="text-end"><span className={`${getProductTransactionFeeClassName(item.Product2Id)}`}>{format2USAmount(item.Transaction_Fee__c) ?? '-'}</span></td>
                <td aria-label="Total*" className="text-end">{item?.Ownership__c === 'Purchase' && item.TotalPrice ? format2USAmount(item.TotalPrice) : '-'}</td>
            </tr>)}
        </tbody>
    </Table>
    <TextComponent fontSize='xs' color='pewter' className='mb-1'>
        *Applicable Sales Taxes and Shipping will be applied to the Total. We will debit your bank account on file.
    </TextComponent>
    {getOpportunityLineItemsHelperText(lineItems).map(text => <TextComponent fontSize='xs' color='pewter' className='mb-1'>{text}</TextComponent>)}
</div>;

export default Products;
