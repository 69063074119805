import { Row, Col, Badge } from 'react-bootstrap';
import TextComponent from '@common/typography/TextComponent';
import { ProcessingFields } from '@hooks/wizard/types';
import useFormValidationContext from '@hooks/useFormValidationContext';
import useWizardContext from '@hooks/wizard/useWizardContext';

const ProcessingPercentageComponent = () => {
    const { errors } = useFormValidationContext();
    const { form } = useWizardContext();
    const { getValues } = form;
    const values = getValues();

    const totalPercentageError = errors?.find?.(({ type }) => type === 'is-100-Percent')?.message;

    return <Row className='mt-2'>
        <TextComponent fontWeight="600" className="mb-2">
            The time frame from when payment is collected to when the product/service is provided
        </TextComponent>
        <Col>
            <div data-cy="immediately">
                Immediately : {`${values?.[ProcessingFields.DELIVERY_IMMEDIATE] || 0}%`}
            </div>
            <div data-cy="1to3Days">
                1-3 days : {`${values?.[ProcessingFields.DELIVERY_1_3_DAYS] || 0}%`}
            </div>
            <div data-cy="4to7Days">
                4-7 days : {`${values?.[ProcessingFields.DELIVERY_4_7_DAYS] || 0}%`}
            </div>
        </Col>
        <Col>
            <div data-cy="8to14Days">
                8-14 days : {`${values?.[ProcessingFields.DELIVERY_8_14_DAYS] || 0}%`}
            </div>
            <div data-cy="15to30Days">
                15-30 days : {`${values?.[ProcessingFields.DELIVERY_15_30_DAYS] || 0}%`}
            </div>
            <div data-cy="over30Days">
                Over 30 days : {`${values?.[ProcessingFields.DELIVERY_OVER_30_DAYS] || 0}%`}
            </div>
        </Col>
        {Boolean(totalPercentageError) && <div>
            <Badge
                bg="danger"
                pill
                className="text-white p-2 mt-2 text-truncate"
                data-cy="errorInformationBadge"
            >
                Total Must be 100% and All fields are required.
            </Badge>
        </div>}
    </Row>;
};

export default ProcessingPercentageComponent;
