import type { FC } from 'react';
import useWizardContext from '@hooks/wizard/useWizardContext';
import WizardHeader from '@hooks/wizard/WizardHeader';

const WizardSwitch: FC = () => {
    const { getCurrentStep } = useWizardContext();

    const step = getCurrentStep();
    return WizardHeader({ header: step?.title || '' });
}
export default WizardSwitch;
