import { Row, Col, Badge } from 'react-bootstrap';
import TextComponent from '@common/typography/TextComponent';
import useFormValidationContext from '@hooks/useFormValidationContext';
import { ProcessingFields } from '@hooks/wizard/types';
import useWizardContext from '@hooks/wizard/useWizardContext';

const ApplicationProcessPercent = () => {
    const { errors } = useFormValidationContext();
    const { form } = useWizardContext();
    const { getValues } = form;
    const values = getValues();

    const hasTotalPercentageError = errors.some(({ type }) => type === 'is-100-Percent-total');

    return <Row className='mt-2'>
        <TextComponent fontWeight="600" className="mb-2">
            Processing Payment Percent
        </TextComponent>
        <Col>
            <div data-cy="swiped">
                Swiped : {`${values?.[ProcessingFields.SWIPED_PERCENT] || 0}%`}
            </div>
            <div data-cy="keyed">
                Keyed : {`${values?.[ProcessingFields.KEYED_PERCENT] || 0}%`}
            </div>

        </Col>
        <Col>
            <div data-cy="moto">
                Moto : {`${values?.[ProcessingFields.MOTO_PERCENT] || 0}%`}
            </div>
            <div data-cy="internet">
                Internet : {`${values?.[ProcessingFields.INTERNET_PERCENT] || 0}%`}
            </div>

        </Col>
        {hasTotalPercentageError && <div>
            <Badge
                bg="danger"
                pill
                className="text-white p-2 mt-2 text-truncate"
                data-cy="errorInformationBadge"
            >
                Total Must be 100% and All fields are required.
            </Badge>
        </div>}
    </Row>;
};

export default ApplicationProcessPercent;
