import TextComponent from '@common/typography/TextComponent';

const TermsAndConditionsPFAC = () => <>
    <TextComponent
        fontSize="lg"
        fontWeight="700"
        color="charcoal"
        className="d-flex justify-content-left mb-4"
    >
        Terms & Conditions
    </TextComponent>
    <TextComponent fontSize="md" className="justify-content-left mb-4">
        PFAC Terms and Conditions Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque quia
        eius eligendi voluptatem repudiandae nemo iure accusantium esse
        delectus possimus doloribus harum ad, dicta sunt maiores
        consequuntur quo similique ullam? Lorem ipsum dolor sit amet
        consectetur adipisicing elit. Eaque quia eius eligendi voluptatem
        repudiandae nemo iure accusantium esse delectus possimus doloribus
        harum ad, dicta sunt maiores consequuntur quo similique ullam? Lorem
        ipsum dolor sit amet consectetur adipisicing elit. Eaque quia eius
        eligendi voluptatem repudiandae nemo iure accusantium esse delectus
        possimus doloribus harum ad, dicta sunt maiores consequuntur quo
        similique ullam? Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Eaque quia eius eligendi voluptatem repudiandae nemo iure
        accusantium esse delectus possimus doloribus harum ad, dicta sunt
        maiores consequuntur quo similique ullam? Lorem ipsum dolor sit amet
        consectetur adipisicing elit. Eaque quia eius eligendi voluptatem
        repudiandae nemo iure accusantium esse delectus possimus doloribus
        harum ad, dicta sunt maiores consequuntur quo similique ullam? Lorem
        ipsum dolor sit amet consectetur adipisicing elit. Eaque quia eius
        eligendi voluptatem repudiandae nemo iure accusantium esse delectus
        possimus doloribus harum ad, dicta sunt maiores consequuntur quo
        similique ullam? Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Eaque quia eius eligendi voluptatem repudiandae nemo iure
        accusantium esse delectus possimus doloribus harum ad, dicta sunt
        maiores consequuntur quo similique ullam? Lorem ipsum dolor sit amet
        consectetur adipisicing elit. Eaque quia eius eligendi voluptatem
        repudiandae nemo iure accusantium esse delectus possimus doloribus
        harum ad, dicta sunt maiores consequuntur quo similique ullam? Lorem
        ipsum dolor sit amet consectetur adipisicing elit. Eaque quia eius
        eligendi voluptatem repudiandae nemo iure accusantium esse delectus
        possimus doloribus harum ad, dicta sunt maiores consequuntur quo
        similique ullam? Lorem ipsum dolor sit amet consectetur adipisicing
        elit. Eaque quia eius eligendi voluptatem repudiandae nemo iure
        accusantium esse delectus possimus doloribus harum ad, dicta sunt
        maiores consequuntur quo similique ullam? Lorem ipsum dolor sit amet
        consectetur adipisicing elit. Eaque quia eius eligendi voluptatem
        repudiandae nemo iure accusantium esse delectus possimus doloribus
        harum ad, dicta sunt maiores consequuntur quo similique ullam? Lorem
        ipsum dolor sit amet consectetur adipisicing elit. Eaque quia eius
        eligendi voluptatem repudiandae nemo iure accusantium esse delectus
        possimus doloribus harum ad, dicta sunt maiores consequuntur quo
        similique ullam?
    </TextComponent>
</>;

export default TermsAndConditionsPFAC;
