import useWizardContext from "@hooks/wizard/useWizardContext";
import { BankFields } from "@hooks/wizard/types";
import { Col, Row } from "react-bootstrap";
import LabelComponent from "./LabelComponent";

const BankDetailsComponent = () => {
    const { form: { getValues }, id } = useWizardContext();
    const values = getValues();

    return <Row>
        <Col md={6} data-cy="routingNumber">
            <LabelComponent
                label="Routing Number"
                value={values?.[BankFields.P_BANK_ROUTING_NUMBER]}
                ddMaskText
                formKey={BankFields.P_BANK_ROUTING_NUMBER}
            />
        </Col>
        <Col md={6} data-cy="accountNumber">
            <LabelComponent
                label="Account Number"
                value={localStorage.getItem(`${id}:primaryBankValidatedByPlaid`) === 'true' ? values?.[BankFields.MASKED_P_BANK_ACCOUNT_NUMBER] : values?.[BankFields.P_BANK_ACCOUNT_NUMBER]}
                ddMaskText
                formKey={BankFields.P_BANK_ACCOUNT_NUMBER}
            />
        </Col>
    </Row>;
}
export default BankDetailsComponent;
