export const emailRegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const wholeNumRegExp = /^\d+$/;
export const cityRegExp = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/;
export const atleast1AlphabetRegExp = /[a-z]/i;
export const urlRegExp =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/;
export const poBoxRegExp =
    /^ *((#\d+)|((box|bin)[-. /\\]?\d+)|(.*p[ .]? ?(o|0)[-. /\\]? *-?((box|bin)|b|(#|num)?\d+))|(p(ost)? *(o(ff(ice)?)?)? *((box|bin)|b)? *\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(number|num|#)? *\d+|(num|number|#) *\d+)/i;
export const NUM_ONLY = /^([0-9]*)$/;
export const USERNAME =
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})|^([a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})$/;
export const DateRegExp =
    /^(?:(?:(?:0?[13578]|1[02])(\/|-|.)31)\1|(?:(?:0?[1,3-9]|1[0-2])(\/|-|.)(?:29|30)\2))(?:\d{4})$|^(?:0?2(\/|-|.)29\3(?:(?:(?:1[6-9]|[2-9]\d){2}(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9])|(?:1[0-2]))(\/|-|.)(?:0?[1-9]|1\d|2[0-8])\4(?:\d{4})$/;
