import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useNavigationContext } from '@hooks/useNavigationContext';
import useWizardContext from '@hooks/wizard/useWizardContext';
import { Page } from '@hooks/wizard/Wizard';

const WizardPages = styled.div`
    width: 100%;
    margin: auto;
    @media (min-width: 800px) {
        width: 75%;
    }
`;

const WizardContainer = () => {
    const { steps } = useWizardContext();
    const { currentPage } = useNavigationContext();

    // Scroll to top on each page
    const ref = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        ref?.current?.parentElement?.scrollTo?.({ top: 0 , behavior: 'smooth' });
    }, [currentPage]);

    return <WizardPages className='wizard-container' ref={ref}>
        <Page {...steps[currentPage]} />
    </WizardPages>;
};

export default WizardContainer;
