interface GravityLogoProps {
    fill?: string;
}

const GravityLogo = ({ fill = 'none' }: GravityLogoProps) => {
    return (
        <svg
            width="136"
            height="42"
            viewBox="0 0 136 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.0137 35.4844C27.9142 35.4844 27.8418 35.5643 27.8418 35.653V41.476C27.8418 41.5647 27.9142 41.6446 28.0137 41.6446H28.8372C28.9277 41.6446 29.0092 41.5647 29.0092 41.476V39.4078H30.1313C31.2262 39.4078 32.1312 38.5201 32.1312 37.4283C32.1312 36.3631 31.2262 35.4844 30.1222 35.4844H28.0137ZM30.9729 37.4239C30.9729 37.9432 30.5521 38.3382 30.0498 38.3382H29.0092V36.5717H30.0498C30.5521 36.5717 30.9729 36.9401 30.9729 37.4239Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40.1673 35.4975L37.3756 41.4226C37.3213 41.5335 37.3937 41.6489 37.5249 41.6534H38.3077C38.4525 41.6534 38.5339 41.5646 38.5701 41.4758L39.009 40.5261H41.6921L42.131 41.4758C42.1944 41.6001 42.2577 41.6534 42.3935 41.6534H43.1717C43.3074 41.6534 43.3798 41.538 43.3255 41.4226L40.5655 35.4975C40.5383 35.4443 40.5021 35.3999 40.4117 35.3999H40.3212C40.2397 35.3999 40.1945 35.4443 40.1673 35.4975ZM40.3574 37.6101L41.2532 39.5452H39.4524L40.3302 37.6101H40.3574Z"
                fill={fill}
            />
            <path
                d="M50.3841 38.6843L48.3389 35.7462C48.2666 35.6308 48.3389 35.4888 48.4837 35.4888H49.3977C49.4701 35.4888 49.5154 35.5332 49.5425 35.5687L50.9768 37.5925L52.4111 35.5687C52.4383 35.5332 52.4835 35.4888 52.5559 35.4888H53.479C53.6238 35.4888 53.6961 35.6308 53.6238 35.7462L51.5515 38.6754V41.4848C51.5515 41.5736 51.47 41.6535 51.3795 41.6535H50.5515C50.452 41.6535 50.3796 41.5736 50.3796 41.4848V38.6843H50.3841Z"
                fill={fill}
            />
            <path
                d="M60.1028 35.533C60.1119 35.462 60.1662 35.3999 60.2657 35.3999H60.4105C60.4829 35.3999 60.5372 35.4354 60.5643 35.4887L62.5416 39.6429C62.5597 39.6429 62.5597 39.6429 62.5688 39.6429L64.5415 35.4887C64.5687 35.4354 64.6139 35.3999 64.6953 35.3999H64.8401C64.9397 35.3999 64.994 35.462 65.003 35.533L66.0618 41.4448C66.0889 41.569 66.0165 41.6489 65.8989 41.6489H65.0845C65.003 41.6489 64.9306 41.5779 64.9125 41.5158L64.3831 38.1782C64.3741 38.1782 64.356 38.1782 64.356 38.1782L62.7859 41.6401C62.7678 41.6933 62.7135 41.7377 62.6321 41.7377H62.4692C62.3878 41.7377 62.3425 41.6933 62.3154 41.6401L60.7272 38.1782C60.7272 38.1782 60.7091 38.1782 60.7001 38.1782L60.1797 41.5158C60.1707 41.5779 60.0983 41.6489 60.0168 41.6489H59.2069C59.0893 41.6489 59.0169 41.569 59.035 41.4448L60.1028 35.533Z"
                fill={fill}
            />
            <path
                d="M72.3193 35.653C72.3193 35.5643 72.3917 35.4844 72.4913 35.4844H76.1427C76.2422 35.4844 76.3146 35.5643 76.3146 35.653V36.3765C76.3146 36.4652 76.2422 36.5451 76.1427 36.5451H73.4867V37.9875H75.7038C75.7943 37.9875 75.8757 38.0674 75.8757 38.1562V38.8841C75.8757 38.9817 75.7943 39.0527 75.7038 39.0527H73.4867V40.5883H76.1427C76.2422 40.5883 76.3146 40.6682 76.3146 40.757V41.476C76.3146 41.5647 76.2422 41.6446 76.1427 41.6446H72.4913C72.3917 41.6446 72.3193 41.5647 72.3193 41.476V35.653Z"
                fill={fill}
            />
            <path
                d="M82.6309 35.5597C82.6309 35.4709 82.7123 35.3999 82.8028 35.3999H83.0245L86.7574 39.2922H86.7664V35.6573C86.7664 35.5686 86.8388 35.4887 86.9383 35.4887H87.7664C87.8569 35.4887 87.9338 35.5686 87.9338 35.6573V41.5824C87.9338 41.6711 87.8523 41.7421 87.7664 41.7421H87.5492L83.7982 37.7033H83.7892V41.4892C83.7892 41.5779 83.7168 41.6578 83.6172 41.6578H82.8028C82.7123 41.6578 82.6309 41.5779 82.6309 41.4892V35.5597H82.6309Z"
                fill={fill}
            />
            <path
                d="M95.4492 36.5451H94.0782C93.9786 36.5451 93.9062 36.4652 93.9062 36.3765V35.653C93.9062 35.5643 93.9786 35.4844 94.0782 35.4844H97.9965C98.0961 35.4844 98.1685 35.5643 98.1685 35.653V36.3765C98.1685 36.4652 98.0961 36.5451 97.9965 36.5451H96.6256V41.4849C96.6256 41.5736 96.5441 41.6535 96.4536 41.6535H95.6166C95.5261 41.6535 95.4492 41.5736 95.4492 41.4849V36.5451Z"
                fill={fill}
            />
            <path
                d="M103.77 40.7879L104.092 40.242C104.164 40.1177 104.313 40.1177 104.395 40.1798C104.44 40.2065 105.169 40.7257 105.748 40.7257C106.214 40.7257 106.567 40.4284 106.567 40.0511C106.567 39.5984 106.182 39.2922 105.427 38.9948C104.585 38.662 103.739 38.1338 103.739 37.0953C103.739 36.3097 104.332 35.4043 105.757 35.4043C106.671 35.4043 107.372 35.8659 107.553 35.9946C107.644 36.0478 107.671 36.1987 107.607 36.2831L107.268 36.7846C107.196 36.8911 107.06 36.9621 106.956 36.8911C106.883 36.8467 106.2 36.4073 105.707 36.4073C105.196 36.4073 104.915 36.7402 104.915 37.0243C104.915 37.437 105.246 37.7211 105.974 38.0095C106.843 38.3513 107.847 38.8617 107.847 39.9979C107.847 40.9033 107.046 41.7421 105.784 41.7421C104.653 41.7421 103.992 41.2228 103.811 41.0542C103.739 40.9698 103.694 40.9299 103.77 40.7879Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.2225 26.71V8.36027V8.35581H18.9361V11.1105H18.8547C18.2403 10.222 16.2703 7.58789 11.5984 7.58789C5.81954 7.58789 0 12.3293 0 19.3746C0 26.9109 6.1087 31.5675 11.6391 31.5675C16.2703 31.5675 18.3623 28.978 19.0987 27.9645L19.1801 28.0449C18.8954 32.9426 15.2853 35.0499 12.2129 35.0499C11.3499 35.0499 8.7293 35.0499 6.59668 32.5408H0.36598C2.33143 37.5635 7.12983 39.9923 11.8017 39.9923C16.1076 39.9968 24.2225 37.5234 24.2225 26.71ZM5.44904 19.4147C5.44904 15.1644 8.81063 12.5303 12.0909 12.5303C16.1483 12.5303 18.7734 16.1779 18.7689 19.3746C18.7689 23.8705 15.5338 26.6252 12.2536 26.6252C8.93262 26.6252 5.44904 23.991 5.44904 19.4147Z"
                fill={fill}
            />
            <path
                d="M28.0039 8.3602H33.0056V10.347H33.087C34.1126 8.88703 35.1383 7.59229 37.9667 7.59229H38.4185V12.8159C33.4575 13.0168 33.4575 16.8654 33.4575 18.2003V30.8352H28.0039V8.3602Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M58.0282 30.8398H63.0299V8.35581H58.0282V11.1909H57.9468C57.7842 10.8694 55.0371 7.58789 50.4058 7.58789C44.0938 7.58789 39.0107 12.5704 39.0107 19.4549C39.0107 26.9511 44.2158 31.5675 50.076 31.5675C52.9451 31.5675 56.3067 30.0719 58.0282 28.0449V30.8398ZM44.4643 19.7005C44.4643 16.3386 46.7596 12.5303 51.2282 12.5303C54.4632 12.5303 57.8655 14.8831 57.87 19.5353C57.87 23.7097 54.9964 26.6252 51.2282 26.6252C47.3334 26.6252 44.4643 23.5088 44.4643 19.7005Z"
                fill={fill}
            />
            <path
                d="M65.3975 8.36035H71.2983L76.7925 23.2633H76.9145L82.0789 8.36035H88.0611L78.8393 30.8399H74.5741L65.3975 8.36035Z"
                fill={fill}
            />
            <rect x="90.3975" y="8.36035" width="5.44904" height="22.4795" fill={fill} />
            <path
                d="M101.905 13.142H99.2393V8.3648H101.905V0.868652H107.359V8.36033H110.639V13.1375H107.359V30.8354H101.905V13.142Z"
                fill={fill}
            />
            <path
                d="M121.221 28.7325L112.898 8.36035H119.129L124.294 22.7767L129.788 8.36035H135.978L122.902 38.3315H117.042L121.221 28.7325Z"
                fill={fill}
            />
            <path
                d="M96.1175 2.99382C96.1175 4.63235 94.7755 5.95835 93.1173 5.95835C91.4591 5.95835 90.1172 4.63235 90.1172 2.99382C90.1172 1.3553 91.4591 0.0292969 93.1173 0.0292969C94.7755 0.0292969 96.1175 1.3553 96.1175 2.99382Z"
                fill={fill}
            />
        </svg>
    );
};

export default GravityLogo;
