import { FetchOpportunityResponse } from '@app/api/opportunity/[id]/route';
import { type PropsWithChildren, createContext, useContext, useMemo } from 'react';

type AdditionalProcessingFields = Partial<Pick<FetchOpportunityResponse['processingDetails'],
    'avgMotoPercentage'|
    'avgSwipedPercentage'|
    'avgManualPercentage'|
    'avgInternetPercentage'|
    'annualSalesVolume'|
    'goodsAndService'|
    'isCurrentlyAcceptingCards'|
    'currentProcessor'|
    'annualVisaSalesVolume'|
    'annualDiscoverVolume'|
    'annualAmexVolume'|
    'annualAmexOptBlueVolume'|
    'avgTicket'|
    'sic'|
    'isUnsolicited'|
    'reasonForLeave'|
    'isSeasonal'>>;

export type Context = {
    additionalProcessingFields: AdditionalProcessingFields,
    complianceFee: FetchOpportunityResponse['complianceFee'],
    isSigner: boolean;
    mid: FetchOpportunityResponse['mid'],
    lineItems: FetchOpportunityResponse['opportunityLineItems'],
    pci: FetchOpportunityResponse['pci'],
    quote: FetchOpportunityResponse['quote'];
}

export const OpportunityContext = createContext<Context>({
    additionalProcessingFields: {},
    complianceFee: 0,
    isSigner: false,
    mid: '',
    lineItems: [],
    pci: { required: false },
    quote: {},
});

export const OpportunityContextProvider = ({
    children = null,
    additionalProcessingFields,
    complianceFee,
    isSigner, // FIXME(LY) Move this to a UserContext?
    mid,
    lineItems,
    pci,
    quote
}: PropsWithChildren<Context>) => {
    const value = useMemo(() => ({ additionalProcessingFields, complianceFee, isSigner, mid, lineItems, pci, quote }), [
        additionalProcessingFields,
        complianceFee,
        isSigner,
        mid,
        lineItems,
        pci,
        quote,
    ]);
    return <OpportunityContext.Provider value={value}>{children}</OpportunityContext.Provider>;
}

const useOpportunityContext = () => {
    const context = useContext(OpportunityContext);

    if (!context) {
        throw new Error(`useOpportunityContext hook can be used only inside Opportunity paths`);
    }

    return context;
};

export default useOpportunityContext;
