import { IconProps } from './type';

const EditIcon = ({ fill = '#107AB0', className }: IconProps) => {
    return (
        <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            className={className}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.10152 8.8457L10.4176 0.528279C11.1012 -0.15526 12.2113 -0.15526 12.8949 0.528279L13.9723 1.60644C14.057 1.69176 14.1309 1.7839 14.1965 1.88097C14.6449 2.56019 14.5711 3.48359 13.9723 4.08242L5.6543 12.3977C5.61875 12.4332 5.5832 12.4441 5.54492 12.5016C5.28242 12.734 4.9707 12.8816 4.63164 13.0047L1.34136 13.9727C1.11113 14.041 0.862025 13.9781 0.692221 13.784C0.52241 13.6391 0.458923 13.3902 0.526681 13.1578L1.49449 9.86836C1.60824 9.48281 1.81687 9.13007 2.10152 8.8457ZM2.75367 10.2375L2.12531 12.3758L4.2625 11.7469C4.41289 11.6949 4.59609 11.5992 4.72734 11.4707L10.9727 5.22265L9.25274 3.50273L3.02957 9.77265C3.01343 9.78906 2.99785 9.80546 2.98281 9.82461C2.87726 9.94218 2.79878 10.0844 2.75367 10.2375Z"
                fill={fill}
            />
        </svg>
    );
};

export default EditIcon;
