import styled from 'styled-components';

const InformationContainer = styled.div<{ variant?: string; height?: string }>`
    background-color: ${(props) => props?.theme?.[props?.variant || 'grey']};
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
    border: 1px solid ${(props) => props.theme.borderColor};
    padding: 20px;
    height: ${(props) => props?.height || 'auto'};
    border-radius: 8px;
    .active {
        background-color: ${props => props.theme.secondary};
        .nav-text {
            color: white;
        }
    }

    @media(max-width: 700px) {
        .nav-items {
            flex-direction: column;
            width: 100%;
        }
    }
`;

export default InformationContainer;
