import Image from 'next/image';
import { Col, Row } from 'react-bootstrap';
import { pdf } from '@react-pdf/renderer';

import { Button } from '@common';
import Document from '@components/icons/Document';
import TextComponent from '@common/typography/TextComponent';
import useWizardContext from '@hooks/wizard/useWizardContext';
import { currentYear } from '@utils/FormUtils';

const SuccessPage = () => {
    const { form: { getValues }, isOpportunity, pdfComponent } = useWizardContext();

    const handlePrint = async () => {
        const values = getValues();
        const documentBlob = await pdf(pdfComponent(values))
            .toBlob();
        const documenturl = URL.createObjectURL(documentBlob);
        const windowURL = window.open(documenturl);
        const doc = windowURL?.document;
        const theScript = document.createElement('script');
        function injectThis() {
            window.print();
        }
        theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
        doc?.body?.appendChild(theScript);
    }

    return <div className="p-3">
        <div className="mt-3 mb-2 text-center">
            {isOpportunity && <div className='text-end'>
                <Button variant="secondary" onClick={handlePrint} >
                    <TextComponent color="white" className='d-flex align-items-center'>
                        <Document fill="white" width='20' className="me-2" />
                        <div>Print Application</div>
                    </TextComponent>
                </Button>
            </div>}
            <TextComponent fontSize="lg" fontWeight="800" color="charcoal" className="mb-2">
                Congratulations
            </TextComponent>
            <TextComponent fontSize="md" fontWeight="400">
                Your application was submitted successfully, and you are one step closer to
                processing payments!
            </TextComponent>
        </div>
        <Row>
            <Col className="text-center">
                <Image
                    src="/success-page-image.png"
                    alt="SuccessPageImage"
                    unoptimized
                    width="400"
                    height="1000"
                    style={{ width: '100%', height: 'auto', maxWidth: '450px' }}
                />
            </Col>
        </Row>
        <div className="d-flex flex-column justify-content-center align-items-center arial">
            <TextComponent className="mt-3" color="pewter" fontSize="xss">
                © {currentYear} Gravity Payments. All Rights Reserved.
            </TextComponent>
            <TextComponent className="mt-1 text-center" color="pewter" fontSize="xss">
                Gravity Payments, Inc. is a registered ISO/MSP of Wells Fargo Bank, N.A., Concord, CA, and Synovus Bank, Columbus, GA
            </TextComponent>
        </div>
    </div>;
};

export default SuccessPage;
