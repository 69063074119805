'use client';

import Image from 'next/image';

import SidebarComponent from '@common/sidebar/SidebarComponent';
import Avatar from '@assets/header/avatar.svg';
import TextComponent from '@common/typography/TextComponent';
import Stepper from '@common/stepper/StepperComponent';
import useSidebarContext from '@hooks/useSidebarContext';
import { currentYear } from '@utils/FormUtils';

export interface SalesContact {
    email?: string,
    phone?: string,
    firstName?: string,
    lastName?: string,
}

const SidebarBody = () => <div className='text-center'>
    <Image src='/gravity-logo-white.png' width='110' height='35' alt='Logo' unoptimized />
    <div className=' overflow-auto mt-4'>
        <div className='mt-2'>
            <Stepper />
        </div>
    </div>
</div>;

const SidebarFooter = ({ salesContact = {} }: Props) => (<>
        {salesContact?.email || salesContact?.phone ? <div className='mb-2 p-3'>
            <TextComponent color='white' fontSize='md' fontWeight='700'>
                Have questions?
                <TextComponent fontSize='xs' color='xs' fontWeight='400'>
                    Contact your sales rep:
                </TextComponent>
            </TextComponent>
            <div className='d-flex mt-3 align-items-center'>
                <Image src={Avatar} width='35' height='35' alt='Gravity' />
                <div className='ms-3'>
                    <TextComponent fontSize='sm' color='white'>
                        {salesContact?.firstName} {salesContact?.lastName}
                    </TextComponent>
                    {salesContact?.phone && <a href={`tel:${salesContact?.phone}`} className='text-decoration-none'>
                        <TextComponent fontSize='sm' color='linkBlue'>
                            {salesContact?.phone}
                        </TextComponent>
                    </a>
                    }
                    {salesContact?.email && <a href={`mailto:${salesContact?.email}`} className='text-decoration-none'>
                        <TextComponent fontSize='sm' color='linkBlue'>
                            Email {salesContact?.firstName}
                        </TextComponent>
                    </a>
                    }
                </div>
            </div>
        </div> : null}
        <div className='border-top' />
        <TextComponent color='silver' fontSize='xss' className='mx-auto p-3 mb-1 arial'>
            <div className='text-left'>
                <div>
                    <u>
                        <a href='https://gravitypayments.com/privacy-policy/' target='_blank' rel='noreferrer' className='text-decoration-none'>
                            <TextComponent fontSize='xs' color='silver' as='span'>
                                Privacy Policy
                            </TextComponent>
                        </a>
                    </u>
                    <u>
                        <a href='https://gravitypayments.com/terms-of-use/' target='_blank' rel='noreferrer' className='text-decoration-none ms-2'>
                            <TextComponent fontSize='xs' color='silver' as='span'>
                                Terms of Use
                            </TextComponent>
                        </a>
                    </u>
                </div>
                © {currentYear} Gravity Payments.
                <div>All Rights Reserved.</div>
            </div>
        </TextComponent>
    </>);

type Props = {
    salesContact?: SalesContact;
};

// FIXME(LY) We might want to pass the steps as props in case they differ between Oppty and PFAC
const AppSidebar = ({ salesContact = {} }: Props) => {
    const { showSidebar = true } = useSidebarContext();

    return <SidebarComponent show={showSidebar} body={<SidebarBody />} footer={<SidebarFooter salesContact={salesContact} />} />;
};

export default AppSidebar;
