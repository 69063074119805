import styled from 'styled-components';
import React from 'react';
import Image from 'next/image';
import CheckMark from '@assets/sidebar/check-mark.svg';
import Polygon from '@assets/sidebar/warning-polygon.svg';
import { useNavigationContext } from '@hooks/useNavigationContext';
import useWizardContext from '@hooks/wizard/useWizardContext';

const StepperIconContainer = styled.div<{ $active: boolean; $hasWarning: boolean; varient: string }>`
    width: 30px;
    height: 30px;
    font-size: 14px;
    font-weight: 700;
    border: 1px solid
        ${(props) => (props?.$hasWarning && !props?.$active ? props.theme?.warning : props.varient)};
    border-radius: 50px;
    display: flex;
    opacity: ${(props) => (props.$active ? '' : '60%')};
    justify-content: center;
    align-items: center;
    background: ${(props) => {
        if (props.$active) {
            return props.theme.secondary;
        }
        if (props.$hasWarning) {
            return `${props.theme.warning}40`;
        }
        return 'transparent';
    }};
`;

const StepperText = styled.div<{ $active: boolean }>`
    font-size: 14px;
    font-weight: 500;
    opacity: ${(props) => (props.$active ? '' : '60%')};
`;

const StepperDivider = styled.div`
    background-color: #ffffff60;
    width: 1px;
    height: 20px;
    margin-left: 37px;
`;

export const Stepper = () => {
    const { jumpTo, pages } = useNavigationContext();
    const { isSubmitted } = useWizardContext();

    return <>
        {pages.map(({ hasWarning, isActive, isCompleted, progressIcon, title }, index) => isSubmitted
            ? null
            : <div className="stepper" key={title}>
                <div
                    className="d-flex align-items-center"
                    role="button"
                    onClick={() => jumpTo(index)}
                    onKeyUp={() => jumpTo(index)}
                    tabIndex={0}
                >
                    <div className={`${hasWarning && !isActive ? '' : 'opacity-0'} me-3`}>
                        <Image src={Polygon} alt="polygon" />
                    </div>
                    <StepperIconContainer
                        $active={isActive}
                        $hasWarning={hasWarning}
                        varient='white'
                    >
                        {isCompleted ? <Image src={CheckMark} alt='check' /> : progressIcon}
                    </StepperIconContainer>
                    <StepperText $active={isActive} className="ms-2 ">
                        {title}
                    </StepperText>
                </div>
                <StepperDivider className={index === pages.length - 1 ? 'd-none' : ''} />
            </div>
        )}
    </>;
}

export default Stepper;
