import TextComponent from '@common/typography/TextComponent';
import useWizardContext from "@hooks/wizard/useWizardContext";

const TermsAndConditionsOpportunity = () => {
    const { form: { getValues } } = useWizardContext();
    const {
        legalIsPhysicalAddress,
        legalName,
        legalAddr1,
        legalAddr2,
        legalCity,
        legalState,
        legalZipCode,
        businessAddr1,
        businessAddr2,
        businessCity,
        businessState,
        businessZipCode
    } = getValues();

    const address = legalIsPhysicalAddress ?
        `${businessAddr1}${businessAddr2 ? ` ${businessAddr2}`: ''}, ${businessCity}, ${businessState?.value} ${businessZipCode}`:
        `${legalAddr1}${legalAddr2 ? ` ${legalAddr2}` : ''}, ${legalCity}, ${legalState?.value} ${legalZipCode}`;

    return <>
        <TextComponent
            fontSize="lg"
            fontWeight="700"
            color="charcoal"
            className="d-flex justify-content-left mb-4"
        >
            Program Guide Confirmation Page
        </TextComponent>
        <TextComponent className="justify-content-left mb-4">
            <strong>Client&apos;s Business Legal Name:</strong> {legalName}<br />
            <strong>Merchant Address:</strong> {address}<br /><br />

            <strong>Processor Information</strong><br />
            Name: Gravity Payments<br />
            Address: 5601 22nd Ave NW 200, Seattle, WA 98107<br />
            URL: gravitypayments.com<br />
            Customer Service #: 1-877-388-5906<br />
        </TextComponent>
        <TextComponent className="justify-content-left mb-4">
            Please read the program guide in its entirety.
            (<a href='https://gravitypayments.com/docusign/GP_2607_PG__08.22.23_fp.pdf' target='_blank' rel='noreferrer'>
                <TextComponent as='span'>
                    Program Guide Link
                </TextComponent>
            </a>)
            It describes the terms under which we will provide merchant processing Services to you. From time
            to time you may have questions regarding the contents of your Agreement with Bank and/or Processor or
            the contents of your Agreement with TeleCheck. The following information summarizes portions of your
            Agreement in order to assist you in answering some of the questions we are most commonly asked.
            <br />
            <br />
            <ol>
                <li>
                    <strong>Your Discount Rates And Other Fees</strong> and charges are calculated based on
                    transactions qualifying for certain program pricing and interchange rates levied by the applicable
                    Payment Network. Transactions that fail to qualify for these rates will be charged an additional
                    fee. We will provide you with a schedule of fees and charges in connection with the Services.
                    Interchange and program pricing levied by the Payment Network is subject to change, (see Section
                    25 of the General Terms & Conditions).
                </li>
                <li>
                    <strong>We may debit your bank account</strong> (also referred to as your Settlement Account)
                    from time to time for amounts owed to us under the Agreement.
                </li>
                <li>
                    <strong>There are many reasons why a Chargeback may occur.</strong> When they occur we will
                    debit your settlement funds or Settlement Account. For a more detailed discussion regarding
                    Chargebacks see Section 14 of the Your Payments Acceptance Guide or see the applicable
                    provisions of the TeleCheck Solutions Agreement.
                </li>
                <li>
                    <strong>In consideration of the Services provided by us,</strong> you shall be charged and
                    hereby agree to pay us any and all fees set forth in this Agreement (for the purpose of
                    clarity, this includes the Application and any additional pricing supplements of subsequent
                    communications)., all of which shall be calculated and payable pursuant to the terms of this
                    Agreement and any additional pricing supplements or subsequent communications. If you dispute
                    any charge or funding, you must notify us within 60 days if the date of the statement where
                    the charge or funding appears for Card Processing or within 30 days of the date of a TeleCheck
                    transaction.
                </li>
                <li>
                    <strong>The Agreement limits our liability to you.</strong> For a detailed description of the
                    limitation of liability, see Section 27, 38.3, and 39.9 of the Card General Terms; or Section
                    17 of the TeleCheck Solutions Agreement.
                </li>
                <li>
                    <strong>We have assumed certain risks</strong> by agreeing to provide you with Card processing
                    or check services. Accordingly, we may take certain actions to mitigate our risk, including
                    termination of the Agreement, and/or hold monies otherwise payable to you (see Card Processing
                    General Terms in Section 31, Term: Events of Default and Section 32, Reserve Account; Security
                    Interest), (see TeleCheck Solutions Agreement in Section 7), under certain circumstances.
                </li>
                <li>
                    <strong>By executing this Agreement with us</strong> you are authorizing us and our Affiliates
                    to obtain financial and credit information regarding your business and the signers and
                    guarantors of the Agreement until all your obligations to us and our Affiliates are satisfied.
                </li>
                <li>
                    <strong>The Agreement contains a provision</strong> that in the event you terminate the
                    Agreement prior to the expiration of your initial 3 year term, you will be responsible for the
                    payment of an early termination fee as set forth in Part 4, A.3 under “Additional Fee
                    Information” and Section 6.2 of the TeleCheck Solutions Agreement.
                </li>
                <li>
                    <strong>For questions or concerns</strong> regarding your merchant account, contact customer
                    service at the number located on your Merchant Services Statement.
                </li>
            </ol>

            <strong>PAYMENT AND NETWORK DISCLOSURE</strong> Visa and Mastercard Member Bank Information: Wells Fargo Bank, N.A.<br /><br />

            The Bank’s mailing address is P.O. Box 6079, Concord, CA 94524, and its phone number is 1-844-284-6834.<br /><br />

            <strong>Important Member Bank Responsibilities:</strong><br />
            <ol type='a'>
                <li>
                    The Bank is the only entity approved to extend acceptance of Visa and Mastercard products
                    directly to a merchant.
                </li>
                <li>
                    The Bank must be a principal (signer) to the Agreement.
                </li>
                <li>
                    The Bank is responsible for educating merchants on pertinent Visa and Mastercard rules with which merchants must comply; but this information may be provided to you by Processor.
                </li>
                <li>
                    The Bank is responsible for and must provide settlement funds to the merchant.
                </li>
                <li>
                    The Bank is responsible for all funds held in reserve that are derived from settlement.
                </li>
                <li>
                    The Bank is the ultimate authority should a merchant have any problems with Visa or Mastercard products (however, Processor also will assist you with any such problems).
                </li>
            </ol>

            <strong>Important Merchant Responsibilities:</strong><br />
            <ol type='a'>
                <li>
                    Ensure compliance with Cardholder data security and storage requirements.
                </li>
                <li>
                    Maintain fraud and Chargebacks below Payments Network thresholds.
                </li>
                <li>
                    Review and understand the terms of the Merchant Agreement.
                </li>
                <li>
                    Comply with Payments Network Rules and applicable law and regulations.
                </li>
                <li>
                    Retain a signed copy of this Disclosure Page.
                </li>
                <li>
                    You may download Visa Regulations from Visa’s website at: <a href='https://usa.visa.com/content/dam/VCOM/download/about-visa/visa-rules-public.pdf' target='_blank' rel='noreferrer'>
                        <TextComponent as='span'>
                            https://usa.visa.com/content/dam/VCOM/download/about-visa/visa-rules-public.pdf
                        </TextComponent>
                    </a>
                </li>
                <li>
                    You may download Mastercard Regulations from Mastercard’s website at: <a href='https://www.mastercard.us/content/dam/public/mastercardcom/na/global-site/documents/mastercard-rules.pdf' target='_blank' rel='noreferrer'>
                        <TextComponent as='span'>
                            https://www.mastercard.us/content/dam/public/mastercardcom/na/global-site/documents/mastercard-rules.pdf
                        </TextComponent>
                    </a>
                </li>
                <li>
                    You may download American Express Merchant Operating Guide from American Express’ website at: <a href='https://www.americanexpress.com/us/merchant' target='_blank' rel='noreferrer'>
                        <TextComponent as='span'>
                            https://www.americanexpress.com/us/merchant
                        </TextComponent>
                    </a>
                </li>
            </ol>
        </TextComponent>
    </>;
}

export default TermsAndConditionsOpportunity;
